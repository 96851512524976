<template>
  <v-container class="sidebar-container pa-0 pb-2">
    <v-expansion-panels v-model="panelStatus" multiple accordion>
      <v-expansion-panel
        v-for="(paneInfo, i) in accordionItems"
        :key="`${paneInfo.id}-${i}`"
        class="pa-0"
      >
        <v-expansion-panel-header color="accent">
          <b> {{ paneInfo.title }} </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="accordion-container pa-0 pb-2">
          <v-component
            v-if="paneInfo.componentKey && node"
            :is="accordionComponents[paneInfo.componentKey]"
            :node="node"
            :paneInfo="paneInfo"
            :isPending="isPending"
          ></v-component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { computed, ref, watch, nextTick, set } from "vue-demi";
import "splitpanes/dist/splitpanes.css";
import { usePartFamily } from "../store/partFamily.pinia";
import {
  useFindByPartId,
  useGetHelper,
} from "../utils/feathers-pinia/use-get-helpers";
import { computedRef } from "../utils/composables";
import { isRegionUS } from "../utils/variables";
import { useFind } from "feathers-pinia";
import { usePartPreSchedule } from "../store/partPreSchedule.pinia";
import { useStore } from "../store/app.pinia";
import { get } from "lodash-es";

export default {
  name: "WorkflowSidebar",
  props: {
    node: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
    isManufacturedNonMRPFlow: {
      default: false,
    },
  },
  setup(props) {
    const panelStatus = ref([]);
    const partFamilyStore = usePartFamily();
    const partPreScheduleStore = usePartPreSchedule();
    const store = useStore();

    const { items: partFamilyResults } = useFindByPartId(
      props.node?.part_id,
      partFamilyStore
    );

    const childPartFamilyParams = computedRef(() => {
      return {
        query: {
          child_part_id: props.node?.part_id,
        },
      };
    });

    const { items: childPartResults } = useFind({
      model: partFamilyStore.Model,
      params: childPartFamilyParams,
    });

    const { item: partPrescheduleResult } = useGetHelper(
      computed(() => props.node?.regarding_object_id),
      partPreScheduleStore
    );

    const lowerCaseString = (str) => {
      return (str ?? "").toLowerCase();
    };

    const sourceCode = computed(() => {
      return lowerCaseString(props.node?.part?.source_code) ?? "";
    });
    const nodeText = computed(() => {
      return lowerCaseString(props.node?.text) ?? "";
    });
    const nodeType = computed(() => lowerCaseString(props?.node?.type) ?? "");
    const isInitiation = computed(() => nodeType.value === "initiation");
    const isOperation = computed(() => nodeType.value === "operation");
    // technically there are several types of buffers (SourceBuffer, DestinationBuffer, etc.)
    // but they all display the same data, so we just need to check if the word 'buffer' is present
    const isBuffer = computed(() => nodeType.value.includes("buffer"));
    const isDestinationBuffer = computed(
      () => nodeType.value === "destinationbuffer"
    );
    const isWaypoint = computed(() => nodeType.value === "waypoint");
    const isPartGroup = computed(() => nodeType.value === "partgroup");
    const isMaterial = computed(() => nodeType.value === "materialspec");
    const isInterfacility = computed(() => nodeType.value === "interfacility");
    const isFinishedGoods = computed(
      () =>
        lowerCaseString(props.node?.part?.part_type?.description) ===
        lowerCaseString("Finished Goods")
    );
    // TODO: Update isPartWithPreSchedule to check against regard_object_id? I think this should be filtered to check for a specific preschedule
    // TODO: preschedule specificity
    const isPartWithPreSchedule = computed(
      () => !!props.node?.part?.part_preschedules.length
    );
    const hasInventory = computed(() => !!props.node?.inventory?.length);

    const accordionComponents = ref({
      manufacturedPart: () =>
        import("./workflow-accordion/ManufacturedPartAccordion.vue"),
      purchasedPart: () =>
        import("./workflow-accordion/PurchasedPartAccordion.vue"),
      finishedGoodsPart: () =>
        import("./workflow-accordion/FinishedGoodsPartAccordion.vue"),
      operation: () => import("./workflow-accordion/OperationAccordion.vue"),
      buffer: () => import("./workflow-accordion/BufferAccordion.vue"),
      waypoint: () => import("./workflow-accordion/WaypointAccordion.vue"),
      materialSpec: () =>
        import("./workflow-accordion/MaterialSpecAccordion.vue"),
      purchasing: () => import("./workflow-accordion/PurchasingAccordion.vue"),
      familyDetail: () =>
        import("./workflow-accordion/FamilyDetailAccordion.vue"),
      partGroup: () => import("./workflow-accordion/PartGroupAccordion.vue"),
      chainedFlowInitiation: () =>
        import("./workflow-accordion/ChainedFlowInitAccordion.vue"),
      finishedGoodsInitiation: () =>
        import("./workflow-accordion/FinishedGoodsInitAccordion.vue"),
      manufacturedMrpInitiation: () =>
        import("./workflow-accordion/ManufacturedMRPInitAccordion.vue"),
      manufacturedNotMrpInitiation: () =>
        import("./workflow-accordion/ManufacturedNotMRPInitAccordion.vue"),
      inventory: () => import("./workflow-accordion/InventoryAccordion.vue"),
      partFile: () => import("./workflow-accordion/PartFileAccordion.vue"),
    });

    const accordionItems = computedRef(() => {
      return [
        {
          id: "manufactured-part-info",
          title: "Manufactured Part Info",
          componentKey: "manufacturedPart",
          isActive:
            sourceCode.value.includes("manufactured") && !isFinishedGoods.value,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "manufactured-part-info",
            true
          ),
        },
        {
          id: "purchased-part-info",
          title: "Purchased Part Info",
          componentKey: "purchasedPart",
          isActive: sourceCode.value.includes("purchased"),
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "purchased-part-info",
            true
          ),
        },
        {
          id: "finished-goods-part-info",
          title: "Finished Goods Part Info",
          componentKey: "finishedGoodsPart",
          isActive: isFinishedGoods.value,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "finished-goods-part-info",
            true
          ),
        },
        {
          id: "operation-info",
          title: "Operation Info",
          componentKey: "operation",
          isActive: isOperation.value,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "operation-info",
            true
          ),
        },
        {
          id: "buffer-info",
          title: "Buffer Info",
          componentKey: "buffer",
          isActive: isBuffer.value,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "buffer-info",
            true
          ),
        },
        {
          id: "waypoint-info",
          title: "Waypoint Info",
          componentKey: "waypoint",
          isActive:
            isWaypoint.value &&
            partPrescheduleResult?.value?.type.toLowerCase().trim() ===
              "pre-op",
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "waypoint-info",
            true
          ),
        },
        {
          id: "material-spec",
          title: "Material Spec",
          componentKey: "materialSpec",
          isActive: isMaterial.value,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "material-spec",
            true
          ),
        },
        {
          id: "purchasing-info",
          title: "Purchasing Info",
          componentKey: "purchasing",
          isActive: sourceCode.value.includes("purchased"),
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "purchasing-info",
            isInitiation.value ||
              (!sourceCode.value.includes("purchased") && !isMaterial.value)
          ),
        },
        {
          id: "family-detail",
          title: "Family Detail",
          componentKey: "familyDetail",
          isActive:
            partFamilyResults?.value?.length || childPartResults?.value?.length,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "family-detail",
            false
          ),
        },
        {
          id: "part-group",
          title: "Part Group",
          componentKey: "partGroup",
          isActive: isPartGroup.value,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "part-group",
            true
          ),
        },
        {
          id: "chained-flow-initiation",
          title: "Chained Flow Initiation",
          componentKey: "chainedFlowInitiation",
          isActive: isInitiation.value && nodeText.value.includes("chained"),
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "chained-flow-initiation",
            true
          ),
        },
        {
          id: "finished-goods-initiation",
          title: "Finished Goods Initiation",
          componentKey: "finishedGoodsInitiation",
          isActive: isInitiation.value && isFinishedGoods.value,
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "finished-goods-initiation",
            true
          ),
        },
        {
          id: "manufactured-mrp-initiation",
          title: "Manufactured, MRP Initiation",
          componentKey: "manufacturedMrpInitiation",
          isActive:
            isInitiation.value && nodeText.value.includes("manufactured mrp"),
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "manufactured-mrp-initiation",
            true
          ),
        },
        {
          id: "manufactured-not-mrp-initiation",
          title: "Manufactured, Not MRP Initiation",
          componentKey: "manufacturedNotMrpInitiation",
          isActive:
            isInitiation.value &&
            (nodeText.value.includes("manufactured non mrp") ||
              (isFinishedGoods.value && isPartWithPreSchedule.value)),
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "manufactured-not-mrp-initiation",
            true
          ),
        },
        {
          id: "inventory",
          title: "Inventory",
          componentKey: "inventory",
          isActive:
            (isInitiation.value ||
              isBuffer.value ||
              isInterfacility.value ||
              isWaypoint.value) &&
            (hasInventory.value ||
              (props.isManufacturedNonMRPFlow && isDestinationBuffer.value)),
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "inventory",
            false
          ),
          isManufacturedNonMRPFlow: props.isManufacturedNonMRPFlow,
        },
        {
          id: "part-viewer",
          title: "Part Files Viewer",
          componentKey: "partFile",
          isActive: isRegionUS, // isActive if there is a file a file to show?
          expanded: get(
            store.flowviewerAccordionExpansionPrefs,
            "part-viewer",
            false
          ), // default to true?
        },
      ].filter((item) => item.isActive);
    });

    const updatePanelsState = (panels) => {
      // Get the array position for each item where expanded is true
      const expandedKeys = panels
        .map((item, index) => (item.expanded ? index : null))
        .filter((item) => item !== null);
      // Set the keys immediately, so the accordion doesn't collapse before nextTick
      panelStatus.value = expandedKeys;
      // Set the keys again after render, so the expanded state is triggered correctly
      nextTick(() => (panelStatus.value = expandedKeys));
    };

    watch(() => accordionItems.value, updatePanelsState, {
      immediate: true,
      deep: true,
    });

    const storePanelStatusPrefs = (panelStatuses) => {
      // Create an object of the current panel statuses
      const panelStatusesObject = accordionItems.value.reduce(
        (acc, cur, currIndex) => {
          acc[cur.id] = !!panelStatuses.includes(currIndex);
          return acc;
        },
        {}
      );
      // Merge the values into the store
      set(
        store,
        "flowviewerAccordionExpansionPrefs",
        Object.assign(
          {},
          store.flowviewerAccordionExpansionPrefs,
          panelStatusesObject
        )
      );
    };

    watch(() => panelStatus.value, storePanelStatusPrefs, {
      immediate: true,
      deep: true,
    });

    return {
      accordionItems,
      panelStatus,
      accordionComponents,
    };
  },
};
</script>

<style scoped>
.accordion-container::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
