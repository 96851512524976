import React, { memo } from "react";
import BaseNode from "./base/BaseNode";
import { mdiChevronDoubleRight, mdiLayers } from "@mdi/js";

React;

const InitiationNode = memo((props) => {
  const iconsLeft = {
    color: "#2caf33",
    props: [
      {
        path: mdiChevronDoubleRight,
        title: "Initiation",
      },
    ],
  };
  const inventory = props.data?.detail?.metadata?.hide_inventory
    ? null
    : {
        // green if inventory is present, gray if not present
        color: props.data.detail.inventory?.length ? "green" : "lightgray",
        path: mdiLayers,
        title: "Inventory",
      };

  return (
    <BaseNode
      {...props}
      handles={["bottom"]}
      iconsLeft={iconsLeft}
      inventory={inventory}
      slot={
        <div>
          <div>{props?.data?.detail?.part?.part_number ?? ""}</div>
        </div>
      }
    />
  );
});

export { InitiationNode };
