import { ref } from "vue-demi";

export const createLoadingData = (entity, fields = []) =>
  ref(
    Object.keys(entity).reduce((acc, key) => {
      if (fields.length && !fields.includes(key)) return acc;
      acc[key] = false;
      return acc;
    }, {})
  );
