import { defineStore, BaseModel } from "./store.pinia";
import { api } from "../utils/feathers-api";

export class ProcessTemplateFieldType extends BaseModel {
  static instanceDefaults(data = null) {
    return {
      id: data?.id ?? null,
      title: data?.title ?? "",
      created: data?.created ?? null,
      modified: data?.modified ?? null,
      created_by_id: data?.created_by_id ?? null,
      modified_by_id: data?.modified_by_id ?? null,
    };
  }
}

const servicePath = "process-template-field-types";
export const useProcessTemplateFieldTypes = defineStore({
  idField: "id",
  Model: ProcessTemplateFieldType,
  clients: { api },
  servicePath,
});

api.service(servicePath).hooks({});
