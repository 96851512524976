<template>
  <div class="bom-tree-container">
    <v-container>
      <v-row>
        <v-col :cols="hasContextBuffer ? 9 : 12">
          <v-autocomplete
            ref="partSearchInput"
            :search-input.sync="search"
            v-model="selectedPartId"
            :items="allPartResults"
            item-text="part_number"
            item-value="id"
            label="Part Number"
            class="mt-2"
            outlined
            clearable
            dense
            :hint="selectedPartId ? selectedPart.description : ''"
            persistent-hint
            auto-select-first
            :loading="searchLoading"
            @keydown.enter="onEnter"
          >
            <template slot="prepend">
              <v-btn
                text
                icon
                class="mt-0 pt-0"
                :disabled="bomTreeHistoryStore.historyBackDisabled"
                @click="bomTreeHistoryStore.setHistoryBack"
              >
                <v-icon>mdi-arrow-left-thick</v-icon>
              </v-btn>
            </template>
            <template slot="append-outer">
              <v-btn
                text
                icon
                class="mt-0 pt-0"
                :disabled="bomTreeHistoryStore.historyForwardDisabled"
                @click="bomTreeHistoryStore.setHistoryForward"
              >
                <v-icon>mdi-arrow-right-thick</v-icon>
              </v-btn>
            </template>
            <!-- we need a custom slot for the search results to display the part number/description -->
            <template #item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.part_number }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="item.status === 'Obsolete'">
                  <v-badge color="error" dot inline />
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3" class="pr-1" v-if="hasContextBuffer">
          <BTTopFlowButtons :item="selectedBuffer" v-on:flow-click="openFlow" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mb-0 pb-0">
          <v-checkbox v-model="onlyCurrent" label="Only Current" class="mt-0" />
        </v-col>
        <v-col class="mb-0 pb-0">
          <v-btn-toggle
            v-model="toggle"
            color="primary"
            class="mb-2 float-right"
          >
            <v-btn :value="true" small>
              <v-icon> mdi-arrow-expand-vertical </v-icon>
            </v-btn>
            <v-btn :value="false" small>
              <v-icon> mdi-arrow-collapse-vertical </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-container>
    <LoadingContainer :loading="isPending" class="px-0 mx-0 pa-0" no-gutters>
      <v-card v-if="!hasTree"></v-card>
      <v-expansion-panels v-else v-model="openBOMPanels" multiple accordion>
        <v-expansion-panel>
          <v-expansion-panel-header
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
          >
            BOM ({{ treeChildren.length || 0 }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list two-line dense class="pa-0">
              <template v-for="(child, index) in treeChildren">
                <BTListItem
                  :key="child.id"
                  :item="child"
                  :show-status-colors="true"
                  @click="selectPart"
                  v-on:flow-click="openFlow"
                />
                <v-divider
                  v-if="index < treeChildren.length - 1"
                  :key="index"
                />
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
          >
            Where Used ({{ whereUsed.length || 0 }})
            <v-spacer />
          </v-expansion-panel-header>
          <v-expansion-panel-content class="w-100 px-0">
            <v-list dense two-line class="pa-0">
              <template v-for="(parent, index) in whereUsed">
                <BTListItem
                  :key="parent.id"
                  :item="parent"
                  :show-status-colors="!onlyCurrent"
                  @click="selectPart"
                  v-on:flow-click="openFlow"
                />
                <v-divider v-if="index < whereUsed.length - 1" :key="index" />
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
          >
            Buffers ({{ buffers.length || 0 }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense class="pa-0">
              <template v-for="(top, index) in buffers">
                <BTBufferListItem
                  :key="top.id"
                  :item="top"
                  @click="selectPart"
                  v-on:flow-click="openFlow"
                />
                <v-divider v-if="index < buffers.length - 1" :key="index" />
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
          >
            Top Level Uses ({{ topLevelUses.length || 0 }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list two-line dense class="pa-0">
              <template v-for="(top, index) in topLevelUses">
                <BTListItem
                  :key="top.id"
                  :item="top"
                  :show-status-colors="!onlyCurrent"
                  @click="selectPart"
                  item-overrides-path="top_level_data"
                  v-on:flow-click="openFlow"
                />
                <v-divider
                  v-if="index < topLevelUses.length - 1"
                  :key="index"
                />
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </LoadingContainer>
  </div>
</template>

<script>
import { ref, watch, computed, nextTick } from "vue-demi";
import { watchDebounced } from "@vueuse/core";
import { isEqual, pick, sortBy } from "lodash-es";
import { usePart } from "@/store/part.pinia";
import { useBomTree } from "@/store/bomTree.pinia";
import LoadingContainer from "./LoadingContainer.vue";
import BTListItem from "./bom-tree/BTListItem.vue";
import BTBufferListItem from "./bom-tree/BTBufferListItem.vue";
import { useRouter } from "@/utils/useRouter";
import BTTopFlowButtons from "@/components/bom-tree/BTTopFlowButtons.vue";
import { useStore } from "@/store/app.pinia";
import { useBomTreeHistory } from "@/store/bomTreeHistory.pinia";

export default {
  name: "SidebarBomTree",
  components: {
    BTTopFlowButtons,
    LoadingContainer,
    BTListItem,
    BTBufferListItem,
  },
  setup() {
    const router = useRouter();
    const bomTreeStore = useBomTree();
    const bomTreeHistoryStore = useBomTreeHistory();
    const appStore = useStore();

    const partSearchInput = ref(null);
    const onlyCurrent = ref(true);
    const toggle = ref(true);

    const tree = ref({});
    const isPending = ref(false);
    const isEditing = ref(false);
    const autoSelectFirst = ref(false);
    const openBOMPanels = ref([0, 1, 2, 3]);
    const searchLoading = ref(false);

    // Helper function to check if a path is current
    const isPathCurrent = (partIds) =>
      partIds.every((id) => !obsoleteTreePartIds.value.includes(id));

    const hasTree = computed(() => {
      return (
        tree.value?.parents?.length > 0 || tree.value?.children?.length > 0
      );
    });
    // Combine all part objects from the tree into a single array
    const treeParts = computed(() => {
      return [
        ...(tree.value?.parents ?? []),
        ...(tree.value?.children ?? []),
      ].reduce((acc, item) => {
        // Check if parent_part exists and has an id
        if (item.parent_part?.id) {
          // Add the parent part to the accumulator if it doesn't already exist
          if (!acc.find((part) => part.id === item.parent_part.id)) {
            acc.push(item.parent_part);
          }
        }
        // Check if child_part exists and has an id
        if (item.child_part?.id) {
          // Add the child part to the accumulator if it doesn't already exist
          if (!acc.find((part) => part.id === item.child_part.id)) {
            acc.push(item.child_part);
          }
        }
        return acc;
      }, []);
    });

    // Get all obsolete tree parts
    const obsoleteTreePartIds = computed(() =>
      (treeParts.value ?? [])
        .filter((part) => part.status === "Obsolete")
        .map((part) => part.id)
    );

    // Get direct parents of the selected part
    const whereUsed = computed(() => {
      return sortBy(
        tree.value?.parents?.filter(
          (item) =>
            item.level === 1 &&
            (!onlyCurrent.value ||
              isPathCurrent([item.parent_part_id, ...item.path]))
        ),
        ["part_number"]
      );
    });
    const treeChildren = computed(() =>
      sortBy(tree.value?.children || [], ["part_number"])
    );
    const buffers = computed(() =>
      sortBy(tree.value?.buffers || [], ["description"])
    );
    const topLevelUses = computed(() => {
      // Return the top level uses from the tree (is_top), and exclude any duplicate ids
      return sortBy(
        tree.value?.parents?.filter(
          (parent, index, self) =>
            parent.top_level_display_flag &&
            self.findIndex((p) => p.id === parent.id) === index &&
            (!onlyCurrent.value ||
              isPathCurrent([parent.parent_part_id, ...parent.path]))
        ) ?? [],
        ["parent_part.part_number"]
      );
    });

    const selectedPartId = computed({
      get() {
        return bomTreeHistoryStore.selectedPartId;
      },
      set(newVal) {
        bomTreeHistoryStore.setSelectedPartId(newVal);
      },
    });

    const search = computed({
      get() {
        return bomTreeHistoryStore.searchTerm;
      },
      set(newVal) {
        bomTreeHistoryStore.searchTerm = newVal;
      },
    });

    const fetchBOMPart = async (parentPartId) => {
      isPending.value = true;
      try {
        const response = await bomTreeStore.fetchBOMPartById(parentPartId);
        console.log("Fetched BOM part:", response);
        tree.value = response;
      } catch (error) {
        // Handle error (already logged by the store)
        console.log("failed: ", error);
      } finally {
        isPending.value = false;
      }
    };

    // Setup part autocomplete
    const partStore = usePart();
    const partResults = computed({
      get() {
        return bomTreeHistoryStore.partResults;
      },
      set(newVal) {
        bomTreeHistoryStore.partResults = newVal;
      },
    });
    const allPartResults = computed(() => bomTreeHistoryStore.allPartResults);
    const partSearchParams = computed(
      () => bomTreeHistoryStore.partSearchParams
    );

    const autoSelectPart = () => {
      if (!autoSelectFirst.value) return;
      nextTick(() => {
        // If the search value matches a part number, select it
        const part = allPartResults.value.find((part) => {
          const partNumber = (part.part_number ?? "").toLowerCase();
          const searchValue = (search.value ?? "").toLowerCase();
          return partNumber === searchValue;
        });
        // If we found a part, select it
        if (part) {
          // Only select if it's not already selected
          if (selectedPartId.value !== part.id) selectedPartId.value = part.id;
        }
        // otherwise, select the first part result
        else {
          const result = allPartResults.value[0]?.id;
          if (result) selectedPartId.value = result;
        }
        // Flip it back to false
        autoSelectFirst.value = false;
        // Close the autocomplete
        partSearchInput.value.blur();
      });
    };

    // Debounce the search input
    watchDebounced(
      partSearchParams,
      (newVal) => {
        console.log("newVal: ", newVal);
        // Don't search for empty strings
        if (newVal === null || !newVal.query.part_number.$iLike.length) return;

        // Get clean search term
        const searchTerm = newVal.query.part_number.$iLike
          .replace("%", "")
          .toLowerCase();

        // Make sure the exact part doesn't already exist in allPartResults
        if (
          allPartResults.value.find(
            (part) => part.part_number.toLowerCase() === searchTerm
          )
        ) {
          return;
        }

        // Set loading state
        searchLoading.value = true;
        // Search for parts
        partStore
          .find(partSearchParams)
          .then(({ data }) => {
            // If the search params changed while we were searching, don't update the results
            if (isEqual(partSearchParams.value, newVal)) {
              partResults.value = data;

              // Only auto select if the search term matches the part number
              if (searchTerm === data[0]?.part_number.toLowerCase()) {
                autoSelectPart();
              }

              // Update any cached results
              bomTreeHistoryStore.updateCachedItems(data);
            }
          })
          .catch((error) => {
            if (isEqual(partSearchParams.value, newVal)) partResults.value = [];
            console.error("error: ", error);
          })
          .finally(() => {
            if (isEqual(partSearchParams.value, newVal))
              searchLoading.value = false;
          });
      },
      { immediate: true, debounce: 600 }
    );

    // Fetch the bom when selectedPartId changes
    watch(
      selectedPartId,
      (newVal) => {
        if (newVal && newVal.length > 0) {
          // Check if we have the part
          const part = allPartResults.value.find((part) => part.id === newVal);
          let partExists = part !== undefined;
          // First check if we need to cache a part from the BOM tree
          if (!partExists) {
            // Search the tree parts for the selected part
            const treePart = treeParts.value.find(
              // Find by parent_part_id
              (part) => part.id === newVal
            );
            // If the part exists in the tree, add it to the cache
            if (treePart) {
              // Use the parent_part_id as the id
              const partToCache = pick(treePart, [
                "id",
                "part_number",
                "description",
                "status",
              ]);
              bomTreeHistoryStore.addPartToCache(partToCache);
              // Set partExists to true so we don't fetch it
              partExists = true;
            }
          }
          fetchBOMPart(newVal);
          // If the selected part id doesn't exist in the part results, fetch it
          if (!partExists) {
            searchLoading.value = true;
            partStore
              .get(selectedPartId.value)
              .then((result) => {
                if (result) bomTreeHistoryStore.addPartToCache(result);
              })
              .catch((error) => {
                console.error("error: ", error);
              })
              .finally(() => {
                searchLoading.value = false;
              });
          }
        } else {
          // clear bom tree
          tree.value = [];
        }
      },
      { immediate: true }
    );

    const selectedPart = computed(() => {
      return (
        allPartResults.value.find((part) => part.id === selectedPartId.value) ||
        {}
      );
    });

    watch(
      () => selectedPart.value,
      (newVal) => {
        // Add part to cache
        if (newVal && newVal.id) bomTreeHistoryStore.addPartToCache(newVal);
      }
    );

    const openFlow = (flowId) => {
      if (flowId) {
        router.push(`/flow-viewer/${flowId}`);
      }
    };

    const selectPart = (partId) => {
      console.log("part: ", partId);
      selectedPartId.value = partId;
    };

    const onEnter = () => {
      // When enter key is pressed, select the first part result
      autoSelectFirst.value = true;
    };

    const selectedBuffer = computed(() => {
      // When only one active buffer exists in the system for the entered part, that buffer should be selected.
      if (buffers.value.length === 1) {
        return buffers.value[0];
      }

      // Select the buffer at the active facility when one (1) exists (buffer.facilityid = activefacilityid)
      const activeFacilityBuffers = buffers.value.filter((buffer) => {
        return (
          buffer.facility_id && buffer.facility_id === appStore.activeFacilityId
        );
      });

      // If only one active buffer exists, return it
      if (activeFacilityBuffers.length === 1) {
        return activeFacilityBuffers[0];
      }

      // Select the earliest buffer (non-emove) when one (1) exists (buffer.from_buffer = NULL)
      const eligibleBuffers = buffers.value.filter((buffer) => {
        const hasFromBuffer = buffer.from_buffer?.id;
        const isEmove = buffer.flow_initiation_detail?.text === "Type: Emove";

        return !hasFromBuffer && !isEmove;
      });

      // If only one eligible buffer exists, return it
      if (eligibleBuffers.length === 1) {
        return eligibleBuffers[0];
      }

      return null;
    });

    const hasContextBuffer = computed(() => {
      return (
        selectedBuffer.value &&
        (selectedBuffer.value.from_flow_id || selectedBuffer.value.flow_id)
      );
    });

    // Watch toggle value and update the openBOMPanels
    watch(toggle, (newVal) => {
      if (newVal) {
        openBOMPanels.value = [0, 1, 2, 3];
      } else {
        openBOMPanels.value = [];
      }
    });

    return {
      toggle,
      hasContextBuffer,
      selectedBuffer,
      partSearchInput,
      isEditing,
      isPending,
      openBOMPanels,
      search,
      tree,
      treeChildren,
      topLevelUses,
      whereUsed,
      buffers,
      hasTree,
      bomTreeStore,
      bomTreeHistoryStore,
      partResults,
      allPartResults,
      selectedPartId,
      selectedPart,
      searchLoading,
      openFlow,
      selectPart,
      onEnter,
      onlyCurrent,
    };
  },
};
</script>
<style>
.bom-tree-container
  .v-expansion-panel-content
  .v-expansion-panel-content__wrap {
  padding-right: 0;
  padding-bottom: 0;
}
</style>
