import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "user-saved-flows";
const useUserSavedFlows = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  actions: {
    async addSavedFlows(listId, flows, userId) {
      const userSavedFlows = flows.map((flow) => {
        return new this.Model({
          user_id: userId,
          flow_id: flow.id,
          list_id: listId,
          initiation_type: flow.initiation_type,
        });
      });
      // Save it to the database
      return Promise.all(
        userSavedFlows.map((flow) =>
          flow.save().catch((err) => console.error(err))
        )
      ).catch((err) => console.error(err));
    },
    deleteSavedFlowById(id) {
      return this.remove(id).catch((err) => console.error(err));
    },
    // delete all saved flows with given list id
    deleteSavedFlowsByIds(savedFlowIds) {
      return savedFlowIds.map((savedFlowId) => {
        this.remove(savedFlowId).catch((err) => console.error(err));
      });
    },
  },
});

api.service(servicePath).hooks({});

export { useUserSavedFlows };
