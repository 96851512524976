<template>
  <v-breadcrumbs :items="appStore.breadcrumbs" divider=">" large>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :href="item.href"
        :disabled="item.disabled"
      >
        <div class="d-flex align-center flex-column white--text">
          <span class="text-caption" v-if="item.subtitle">
            {{ item.subtitle }}
          </span>
          <v-badge
            :value="item.badge !== undefined"
            :color="getBadgeProperty(item, 'color')"
            dot
            inline
          >
            <v-btn v-if="item.icon" icon small>
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
            <span v-else class="breadcrumb-title text-caption text-md-body-1">
              {{ item.text }}
            </span>
          </v-badge>
        </div>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { useStore } from "@/store/app.pinia.js";

export default {
  name: "AppBreadcrumbs",
  setup() {
    const appStore = useStore();

    const getBadgeProperty = (item, property) => {
      const badge = item?.badge ?? {};
      return badge[property] ?? undefined;
    };

    return {
      appStore,
      getBadgeProperty,
    };
  },
};
</script>
