<template>
  <BadgeCanEdit :value="canEdit" :noBadge="noBadge">
    <v-card v-bind="cardAttrs">
      <v-form v-if="entity" v-model="valid" :ref="formId" :style="formStyle">
        <v-row v-show="errorAlert">
          <v-col>
            <v-alert
              v-model="errorAlert"
              color="red"
              dismissible
              type="success"
            >
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
        <slot
          v-bind:clones="clones"
          v-bind:data="clones.entity"
          v-bind:loading="loading"
          v-bind:childProps="childProps"
          v-bind:childEvents="childEvents"
        ></slot>
      </v-form>
    </v-card>
  </BadgeCanEdit>
</template>

<script>
import { computed, ref, set, watch, nextTick } from "vue-demi";
import { handleClones } from "feathers-pinia";
import { generateRandomId } from "@/utils/";
import { createLoadingData } from "@/utils/form/inline-helpers";
import BadgeCanEdit from "./BadgeCanEdit.vue";

export default {
  name: "InlineFieldsContainer",
  components: {
    BadgeCanEdit,
  },
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
    entityStore: {
      type: Object,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    noBadge: {
      type: Boolean,
      default: () => false,
    },
    height: {
      type: String,
      default: () => undefined,
    },
  },
  setup(props, { refs }) {
    // Create an ID for the form
    const formId = ref(generateRandomId());
    const valid = ref(false);
    const errorAlert = ref(false);
    const errorMessage = ref(false);
    // Remove any previous clones
    delete props.entityStore.clonesById[props.entity.id];
    // Reference to the original entity
    let originalEntity;
    // Method to set the original entity
    const setOriginalEntity = (id) =>
      (originalEntity = props.entityStore.itemsById[id] ?? {});
    // Set initial value
    setOriginalEntity(props.entity.id);
    // Handle clones
    const { clones, saveHandlers } = handleClones(props);
    // Get the entity save handler
    const { save_entity } = saveHandlers;
    // Create loading ref object
    const loading = createLoadingData(clones.entity, props.fields);
    // Method to set a field's loading state
    const setLoading = (key, value) => {
      // Set key value
      if (key) set(loading.value, key, value);
      // Set global value
      set(loading.value, "entity", value);
    };
    // Save an entity by key
    const saveEntityKey = (entity, key) => {
      // Update loading;
      setLoading(key, true);
      return save_entity(entity)
        .then((result) => {
          console.log("saved", result);
          // Update the "original entity" with the newly saved data set
          originalEntity = result.item;
          // Update all the clone values from the fresh data
          const ignoredKeys = ["__isClone"];
          for (let key in result.item) {
            if (ignoredKeys.includes(key)) continue;
            if (clones.entity[key] != result.item[key]) {
              set(clones.entity, key, result.item[key]);
            }
          }
          setLoading(key, false);
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.error(error);
          errorAlert.value = true;
          errorMessage.value = error.message;
          entity[key] = originalEntity[key];
          setLoading(key, false);
        });
    };
    // Method to create a save handler for a field
    const saveHandler = (key) => {
      return (val) => {
        clones.entity[key] = val; // Update the clone
        return saveEntityKey(clones.entity, key); // Save the clone
      };
    };
    // Method to trigger validation on this form
    const validate = () => {
      // Trigger revalidation
      if (refs[formId.value]?.validate) return refs[formId.value].validate();
    };
    // Create childProps
    const childProps = computed(() => {
      // Default childProps
      const def = {
        canEdit: props.canEdit,
        formValid: valid.value,
        triggerValidation: validate,
      };
      // define childProps, with default property
      const childProps = { default: { ...def } };
      // Loop through fields
      if (props.fields.length) {
        props.fields.forEach((field) => {
          childProps[field] = {
            ...def,
            value: clones?.entity[field],
            loading: loading.value[field],
          };
        });
      }
      return childProps;
    });

    // Create childEvents
    const childEvents = computed(() => {
      return props.fields.length
        ? props.fields.reduce((acc, field) => {
            acc[field] = {
              input: saveHandler(field),
            };
            return acc;
          }, {})
        : {};
    });

    const formStyle = computed(() => {
      return props.height ? { height: props.height } : {};
    });

    const cardAttrs = computed(() => {
      return {
        outlined: props.noBadge ? false : true,
        rounded: props.noBadge ? undefined : "lg",
        flat: props.noBadge,
        height: props.height ?? undefined,
      };
    });

    watch(
      () => props.entity.id,
      (id, oldId) => {
        // If an oldId was set, and the id's don't match
        if (id !== oldId) {
          [id, oldId]
            .filter((id) => id) // Only keep the non-null ids
            .forEach((id) => {
              // Remove clone
              delete props.entityStore.clonesById[id];
            });
          // Update the original entity
          setOriginalEntity(id);
          nextTick(() => {
            // Trigger revalidation
            validate();
          });
        }
      }
    );
    // Trigger initial validation
    watch(
      () => childProps.value?.default?.formValid,
      (valid, oldValid) => {
        if (!valid && oldValid) {
          nextTick(() => validate());
        }
      },
      { immediate: true }
    );

    return {
      valid,
      formId,
      clones,
      saveHandlers,
      loading,
      childProps,
      childEvents,
      errorAlert,
      errorMessage,
      cardAttrs,
      formStyle,
    };
  },
};
</script>
