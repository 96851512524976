<template>
  <v-container
    fluid
    fill-height
    :class="containerClasses"
    :style="containerStyles"
  >
    <splitpanes
      v-if="!flow404"
      class="default-theme"
      :dbl-click-splitter="false"
      :style="splitPanesStyle"
      :max="100"
      @resize="handlePaneResize"
      @splitter-click="handleSplitterClick"
    >
      <pane
        ref="flowPaneEl"
        :size="flowPanesSize"
        :min-size="$vuetify.breakpoint.smAndDown ? flowPaneMinSize : 0"
      >
        <LoadingContainer :loading="!flow" fluid containerClass="pa-0">
          <reactWorkflowComponent
            :flow="flow"
            :nodeTypes="nodeTypes"
            :onSelectionChange="onSelectionChange"
            :onButtonPress="onButtonPress"
            :paneRect="flowPaneSize"
          />
        </LoadingContainer>
      </pane>
      <pane
        v-if="selectedNode"
        :size="100 - flowPanesSize"
        :min-size="$vuetify.breakpoint.smAndDown ? 100 - flowPaneMinSize : 10"
        class="scrollable"
      >
        <WorkflowSidebar
          v-show="flow"
          :node="selectedNode"
          :key="selectedNode.id"
          :isPending="isPending"
          :isManufacturedNonMRPFlow="isManufacturedNonMRPFlow"
        />
      </pane>
    </splitpanes>
    <NotFound v-else text="Flow Not Found" />
  </v-container>
</template>

<script>
import { computed, ref, onMounted } from "vue-demi";
import { useElementBounding, watchDebounced } from "@vueuse/core";
import { applyReactInVue } from "vuereact-combined";
import { useGet, useFind } from "../utils/feathers-pinia/lib";
import { get, hasIn } from "lodash-es";
import {
  OperationNode,
  BufferNode,
  InitiationNode,
  PartGroupNode,
  TransferNode,
  WaypointNode,
  MaterialSpecificationNode,
} from "../components-react/nodes";
import NotFound from "./NotFound.vue";
import { usePart } from "../store/part.pinia";
import { useFacilities } from "../store/facilities.pinia";
import { useFlow } from "../store/flow.pinia";
import { useFlowDetails } from "../store/flowDetails.pinia";
import { useOperationDetail } from "../store/operationDetail.pinia";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import LoadingContainer from "./LoadingContainer.vue";
import WorkflowSidebar from "./WorkflowSidebar.vue";
import { useBuffer } from "../store/buffer.pinia";
import { useBom } from "../store/bom.pinia";
import { useProcessTemplates } from "../store/processTemplates.pinia";
import { computedRef } from "../utils/composables";
import { useWorkcenters } from "../store/workcenters.pinia";
import { useOperation } from "../store/operation.pinia";
import { useRouter } from "../utils/useRouter";
import { useToast } from "vue-toastification/composition";
import { useInventory } from "../store/inventory.pinia";
import reactGraph from "../components-react/graph.jsx";
import { useBufferMetadata } from "../store/bufferMetadata.pinia.js";

const reactWorkflowComponent = applyReactInVue(reactGraph);

export default {
  name: "Workflow",
  components: {
    LoadingContainer,
    reactWorkflowComponent,
    Splitpanes,
    Pane,
    WorkflowSidebar,
    NotFound,
  },
  props: {
    flowId: {
      type: String,
      default: "1",
    },
  },
  setup(props, { root }) {
    const partStore = usePart();
    const facilitiesStore = useFacilities();
    const flowStore = useFlow();
    const flowDetailsStore = useFlowDetails();
    const operationStore = useOperation();
    const operationDetailStore = useOperationDetail();
    const bufferStore = useBuffer();
    const bomStore = useBom();
    const processStore = useProcessTemplates();
    const workcenterStore = useWorkcenters();
    const flowPaneEl = ref(null);
    const selectedNode = ref({ id: 0 });
    const router = useRouter();
    const toast = useToast();
    const inventoryStore = useInventory();
    const bufferMetadata = useBufferMetadata();

    // Make sure scroll position is reset when component is mounted
    onMounted(() => window.scrollTo(0, 0));

    const nodeTypes = {
      Operation: OperationNode,
      Buffer: BufferNode,
      SourceBuffer: BufferNode,
      DestinationBuffer: BufferNode,
      Initiation: InitiationNode,
      Material: MaterialSpecificationNode,
      MaterialSpec: MaterialSpecificationNode,
      PartGroup: PartGroupNode,
      Interfacility: TransferNode,
      Transfer: TransferNode,
      Waypoint: WaypointNode,
      AlternateLeg: BufferNode,
      BranchBuffer: BufferNode,
    };

    const { width: flowPaneWidth } = useElementBounding(flowPaneEl);
    const flowPaneSize = ref({ width: flowPaneWidth.value ?? 0 });

    watchDebounced(
      () => flowPaneWidth.value,
      (newVal) => {
        flowPaneSize.value = { width: newVal ?? 100 };
      },
      { debounce: 50, maxWait: 500 }
    );

    const { item, isPending: flowIsPending } = useGet({
      model: flowStore.Model,
      id: props.flowId,
    });

    // Flow exists status
    const flow404 = computed(() => {
      return !flowIsPending.value && !item?.value?.id;
    });

    // Flow detail params
    const flowDetailsParams = computedRef(() => {
      return {
        query: {
          // Use the returned id, so we don't fetch details on missing flows
          flow_id: item?.value?.id,
          $sort: { created: 1 },
          $limit: 1000,
          // $sort: { sequence: 1 }, // sort by sequence,
        },
      };
    });

    // Query for details once flow is loaded
    const flowDetailsQueryWhen = computedRef(
      () => !!get(flowDetailsParams?.value, "query.flow_id")
    );

    // Fetch process details
    const { items: flowDetails } = useFind({
      model: flowDetailsStore.Model,
      params: flowDetailsParams,
      queryWhen: flowDetailsQueryWhen,
    });

    // Build part query params
    const partParams = computedRef(() => {
      const partIds = flowDetails.value
        .map((item) => item.part_id)
        .filter((item) => !!item);
      return {
        query: {
          id: {
            $in: [...new Set(partIds)],
          },
          $limit: 500,
        },
      };
    });

    const { items: partResults, isPending: isPendingParts } = useFind({
      model: partStore.Model,
      params: partParams,
      queryWhen: computed(() => hasIn(partParams.value, "query.id.$in[0]")),
    });

    const operationIds = computed(() => {
      return flowDetails.value
        .map((item) => {
          if (
            item.type.toLowerCase().trim() === "operation" &&
            item.regarding_object_id &&
            item?.regarding_object.toLowerCase().trim() === "operation"
          ) {
            return item.regarding_object_id;
          } else return null;
        })
        .filter((item) => !!item);
    });

    const operationParams = computedRef(() => {
      return {
        query: {
          id: {
            $in: operationIds.value,
          },
          $limit: 1000,
        },
      };
    });

    const { items: operationResults } = useFind({
      model: operationStore.Model,
      params: operationParams,
      queryWhen: computed(() =>
        hasIn(operationParams.value, "query.id.$in[0]")
      ),
    });

    const operationDetailsParams = computedRef(() => {
      return {
        query: {
          operation_id: {
            $in: operationIds.value,
          },
          $limit: 1000,
        },
      };
    });

    const {
      items: operationDetailsResults,
      // isPending: operationDetailsPending,
    } = useFind({
      model: operationDetailStore.Model,
      params: operationDetailsParams,
      queryWhen: computed(() =>
        hasIn(operationDetailsParams.value, "query.operation_id.$in[0]")
      ),
    });

    const processIds = computed(() => {
      return operationResults.value?.map((item) => item.process_id) ?? [];
    });
    const processQuery = computedRef(() => {
      return {
        query: {
          id: { $in: processIds.value },
          $limit: 1000,
        },
      };
    });
    const { items: processResults } = useFind({
      model: processStore.Model,
      params: processQuery,
      queryWhen: computedRef(() => !!processQuery.value?.query.id.$in?.length),
    });

    const workcenterIds = computed(() => {
      return processResults.value?.map((item) => item.workcenter_id) ?? [];
    });
    const workcentersQuery = computedRef(() => {
      return {
        query: {
          id: { $in: workcenterIds.value },
          $limit: 1000,
        },
      };
    });
    const { items: workcentersResults } = useFind({
      model: workcenterStore.Model,
      params: workcentersQuery,
      queryWhen: computedRef(
        () => !!workcentersQuery.value?.query.id.$in?.length
      ),
    });

    const partFlowDetailsParams = computedRef(() => {
      const partIds = flowDetails.value
        .filter((item) => !!item && item.type === "PartGroup")
        .map((item) => item.part_id);

      return {
        query: {
          part_id: {
            $in: [...new Set(partIds)],
          },
          type: "PartGroup",
          $limit: 1000,
        },
      };
    });

    const { items: partFlowDetailsResults } = useFind({
      model: flowDetailsStore.Model,
      params: partFlowDetailsParams,
      queryWhen: computedRef(
        () => !!partFlowDetailsParams.value?.query.part_id.$in?.length
      ),
    });

    const bufferIds = computed(() => {
      return flowDetails.value
        .map((item) => {
          if (
            (item.type.toLowerCase().trim().includes("buffer") ||
              item.type.toLowerCase().trim().includes("alternateleg")) &&
            item.regarding_object_id &&
            item?.regarding_object.toLowerCase().trim() === "buffer"
          ) {
            return item.regarding_object_id;
          } else return null;
        })
        .filter((item) => !!item);
    });

    const regardingObjectParams = computedRef(() => {
      return {
        query: {
          regarding_object_id: {
            $in: bufferIds.value,
          },
          $limit: 2000,
          // type: {
          //   $in: ["SourceBuffer"],
          // },
          // $select: ["regarding_object_id", "flow_id"],
        },
      };
    });
    const { items: relatedFlowDetailsResults } = useFind({
      model: flowDetailsStore.Model,
      params: regardingObjectParams,
      queryWhen: computed(() =>
        hasIn(regardingObjectParams.value, "query.regarding_object_id.$in[0]")
      ),
    });

    // console.log("regardingObjectParams", regardingObjectParams);
    // console.log("relatedFlowDetailsResults", relatedFlowDetailsResults);

    const bufferParams = computedRef(() => {
      return {
        query: {
          id: {
            $in: bufferIds.value,
          },
          $limit: 1000,
        },
      };
    });

    const { items: bufferResults } = useFind({
      model: bufferStore.Model,
      params: bufferParams,
      queryWhen: computed(() => hasIn(bufferParams.value, "query.id.$in[0]")),
    });

    const requiredBomIds = computed(() => {
      return flowDetails.value
        .map((item) => {
          if (
            item.type.toLowerCase().trim().includes("sourcebuffer") ||
            item.type.toLowerCase().trim().includes("partgroup")
          ) {
            return {
              part_id: item.part_id,
              linked_to: flowDetails.value.find((obj) => {
                return obj.id === item.linked_to;
              }),
            };
          } else return null;
        })
        .filter((item) => !!item);
    });

    const bomChildPartIds = computed(() => {
      return requiredBomIds.value.map((item) => item.part_id);
    });

    const bomParentPartIds = computed(() => {
      return requiredBomIds.value
        .map((item) => item?.linked_to?.part_id)
        .filter((value, index, array) => array.indexOf(value) === index);
    });

    const bomParams = computedRef(() => {
      return {
        query: {
          child_part_id: {
            $in: bomChildPartIds.value,
          },
          parent_part_id: {
            $in: bomParentPartIds.value,
          },
          date_out: null, // Only fetch the current bom records
          $limit: 1000,
        },
      };
    });

    const { items: bomResults, isPending: isPendingBom } = useFind({
      model: bomStore.Model,
      params: bomParams,
      queryWhen: computed(
        () =>
          hasIn(bomParams.value, "query.parent_part_id.$in[0]") &&
          hasIn(bomParams.value, "query.child_part_id.$in[0]")
      ),
    });

    const inventoryParams = computedRef(() => {
      const regardingObjectIds = flowDetails.value
        .map((item) => item.regarding_object_id)
        .filter((item) => !!item);
      const flowDetailIds = flowDetails.value.map((item) => item.id);
      //combine regardingObjectIds and flowDetailIds into one array
      regardingObjectIds.push(...flowDetailIds);
      return {
        query: {
          regarding_object_id: { $in: regardingObjectIds },
        },
      };
    });

    const { items: inventoryResults, isPending: isPendingInventory } = useFind({
      model: inventoryStore.Model,
      params: inventoryParams,
      //queryWhen: () => has(inventoryParams.value, "query.regarding_object_id"),
    });

    const nestedObjectById = (
      parentObject,
      lookupField,
      cb,
      addAsKey = null
    ) => {
      const idField = `${lookupField}_id`;
      if (!parentObject[idField]) return {};
      return {
        [addAsKey ? addAsKey : lookupField]: cb(parentObject[idField]),
      };
    };

    const nestedInventoryObjectById = (parentObject, cb, addAsKey = null) => {
      return {
        [addAsKey]: cb(parentObject),
      };
    };

    const shouldQueryMetadata = computed(() =>
      hasIn(bufferParams.value, "query.id.$in[0]")
    );

    const { items: bufferMetadataResults, isPending: isPendingMetadata } =
      useFind({
        model: bufferMetadata.Model,
        params: bufferParams,
        queryWhen: shouldQueryMetadata,
      });

    // Return true when all data has been loaded
    const isDataLoaded = computed(() => {
      // Wait for flow and flow details to load
      if (!item.value || !Array.isArray(flowDetails.value)) return false;
      if (!flowDetails.value.length) return false;
      // Wait for inventory useFind to finish
      if (isPendingInventory.value) return false;
      // Wait for parts to load
      if (
        hasIn(partParams.value, "query.id.$in[0]") &&
        !partResults.value.length
      )
        return false;
      // Wait for Visual Initiation flows check to load
      if (shouldQueryMetadata.value && isPendingMetadata.value) return false;
      // Data is loaded!
      console.log("isDataLoaded");
      return true;
    });

    const isPending = computed(() => {
      return {
        part: isPendingParts.value,
        bom: isPendingBom.value,
      };
    });

    const isVisualFlow = computedRef(() => {
      return flowDetails.value
        .filter((item) => item.type === "Initiation")[0]
        ?.text.toLowerCase()
        .includes("visual");
    });

    const isManufacturedNonMRPFlow = computedRef(() => {
      return flowDetails.value
        .filter((item) => item.type === "Initiation")[0]
        ?.text.toLowerCase()
        .includes("non mrp");
    });

    const isChainedFlow = computedRef(() => {
      return flowDetails.value
        .filter((item) => item.type === "Initiation")[0]
        ?.text.toLowerCase()
        .includes("chained");
    });

    const getBufferMetadata = (buffer) => {
      const bufferMetadata = bufferMetadataResults?.value.find(
        (item) => item.id === buffer.regarding_object_id
      );

      // When buffer type is a source buffer, and the source flow is visual or chained, hide inventory
      const hideSourceBufferInventory =
        buffer.type === "SourceBuffer" &&
        ["visual", "chained"].includes(bufferMetadata?.source_flow_type);

      // When buffer type Initiation/Destination, and current flow is chained/visual, hide inventory
      const hideInventoryByCurrentFlowType =
        (isChainedFlow.value || isVisualFlow.value) &&
        ["Initiation", "DestinationBuffer"].includes(buffer.type);

      return {
        ...bufferMetadata,
        hide_inventory:
          hideSourceBufferInventory || hideInventoryByCurrentFlowType,
      };
    };

    const flow = computedRef(() => {
      // Return null if data is not loaded
      if (!isDataLoaded.value) return null;
      return {
        isManufacturedNonMRPFlow: isManufacturedNonMRPFlow,
        ...(item.value ? item.value : {}),
        flowDetails: flowDetails.value.map((detail) => {
          return {
            ...detail,
            ...nestedObjectById(detail, "facility", (id) => ({
              id: id,
              code: facilitiesStore.getFacilityCode(id),
            })),
            ...nestedObjectById(detail, "part", (id) =>
              partResults.value.find((part) => part.id === id)
            ),
            // returns operation_detail for a flow object as a regarding_object
            ...nestedObjectById(
              detail,
              "regarding_object",
              (id) =>
                operationDetailsResults?.value.find(
                  (opDet) => opDet.operation_id === id
                ),
              "operation_details"
            ),
            // returns buffer for a flow object as a regarding_object
            ...nestedObjectById(
              detail,
              "regarding_object",
              (id) => bufferResults?.value.find((buffer) => buffer.id === id),
              "buffer"
            ),
            // returns bom for a flow object as a bom
            ...nestedObjectById(
              detail,
              "part",
              (id) => bomResults?.value.find((bom) => bom.child_part_id === id),
              "bom"
            ),
            // returns process by operation_id from process_templates table
            ...nestedObjectById(
              detail,
              "regarding_object",
              (id) =>
                processResults?.value.find((process) => {
                  let processRes = operationResults?.value.find(
                    (operation) => operation.id === id
                  );
                  return process?.id === processRes?.process_id;
                }),
              "process"
            ),
            // returns workcenter by operation_id from process_templates table
            ...nestedObjectById(
              detail,
              "regarding_object",
              (id) =>
                workcentersResults?.value.find((workcenter) => {
                  let process = processResults?.value.find((process) => {
                    let operation = operationResults?.value.find(
                      (operation) => operation.id === id
                    );
                    return process?.id === operation?.process_id;
                  });
                  return workcenter?.id === process?.workcenter_id;
                }),
              "workcenter"
            ),
            ...nestedObjectById(
              detail,
              "regarding_object",
              (id) =>
                relatedFlowDetailsResults?.value.filter(
                  (relatedFlowDetail) =>
                    relatedFlowDetail.regarding_object_id === id
                ),
              "related_buffers"
            ),
            ...nestedObjectById(
              detail,
              "part",
              (id) =>
                partFlowDetailsResults?.value.filter(
                  (partFlowDetail) => partFlowDetail.part_id === id
                ),
              "related_part_groups"
            ),
            ...nestedInventoryObjectById(
              detail,
              (detail) =>
                inventoryResults?.value.filter(
                  (inventory) =>
                    inventory.regarding_object_id ===
                      detail.regarding_object_id ||
                    inventory.regarding_object_id === detail.id
                ),
              "inventory"
            ),
            metadata: getBufferMetadata(detail),
          };
        }),
      };
    });

    const onSelectionChange = (node) => {
      console.log("selectedNode:", node);
      selectedNode.value = node;
    };

    const onButtonPress = (node, buttonType, linkedFlowIds = []) => {
      // call different method according to to buttonType
      console.log(
        "node onPress",
        linkedFlowIds.length,
        buttonType,
        linkedFlowIds
      );

      switch (buttonType?.toLowerCase().trim()) {
        case "in": {
          inButtonPress(node, linkedFlowIds);
          break;
        }
        case "out": {
          outButtonPress(node, linkedFlowIds);
          break;
        }
        default: {
          console.warn("default case onButtonPress, this shouldn't happen");
        }
      }
    };

    const inButtonPress = (node, linkedFlowIds) => {
      let destination = linkedFlowIds.find(
        (item) => item.type === "DestinationBuffer"
      );

      // console.log("destination", destination);

      if (destination) {
        router.push({
          name: "FlowViewer",
          params: { flowId: destination.flow_id },
        });
      } else toast.error("No related flow found");
    };

    const outButtonPress = (node, relatedFlowDetails) => {
      let nonDestinationFlows = relatedFlowDetails;

      // filter to only the sourcebuffers, as per discovery meeting on 3/6/2023
      if (node.type != "PartGroup") {
        nonDestinationFlows = relatedFlowDetails.filter(
          (item) => item.type === "SourceBuffer"
        );
      }

      // console.log("outbuttonpress", relatedFlowDetails);

      // if out button is only linked to one flow, route to it
      if (nonDestinationFlows.length === 1) {
        // only route if the flow_id is different from the one you are currently viewing
        if (nonDestinationFlows[0].flow_id != node.flow_id)
          router.push({
            name: "FlowViewer",
            params: { flowId: nonDestinationFlows[0].flow_id },
          });
        else toast.error("Already viewing the only related flow");
        // if linked to multiple flows, route to search page and filter by those flow ids
      } else if (nonDestinationFlows.length > 1) {
        let relatedFlowIds = [];
        nonDestinationFlows.forEach((flowDetail) => {
          if (!relatedFlowIds.includes(flowDetail.flow_id)) {
            relatedFlowIds.push(flowDetail.flow_id);
          }
        });
        // console.log("relatedFlowIds", relatedFlowIds);
        //route to search page using linkedFlowIds.flow_id
        router.push({
          name: "FlowSearch",
          query: {
            flowIdFilters: relatedFlowIds,
          },
        });
      } else toast.error("No related flow found");
    };

    /**
     * Flow pane size (second pane is 100 - paneSize)
     * Default is 60 for large screens, 0 for small screens
     */
    const flowPanesSize = ref(root.$vuetify.breakpoint.smAndDown ? 100 : 60);
    // Min size is only used on mobile. Second pane is 100 - flowPaneMinSize
    const flowPaneMinSize = ref(0);

    const handlePaneResize = (e) => {
      flowPanesSize.value = e[0].size;
    };

    const handleSplitterClick = () => {
      // Don't do anything for small screens
      if (!root.$vuetify.breakpoint.smAndDown) return;
      flowPanesSize.value = flowPanesSize.value > 50 ? 0 : 100;
      flowPaneMinSize.value = flowPanesSize.value;
    };

    const splitPanesStyle = computed(() => {
      return {
        height: "auto",
      };
    });

    const containerClasses = computed(() => {
      return {
        "pa-0": true,
        "flow-viewer": true,
        "splitter-left": flowPanesSize.value < 30,
        "splitter-right": flowPanesSize.value > 49,
        "theme--dark": root.$vuetify.theme.dark,
      };
    });

    const containerStyles = computed(() => {
      return {
        "--splitter-bg": root.$vuetify.theme.dark
          ? root.$vuetify.theme.themes.dark.accent
          : root.$vuetify.theme.themes.light.accent,
        "--primary-color": root.$vuetify.theme.dark
          ? root.$vuetify.theme.themes.dark.primary
          : root.$vuetify.theme.themes.light.primary,
      };
    });

    return {
      flowPaneEl,
      flow,
      flow404,
      nodeTypes,
      onSelectionChange,
      onButtonPress,
      selectedNode,
      splitPanesStyle,
      isPending,
      flowPanesSize,
      handlePaneResize,
      handleSplitterClick,
      containerClasses,
      flowPaneSize,
      flowPaneMinSize,
      containerStyles,
      isManufacturedNonMRPFlow,
    };
  },
};
</script>

<style lang="scss">
@import "../../node_modules/reactflow/dist/style.css";
@import "~vuetify/src/styles/settings/_colors.scss";
@import "~vuetify/src/styles/settings/_variables.scss";

$arrow-color: #f4f4f4;

.flow-viewer {
  .splitpanes {
    height: calc(100vh - 7.8em) !important;
  }

  .splitpanes.default-theme .splitpanes__pane {
    background-color: inherit;
    z-index: 5;
  }

  .splitpanes.default-theme .splitpanes__splitter {
    width: 20px;
    background-color: var(--splitter-bg);
    border-left: none;
    border-right: none;
    border-radius: 3px;
    margin-right: 2px;
    transition: all 0.5s ease;

    // Arrow styling
    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 45vh; // x% down the viewable height
      left: 50%;
      width: 10px;
      height: 10px;
      background: none;
      border-bottom: 2px solid $arrow-color;
      border-right: 2px solid $arrow-color;
      margin: -5px;
      margin-left: 0px;
      transition: all 0.5s ease;
    }

    &::before {
      transform: rotate(-45deg) translate(0, 0);
      margin-left: -3px;
    }

    &::after {
      transform: rotate(135deg) translate(0, 0);
      margin-left: -6.5px;
    }

    &:hover {
      transition: all 0.5s ease;
      opacity: 0.8;
    }
  }

  &.splitter-left {
    .splitpanes.default-theme .splitpanes__splitter {
      &::after {
        transform: rotate(315deg) translate(0, 0);
        margin-left: -10.5px;
      }
    }
  }

  &.splitter-right {
    .splitpanes.default-theme .splitpanes__splitter {
      &::before {
        transform: rotate(135deg) translate(0, 0);
        margin-left: 0;
      }
    }
  }

  .scrollable {
    overflow-y: auto;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: var(--splitter-bg);
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(#fff, 0.5);
    border-radius: 5px;
  }
}
</style>
