<template>
  <v-dialog v-model="dialog" max-width="100%" width="400">
    <v-card>
      <v-card-title class="text-h5"> Update List Name </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field
          v-model="newListTitle"
          label="List Name"
          :rules="titleRules"
          @keyup.enter="update"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="close"> Cancel </v-btn>
        <v-btn color="green darken-1" text @click="update"> Update </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch } from "vue-demi";
import { useUserSavedLists } from "../store/userSavedLists.pinia";
import rules from "../utils/rules";

export default {
  name: "EditListNameDialog",
  props: {
    value: {},
    list: {},
  },
  setup(props, context) {
    const userSavedListsStore = useUserSavedLists();
    const newListTitle = ref(props.list.title);

    watch(
      () => props.list,
      () => {
        newListTitle.value = props.list.title;
      }
    );

    const dialog = computed({
      get() {
        return this.value;
      },
      set(val) {
        context.emit("input", val);
      },
    });

    const update = async () => {
      if (newListTitle.value !== "") {
        const list = new userSavedListsStore.Model({
          id: props.list.id,
          title: newListTitle.value,
          user_id: props.list.user_id,
        });
        await list.patch();

        close();
      }
    };

    const close = async () => {
      newListTitle.value = "";
      dialog.value = false;
    };

    return {
      update,
      dialog,
      newListTitle,
      close,
      titleRules: [rules.required],
    };
  },
};
</script>
