<template>
  <v-snackbar
    v-model="appStore.alert.enabled"
    :color="appStore.alert.color"
    :timeout="appStore.alert.timeout === 0 ? -1 : appStore.alert.timeout"
  >
    <span class="text-body-1">
      {{ appStore.alert.message }}
    </span>
    <template v-slot:action="{ attrs }">
      <v-btn text small v-bind="attrs" @click="close()"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { useStore } from "@/store/app.pinia.js";

export default {
  name: "AlertSnackbar",
  setup() {
    const appStore = useStore();

    function close() {
      return appStore.disableAlert();
    }

    return {
      appStore,
      close,
    };
  },
};
</script>
