import { computed, ref } from "vue-demi";
import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { orderBy } from "lodash-es";

export const useBomTreeHistory = defineStore("bom-tree-history", () => {
  const selectedPartHistory = useStorage("selectedPartHistory", []);
  const selectedPartHistoryIndex = useStorage("selectedPartHistoryIndex", null);
  const partResultsCache = useStorage("partResultsCache", []);
  const searchTerm = ref("");
  const partResults = ref([]);

  const selectedPartHistoryIndexItem = computed(() => {
    const lastItem = selectedPartHistory.value.length - 1;
    const selectedIndex =
      selectedPartHistoryIndex.value !== null
        ? selectedPartHistoryIndex.value
        : lastItem;

    return selectedIndex > -1 &&
      selectedIndex < selectedPartHistory.value.length
      ? selectedIndex
      : lastItem;
  });

  const selectedPartId = computed(() => {
    return (
      selectedPartHistory.value[selectedPartHistoryIndexItem.value] ?? null
    );
  });

  const historyBackDisabled = computed(() => {
    return (
      selectedPartHistory.value.length < 2 ||
      parseInt(selectedPartHistoryIndex.value) === 0
    );
  });

  const historyForwardDisabled = computed(() => {
    return (
      !selectedPartHistory.value.length ||
      selectedPartHistoryIndex.value === null ||
      parseInt(selectedPartHistoryIndex.value) ===
        selectedPartHistory.value.length - 1
    );
  });

  const historicCachedItems = computed(() => {
    return [...new Set(selectedPartHistory.value ?? [])]
      .map((id) => partResultsCache.value.find((item) => item.id === id))
      .filter((item) => item);
  });

  const allPartResults = computed(() => {
    const cleanedSearchTerm = (searchTerm.value ?? "").toLowerCase().trim();

    // Filter out current results from cached results
    const cachedResults = (historicCachedItems.value ?? []).filter(
      (cachedPart) =>
        !partResults.value?.some((result) => result.id === cachedPart.id)
    );

    // Start with current results and add unique cached results
    const results = [...partResults.value, ...cachedResults];

    const addSelectedPart =
      selectedPartId.value &&
      !results.some((part) => part.id === selectedPartId.value);

    const addExactMatch =
      cleanedSearchTerm.length &&
      !results.some(
        (part) => part.part_number.toLowerCase() === cleanedSearchTerm
      );

    // Add selected part if it's not in the results
    if (addSelectedPart) {
      const selectedPart = partResultsCache.value.find(
        (part) => part.id === selectedPartId.value
      );
      if (selectedPart) results.push(selectedPart);
    }

    // Add item from partResultsCache if it exists, and is not already in the results
    if (addExactMatch) {
      const cachedPart = partResultsCache.value.find(
        (part) => part.part_number.toLowerCase() === cleanedSearchTerm
      );
      if (cachedPart) results.push(cachedPart);
    }

    // Sort results based on defined criteria
    return orderBy(
      results,
      [
        (part) => part.part_number.toLowerCase() === cleanedSearchTerm,
        (part) => part.id === selectedPartId.value,
      ],
      ["desc", "desc"]
    );
  });

  const partSearchParams = computed(() => {
    const partNumber =
      typeof searchTerm.value === "string" && searchTerm.value.length
        ? searchTerm.value
        : "";
    // If it's not a valid query, return null
    if (!partNumber.length) return null;
    // Return the query
    const query = {
      $limit: 20,
      $include: [false],
      $select: ["part_number", "description", "status", "id"],
      part_number: {
        $iLike:
          !partNumber.length || partNumber.endsWith("%")
            ? partNumber
            : `${partNumber}%`,
      },
    };
    return { query };
  });

  const setSelectedPartId = (partId) => {
    if (partId === selectedPartId.value || !partId) return;
    if (selectedPartHistoryIndex.value !== null) {
      selectedPartHistory.value.splice(
        parseInt(selectedPartHistoryIndex.value) + 1
      );
      selectedPartHistoryIndex.value = null;
    }
    selectedPartHistory.value.push(partId);
    if (selectedPartHistory.value.length > 10) {
      selectedPartHistory.value.shift();
    }
  };

  const setHistoryBack = () => {
    const currentPosition =
      selectedPartHistoryIndex.value !== null
        ? selectedPartHistoryIndex.value
        : selectedPartHistory.value.length - 1;
    selectedPartHistoryIndex.value = Math.max(0, parseInt(currentPosition) - 1);
  };

  const setHistoryForward = () => {
    if (selectedPartHistoryIndex.value === null) return;
    selectedPartHistoryIndex.value = Math.min(
      selectedPartHistory.value.length - 1,
      parseInt(selectedPartHistoryIndex.value) + 1
    );
  };

  const addPartToCache = (part) => {
    if (partResultsCache.value.find((item) => item.id === part.id)) return;
    partResultsCache.value.push(part);
    if (partResultsCache.value.length > 75) {
      partResultsCache.value.shift();
    }
  };

  // Update any cached items that already exist in the cache
  const updateCachedItems = (items) => {
    for (const item of items) {
      const index = partResultsCache.value.findIndex(
        (cacheItem) => cacheItem.id === item.id
      );
      if (index > -1) {
        partResultsCache.value[index] = item;
      }
    }
  };

  return {
    selectedPartHistory,
    selectedPartHistoryIndex,
    partResultsCache,
    selectedPartId,
    setSelectedPartId,
    setHistoryBack,
    setHistoryForward,
    addPartToCache,
    updateCachedItems,
    historyBackDisabled,
    historyForwardDisabled,
    searchTerm,
    partResults,
    allPartResults,
    partSearchParams,
  };
});
