import React, { useEffect, useState, useCallback } from "react";
import ReactFlow, {
  // isNode,
  Background,
  MarkerType,
  ReactFlowProvider,
  useReactFlow,
  // useZoomPanHelper,
  // MiniMap,
  addEdge,
  useNodesState,
  useEdgesState,
  useOnViewportChange,
  getRectOfNodes,
} from "reactflow";
import { diff } from "deep-object-diff";
import { isObject, isEqual } from "lodash-es";
import "./index.css";
import { createGraphLayout } from "./elk-graph";
import { useIsTouchDevice } from "./utils";
// import { nodeTypes } from "./index.js";

React;

const waitMs = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function Graph(props) {
  const [xPosition, setXPosition] = useState(0);
  const [flow, setFlow] = useState([]);
  const [flowContainerSize, setFlowContainerSize] = useState({
    height: "100vh",
  });
  const [nodeTypes, setNodeTypes] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodesRect, setNodesRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [currentViewport, setCurrentViewport] = useState({
    y: 0,
    x: 0,
    zoom: 1,
  });
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  // Determine if we're on a touch device
  const isTouchDevice = useIsTouchDevice();

  const setViewport = (viewport = { y: 0 }) => {
    if (!reactFlowInstance || !reactFlowInstance?.viewportInitialized) return;
    const newViewport = {
      ...reactFlowInstance.getViewport(),
      ...viewport,
    };
    // Update it here for instant sets
    reactFlowInstance.setViewport(newViewport);
    // Set the new viewport
    setCurrentViewport(newViewport);
  };

  const isViewportChanged = () => {
    return !isEqual(currentViewport, reactFlowInstance.getViewport());
  };

  // Even though we're setting the viewport in setViewport, we need to ensure changes are persisted
  useEffect(() => {
    // Skip setting the viewport if there's no change
    if (!isViewportChanged()) return;
    reactFlowInstance.setViewport(currentViewport);
  }, [currentViewport]);

  const updateView = async () => {
    if (!reactFlowInstance || !reactFlowInstance?.viewportInitialized) {
      // Run this in a loop until viewport is initialized
      await waitMs(100);
      return;
    }
    // TODO: useEffect doesn't seem to wait long enough, check for a better solution
    // Wait just a bit for the view to update
    await waitMs(100);
    // Fit view
    reactFlowInstance.fitView();
    // update xPosition
    setXPosition(reactFlowInstance.getViewport().x);
    setViewport({ y: 0 });
    setNodesRect(getRectOfNodes(nodes));
    // console.log("updateView", reactFlowInstance.getViewport());
  };

  const isFirstReactFlowRender = React.useRef(true);

  useOnViewportChange({
    onChange: useCallback(
      (viewport) => {
        const nodesHeight = (nodesRect?.height ?? 100) * viewport?.zoom ?? 1;
        const nodesY = nodesRect?.y ?? 0;
        const viewportY = viewport?.y ?? 0;
        const maxViewportY = nodesY + 100;
        let minViewportY = 0 - (nodesHeight + nodesY) + nodesHeight / 5;
        if (minViewportY > 0) minViewportY = 0;
        // posX should never change from the currentViewport value (no side scrolling)
        const posX = currentViewport.x;
        const maxViewportX = xPosition;
        const minViewportX = xPosition * 4 * viewport.zoom;
        // Enforce max Y position
        if (viewportY > 0 && viewportY > maxViewportY) {
          setViewport({ y: maxViewportY });
        }
        // Enforce min Y position
        else if (viewportY < 0 && viewportY < minViewportY) {
          setViewport({ y: minViewportY });
        }
        // If x has changed, revert it (no side scrolling)
        else if (viewport?.x !== posX) {
          // if touch device, allow side scrolling up to the set X limit
          if (isTouchDevice) {
            // Enforce max X position
            if (viewport.x > maxViewportX) setViewport({ x: maxViewportX });
            // Enforce min X position
            if (viewport.x < minViewportX) setViewport({ x: minViewportX });
          } else setViewport({ x: posX });
        }
      },
      [setViewport]
    ),
  });

  useEffect(() => updateView(), [edges, props.paneRect.width]);

  useEffect(() => {
    // Set node types
    if (props?.nodeTypes) {
      setNodeTypes(props.nodeTypes);
    }
  }, [props.nodeTypes]);

  // Keep flow up to date
  useEffect(async () => {
    // If flow is not an object, don't update
    if (!isObject(props.flow)) return;
    // Compare current flow against the new one
    const flowDiff = diff(flow, props.flow);
    // If flow hasn't changed, don't update
    if (Object.keys(flowDiff).length === 0) return;
    // Update flow
    if (props.flow?.id !== flow?.id) isFirstReactFlowRender.current = true;
    setFlow(props.flow ?? {});
  }, [props.flow]);

  // Format and render flow data, when flow data changes
  useEffect(() => newFormatFlowData(flow), [flow]);

  // Set height of container based on node dimensions
  useEffect(() => {
    const height = nodesRect?.height < 700 ? "100vh" : `${nodesRect?.height}px`;
    setFlowContainerSize({ height });
  }, [nodesRect]);

  let reactFlowInstance = useReactFlow();

  let onSelectionChange = (event, node) => {
    let result = flow?.flowDetails.find((n) => n.id === node?.id);
    props.onSelectionChange(result);
  };

  let onButtonPress = (nodeData, buttonType, linkedFlowIds = []) => {
    // console.log("react onbuttonpress", nodeData, flow?.flowDetails);
    let result = flow?.flowDetails.find((n) => n.id === nodeData.detail.id);
    // console.log("react onbuttonpress result", result);
    props.onButtonPress(result, buttonType, linkedFlowIds);
  };

  let newFormatFlowData = (flow) => {
    let endingNode = flow?.flowDetails?.find(
      (detail) => detail.regarding_object_id === flow.buffer_id
    );

    if (!endingNode) return formatFlowData([]);

    if (isFirstReactFlowRender.current) {
      let initNode = flow?.flowDetails?.find(
        (detail) => detail.type === "Initiation"
      );
      if (initNode) {
        console.log("First render, setting selected node to init node");
        isFirstReactFlowRender.current = false;
        props.onSelectionChange(initNode);
      }
    }

    return formatFlowData(flow, flow?.flowDetails ?? []);
  };

  const getLinkedNodeIds = (node, flowDetails = []) => {
    return flowDetails.filter((detail) => detail.linked_to === node.id);
  };

  const createNode = (element, additionalData = {}) => {
    return {
      elementType: "node",
      id: element?.id,
      data: {
        child: element?.linked_to && !element?.linked_from,
        detail: element,
        label: element?.text,
        facility_id: element?.facility_id,
        facility: element?.facility,
        type: element?.type,
        onButtonPress: onButtonPress,
        part: element?.part,
        operation_details: element?.operation_details,
        buffer: element?.buffer,
        process: element?.process,
        workcenter: element?.workcenter,
        related_buffers: element?.related_buffers,
        is_non_mrp_flow: flow.isManufacturedNonMRPFlow,
      },
      noGap: element?.no_gap == true,
      type: element?.type,
      position: { x: 0, y: 0 },
      ...additionalData,
    };
  };

  const createEdge = (source, target, additionalData = {}) => {
    return {
      elementType: "edge",
      id: `${source}-${target}`,
      source: source,
      target: target,
      focusable: false,
      interactionWidth: 0,
      markerEnd: {
        type: MarkerType.Arrow,
        strokeWidth: 2,
        color: "#676767",
      },
      style: {
        stroke: "#676767",
        strokeWidth: 3,
      },
      ...additionalData,
    };
  };

  let formatFlowData = (flow, flowDetails) => {
    if (!flowDetails?.length) {
      setNodes([]);
      setEdges([]);
      return;
    }

    const items = flowDetails.reduce((acc, element) => {
      // Create the new node
      const newNode = createNode(element, {
        incomingLinks: getLinkedNodeIds(element, flowDetails),
      });

      // Add the new node to the accumulator
      acc.push(newNode);

      // Create and add edges
      if (element.linked_to) {
        acc.push(
          createEdge(element.linked_to, element.id, {
            sourceHandle: "rightHandle",
            targetHandle: "leftHandle",
            markerEnd: undefined,
            ...(element.type?.toLowerCase() === "alternateleg" ||
            element.type?.toLowerCase() === "branchbuffer"
              ? {
                  animated: true,
                }
              : {}),
          })
        );
      }
      if (element.linked_from) {
        acc.push(createEdge(element.linked_from, element.id));
      }

      return acc;
    }, []);

    createGraphLayout(items, flow).then((els) => {
      setNodes(els.nodes);
      setEdges(els.edges);
    });
  };

  const proOptions = { hideAttribution: true };
  const fitViewOptions = { padding: 0, includeHiddenNodes: true };

  return (
    <div style={flowContainerSize}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onSelectionChange}
        nodeTypes={nodeTypes}
        fitView={fitViewOptions}
        proOptions={proOptions}
        panOnDrag={isTouchDevice ? true : false}
        panOnScroll={true}
        maxZoom={1}
        zoomOnScroll={false}
      >
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
}

function FlowWithProvider(props) {
  return (
    <ReactFlowProvider>
      <Graph {...props} />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
