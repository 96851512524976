export const region = process.env.VUE_APP_REGION ?? "US";
export const env = process.env.VUE_APP_APPLICATION_ENV ?? "dev";
export const SSO_URL =
  process.env.VUE_APP_SSO_URL ?? "https://cpgranite.com/app-login";
export const SSO_HOME = new URL(SSO_URL).origin;
export const isRegionUS = region.toLowerCase() === "us";

const adminGroups = [
  "0776b709-6bb6-40d1-8685-465d4ce6b92c",
  "624a58e8-db63-455f-b59f-fe45819d318d",
];

const groups = {
  us: {
    dev: ["fe5f2662-2d13-402b-919d-1b875d631044"],
    qa: ["64df5403-7a0b-408e-af22-122c33633b57"],
    prd: ["c4058d9e-13a9-4f43-9ef7-65f7d67950b0"],
  },
  uk: {
    dev: ["5aabcd0a-b7b8-4885-b14e-7f9e9053c7d5"],
    qa: ["1ae4e3ef-bb3a-4c75-b089-129fd4feff9e"],
    prd: ["cf3400b3-4c18-4ac1-b5fd-e614e55c13a1"],
  },
};

export const appGroups = adminGroups.concat(
  groups[region.toLowerCase()]?.[env.toLowerCase()] ?? []
);

// Whether or not to disable search
export const disableSearch = false;

// Maintenance mode
export const maintenanceMode = false;
