<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title>
        {{ currentFacilityCode }} Saved Searches
        <v-spacer />
        <v-btn
          v-if="!!savedSearchResults.length"
          @click="isEditing = !isEditing"
          icon
          class="align-right"
        >
          <v-tooltip bottom v-if="!isEditing && appStore.canEditActiveFacility">
            <template #activator="{ on }">
              <v-icon v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            Edit Saved Searches
          </v-tooltip>
          <v-tooltip bottom v-else-if="isEditing">
            <template #activator="{ on }">
              <v-icon v-on="on" color="red"> mdi-pencil-off-outline </v-icon>
            </template>
            Stop Editing
          </v-tooltip>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        v-if="!savedSearchResults.length"
        class="text-center text-subtitle-1"
      >
        Nothing Saved Yet
      </v-card-text>
      <v-list subheader dense v-else>
        <draggable
          class="w-100"
          :disabled="!isEditing"
          :value="savedSearchResults"
          handle=".handle"
          @input="updateOrder"
        >
          <template v-for="(savedSearch, index) in savedSearchResults">
            <v-list-item
              :key="savedSearch.id"
              :to="!isEditing ? savedSearch.search_string : null"
              exact
            >
              <v-list-item-icon v-if="isEditing" class="handle">
                <v-icon>mdi-arrow-all</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="savedSearch.title" />
              </v-list-item-content>
              <v-list-item-icon v-if="!isEditing">
                <v-icon> mdi-magnify </v-icon>
              </v-list-item-icon>
              <!-- Edit Mode Icons -->
              <template v-if="isEditing">
                <v-list-item-icon>
                  <!-- <v-icon>mdi-location-enter</v-icon> -->
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="openEditSearchDialog(savedSearch)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    Change Search Name
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="red"
                        @click="openDeleteSearchDialog(savedSearch)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    Delete Search
                  </v-tooltip>
                </v-list-item-icon>
              </template>
            </v-list-item>
            <v-divider
              v-if="index < savedSearchResults.length - 1"
              :key="`${savedSearch.id}-div`"
            />
          </template>
        </draggable>
      </v-list>
    </v-card>

    <!-- delete search confirmation dialog -->
    <v-dialog v-model="deleteSearchDialog" max-width="100%" width="410">
      <v-card>
        <v-card-title>
          Are you sure you want to delete search: <br />
          " {{ selectedSearch.title }} " ?
        </v-card-title>
        <v-card-subtitle>
          Searches are associated with a facility, not a user. This will delete
          the search for <b>all users</b>.
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteSearchDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteSearch()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- change search name dialog, this one does this one need its own component? -->
    <v-dialog v-model="editSearchDialog" max-width="100%" width="400">
      <v-card>
        <v-card-title class="text-h5"> Edit Search Name </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="selectedSearch.title"
            label="Search Name"
            :rules="titleRules"
            @keyup.enter="saveSearchNameChange()"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="cancelSearchNameChange()">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="saveSearchNameChange()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { useStore } from "@/store/app.pinia.js";
import { useUserSavedSearches } from "@/store/userSavedSearches.pinia.js";
import { useFacilities } from "../store/facilities.pinia";
import { useFind } from "../utils/feathers-pinia/lib";
import { computed, ref, watch } from "vue-demi";
// import LoadingContainer from "./LoadingContainer.vue";
import draggable from "vuedraggable";
import rules from "../utils/rules";
import { computedRef } from "../utils/composables";

export default {
  name: "SidebarNavigationSearches",
  components: {
    // LoadingContainer,
    draggable,
  },
  setup() {
    const appStore = useStore();
    const userSavedSearchesStore = useUserSavedSearches();
    const facilitiesStore = useFacilities();
    const isEditing = ref(false);
    const deleteSearchDialog = ref(false);
    const editSearchDialog = ref(false);
    const selectedSearch = ref({});
    const isLoading = ref(false);
    const titleBackup = ref("");

    const currentFacilityCode = computed(() =>
      facilitiesStore.getFacilityCode(appStore.activeFacilityId)
    );

    const userSavedSearchParams = computedRef(() => {
      return {
        query: {
          facility_id: appStore.activeFacilityId,
        },
      };
    });

    const { items: userSavedSearchesResults, isPending: isPending } = useFind({
      model: userSavedSearchesStore.Model,
      params: userSavedSearchParams,
    });

    // Place to store working copy of search results
    const savedSearchResults = ref([]);

    watch(
      () => userSavedSearchesResults.value,
      (newVal) => {
        console.log("userSavedSearchesResults changed", newVal);
        if (newVal.length === 0) {
          // Clear out the savedSearchResults if there are no searches
          savedSearchResults.value = [];
          // Disable editing if there are no searches
          isEditing.value = false;
          return;
        }
        savedSearchResults.value = newVal.sort((a, b) => a.order - b.order);
      },
      { immediate: true, deep: true }
    );

    const openEditSearchDialog = (savedSearch) => {
      selectedSearch.value = savedSearch;
      titleBackup.value = savedSearch.title;
      editSearchDialog.value = true;
    };

    const openDeleteSearchDialog = (savedSearch) => {
      selectedSearch.value = savedSearch;
      deleteSearchDialog.value = true;
    };

    const deleteSearch = () => {
      isLoading.value = true;
      userSavedSearchesStore.remove(selectedSearch.value.id).finally(() => {
        isLoading.value = false;
        deleteSearchDialog.value = false;
      });
    };

    const saveSearchNameChange = () => {
      isLoading.value = true;
      userSavedSearchesStore
        .update(selectedSearch.value.id, selectedSearch.value)
        .finally(() => {
          isLoading.value = false;
          editSearchDialog.value = false;
          isEditing.value = false;
        });
    };

    const cancelSearchNameChange = () => {
      selectedSearch.value.title = titleBackup.value;
      editSearchDialog.value = false;
    };

    const updateOrder = (items) => {
      isLoading.value = true;

      savedSearchResults.value = items.map((item, index) => {
        item.order = index + 1;
        return item;
      });

      Promise.all(
        savedSearchResults.value.map((item) => {
          return item.patch({
            data: { order: item.order },
          });
        })
      )
        .catch((err) => {
          console.error("updateOrder error", err);
        })
        .then((res) => {
          console.log("updateOrder complete", res);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const loading = computed(() => {
      return isPending.value || isLoading.value;
    });

    return {
      appStore,
      currentFacilityCode,
      userSavedSearchesResults,
      savedSearchResults,
      isPending,
      isEditing,
      openEditSearchDialog,
      openDeleteSearchDialog,
      deleteSearchDialog,
      editSearchDialog,
      selectedSearch,
      deleteSearch,
      saveSearchNameChange,
      cancelSearchNameChange,
      titleRules: [rules.required],
      updateOrder,
      loading,
    };
  },
};
</script>
<style scoped>
.handle {
  cursor: grab;
  float: left;
  margin-left: -8px;
  margin-right: 8px;
}

.v-dialog > .v-card > .v-card__subtitle {
  padding: 12px 24px 20px;
}
</style>
