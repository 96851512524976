import { computed, unref } from "vue-demi";
import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "part-preschedule";
const usePartPreSchedule = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (partPreScheduleId) => {
      return state.items.filter((item) => item.id === partPreScheduleId);
    },
  },
  actions: {
    getPartPrescheduleFromFlowNode(detail, fallback = false) {
      return computed(() => {
        const node = unref(detail);

        // Get the regarding object info
        const regardingObjectType = node?.regarding_object;
        const regardingObjectId = node?.regarding_object_id;
        // If the regarding object is not a part preschedule, return undefined
        if (
          !fallback &&
          (typeof regardingObjectType !== "string" ||
            regardingObjectType?.toLowerCase() !== "partpreschedule")
        )
          return undefined;

        // Return the part preschedule, if it exists
        const result =
          node?.part?.part_preschedules?.find(
            (item) => item.id === regardingObjectId
          ) ?? undefined;
        // If result is undefined and fallback is set to true, return the first part_preschedule result
        if (!result && fallback) {
          return node?.part?.part_preschedules?.[0] ?? undefined;
        }
        // Otherwise, return the result!
        return result;
      });
    },
  },
});

api.service(servicePath).hooks({});

export { usePartPreSchedule };
