import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "operation-detail";
const useOperationDetail = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    getByOperationId: (state) => (opId) => {
      return state.items.filter((item) => item.operation_id === opId);
    },
  },
});

api.service(servicePath).hooks({});

export { useOperationDetail };
