import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "/buffer-metadata";
const useBufferMetadata = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
});

api.service(servicePath).hooks({});

export { useBufferMetadata };
