<template>
  <v-app>
    <LoadingContainer :loading="!appStore.isLoaded" containerClass="pb-0" fluid>
      <template v-if="!appStore.isReady">
        <router-view :key="$route.fullPath"></router-view>
      </template>
      <template v-else>
        <AppBar />

        <SidebarNavigationDrawer
          v-if="authStore.isAuthenticated && appStore.activeFacilityId"
        />

        <SidebarRight
          v-if="authStore.isAuthenticated && appStore.activeFacilityId"
        />

        <v-main class="">
          <LoadingContainer
            :loading="!authStore.isAuthenticated || !appStore.activeFacilityId"
            :label="
              authStore.isAuthenticated && !appStore.activeFacilityId
                ? 'Please select a facility to continue. <br />You have no default facility set. Contact your administrator to set a default facility.'
                : undefined
            "
            fluid
            containerClass="pa-0"
          >
            <transition name="fade-in">
              <KeepAlive
                include="AliveInternalParentPage,AliveInternalBasicPage"
              >
                <router-view></router-view>
              </KeepAlive>
            </transition>
          </LoadingContainer>
          <AlertSnackbarVue />
        </v-main>
      </template>
    </LoadingContainer>
  </v-app>
</template>

<script>
import { watch } from "vue-demi";
import AlertSnackbarVue from "./components/AlertSnackbar.vue";
import LoadingContainer from "./components/LoadingContainer.vue";
import AppBar from "@/components/AppBar";
import SidebarNavigationDrawer from "./components/SidebarNavigationDrawer.vue";
import SidebarRight from "./components/SidebarRight.vue";
// Import Toast Notifications
import { provideToast } from "vue-toastification/composition";
import "vue-toastification/dist/index.css";
// Import stores
import { useStore } from "@/store/app.pinia";
import { useAuth } from "@/store/auth.pinia.js";
import { useLogs } from "./store/logs.pinia";
import "./styles/global.scss";

export default {
  name: "App",
  components: {
    AppBar,
    SidebarNavigationDrawer,
    SidebarRight,
    AlertSnackbarVue,
    LoadingContainer,
  },
  setup(props, { root }) {
    const appStore = useStore();
    const authStore = useAuth();
    const logsStore = useLogs();
    window._LOGGING = logsStore; // Add to window for debugging
    // Initiate toast notifications interface
    provideToast({
      timeout: 5000,
    });

    // Watch for changes in authentication, and automatically redirect
    watch(
      () => authStore.isAuthenticated,
      () => {
        if (!authStore.isAuthenticated) {
          root.$router.push({ name: "Login", params: { saveFrom: true } });
        }
      }
    );

    // Redirect to the facility page whenever the active facility changes
    // watch(
    //   () => appStore.activeFacilityId,
    //   (facilityId) => {
    //     if (appStore.redirectPath) return;
    //     if (!facilityId) return;
    //     appStore.navigateToFacilityHome();
    //   }
    // );

    // App initialization
    appStore.initLoader();

    return {
      appStore,
      authStore,
    };
  },
};
</script>
