<template>
  <div class="text-center">
    <v-tooltip v-for="item in chips" :key="item.key" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          class="env-chip"
          label
          text-color="white"
          dark
          v-bind="attrs"
          v-on="on"
          :small="$vuetify.breakpoint.xsOnly"
          :large="$vuetify.breakpoint.mdAndUp"
        >
          <v-icon
            left
            :small="$vuetify.breakpoint.xsOnly"
            :large="$vuetify.breakpoint.mdAndUp"
          >
            {{ item.icon }}
          </v-icon>
          {{ item.label }}
        </v-chip>
      </template>
      <span>{{ item.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { region, env } from "../utils/variables";

export default {
  name: "AppBarEnv",
  setup() {
    let appRegion = region.toLowerCase();
    if (appRegion === "uk") appRegion = "gb";
    const chips = [
      {
        key: "region",
        icon: `fi-${appRegion}`,
        label: region,
        tooltip: "Region",
      },
      {
        key: "env",
        icon: "mdi-git",
        label: env,
        tooltip: "Environment",
      },
    ].filter((item) => {
      // Don't include environment chip on production
      if (env === "prd" && item.key === "env") {
        return false;
      }
      return true;
    });

    return {
      chips,
    };
  },
};
</script>

<style>
.env-chip {
  margin-right: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
}
</style>
