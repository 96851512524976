import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "user-saved-searches";
const useUserSavedSearches = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  actions: {
    addSavedSearch(title, searchString, facilityId) {
      const userSavedSearch = new this.Model({
        title: title,
        search_string: searchString,
        facility_id: facilityId,
      });
      return userSavedSearch.save();
    },
  },
});

api.service(servicePath).hooks({});

export { useUserSavedSearches };
