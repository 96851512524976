var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('CreateNewListDialog',{model:{value:(_vm.createListDialog),callback:function ($$v) {_vm.createListDialog=$$v},expression:"createListDialog"}}),_c('EditListNameDialog',{attrs:{"list":_vm.editingList},model:{value:(_vm.editListDialog),callback:function ($$v) {_vm.editListDialog=$$v},expression:"editListDialog"}}),_c('v-dialog',{attrs:{"max-width":"100%","width":"400"},model:{value:(_vm.deleteListDialog),callback:function ($$v) {_vm.deleteListDialog=$$v},expression:"deleteListDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delete List? ")]),_c('v-card-text',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.listToBeDeleted.title))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.deleteListDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteList(_vm.listToBeDeleted)}}},[_vm._v(" Delete ")])],1)],1)],1),_c('v-card',{attrs:{"loading":_vm.isPending}},[_c('v-card-title',[_vm._v(" My Flows Lists "),_c('v-spacer'),(!_vm.isEditing)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.createNewList()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-plus")])]}}],null,false,4199212921)},[_vm._v(" Create New List ")])],1):_vm._e(),(!!_vm.mySavedListofFlows.length)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){!_vm.isEditing ? _vm.enableEditing() : _vm.disableEditing()}}},[(!_vm.isEditing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-pencil-outline")])]}}],null,false,609529139)},[_vm._v(" Edit Lists ")]):(_vm.isEditing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v(" mdi-pencil-off-outline ")])]}}],null,false,1371151577)},[_vm._v(" Stop Editing ")]):_vm._e()],1):_vm._e()],1),_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""},model:{value:(_vm.openPanels),callback:function ($$v) {_vm.openPanels=$$v},expression:"openPanels"}},_vm._l((_vm.mySavedListofFlows),function(list){return _c('v-expansion-panel',{key:list.id,attrs:{"readonly":!list.flows.length}},[_c('v-expansion-panel-header',{attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4',"hide-actions":!list.flows.length}},[_vm._v(" "+_vm._s(list.title)+" ("+_vm._s(list.flows.length)+") "),(_vm.isEditing)?[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editListName(list)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_vm._v(" Edit List Name ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.startDeleteList(list)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_vm._v(" Delete List ")])],1)]:_vm._e()],2),_c('v-expansion-panel-content',{staticClass:"w-100 pa-0"},[_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('draggable',{staticStyle:{"width":"100%"},attrs:{"disabled":!_vm.isEditing,"list":list.flows,"handle":".handle"}},_vm._l((list.flows),function(savedFlow){return _c('v-list-item',{key:savedFlow.id,staticClass:"list-item pl-6 pr-0",attrs:{"two-line":"","dense":""}},[(_vm.isEditing)?_c('v-icon',{staticClass:"handle"},[_vm._v("mdi-arrow-all")]):_vm._e(),_c('span',[_c('v-list-item-title',[_vm._v(" "+_vm._s(savedFlow.destination_part_number)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(savedFlow.destination_buffer_description)+" ")])],1),_c('v-spacer'),(!_vm.isEditing)?_c('v-btn',{attrs:{"plain":"","text":""},on:{"click":function($event){return _vm.goToFlowViewer(savedFlow.flow_id)}}},[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.facilitiesStore.getFacilityCode(savedFlow.facility_id)))]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"icon-highlighted-white",attrs:{"color":_vm.getColorFromInitiationType(
                            savedFlow.initiation_type
                          )}},on),[_vm._v("mdi-location-enter")])]}}],null,true)},[_vm._v(" View Flow ")])],1):_vm._e(),(_vm.isEditing)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFlowFromList(savedFlow)}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_vm._v(" Delete Flow ")])],1):_vm._e()],1)}),1)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }