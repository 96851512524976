import axios from "axios";
import { computed, unref } from "vue-demi";
import { api } from "./feathers-api";
// import store from "../store";
export * from "./auth";

export const $api = api;

export const getBaseUrl = () =>
  (process.env.VUE_APP_API_URL || "").replace(/\/$/, "");

export const getFilesApiUrl = () =>
  (process.env.VUE_APP_FILES_API_URL || "").replace(/\/$/, "");

// Build an axios instance for talking to the API
function buildAxios(baseUrl) {
  const $http = axios.create({
    ...(baseUrl !== null ? { baseURL: baseUrl } : {}),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return $http;
}

export const $http = buildAxios(`${getBaseUrl()}/v1/`);

export const $filesHttp = buildAxios(getFilesApiUrl());

$filesHttp.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("feathers-jwt");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const getForm = (component) => {
  return component.$refs.form
    ? component.$refs.form
    : getForm(component.$parent);
};

// Recursive function to find parent function and validate the form
export const parentValidate = (component) => {
  return getForm(component).validate();
};

// Return if a value is a valid (non-empty) ID
export const validId = (val) => parseInt(val) > 0;

export const generateRandomId = (length = 8) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";
  for (let i = 0; i < length; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
};

export const createBindings = (sourceObjs, ignoreProperties = []) => {
  return computed(() => {
    if (!Array.isArray(sourceObjs)) sourceObjs = [sourceObjs];
    const bindings = {};
    sourceObjs.forEach((obj) => {
      const item = unref(obj);
      Object.keys(item).forEach((key) => {
        if (!ignoreProperties.includes(key)) {
          bindings[key] = obj[key];
        }
      });
    });
    return bindings;
  });
};

export const sortByStringProperty = (property) => {
  return (a, b) => {
    const aProperty = a[property]?.toLowerCase() ?? "";
    const bProperty = b[property]?.toLowerCase() ?? "";
    if (aProperty < bProperty) return -1;
    if (aProperty > bProperty) return 1;
    return 0;
  };
};

export default {
  getBaseUrl,
  generateRandomId,
  sortByStringProperty,
};
