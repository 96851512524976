<template>
  <span :style="styles" class="badge-custom">
    <slot v-if="noBadge"></slot>
    <v-badge
      v-else
      class="card-container-badge"
      :color="color"
      :icon="icon"
      :overlap="overlap"
      :left="left"
    >
      <template v-if="tooltip" v-slot:badge>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-text="icon" v-on="on" v-bind="attrs"></v-icon>
          </template>
          <span v-html="tooltip"></span>
        </v-tooltip>
      </template>
      <slot></slot>
    </v-badge>
  </span>
</template>

<script>
import { computed } from "vue-demi";

export default {
  name: "BadgeContainer",
  props: {
    noBadge: {
      type: Boolean,
      default: () => false,
    },
    color: {
      type: String,
      default: () => undefined,
    },
    icon: {
      type: String,
      default: () => undefined,
    },
    overlap: {
      type: Boolean,
      default: () => true,
    },
    left: {
      type: Boolean,
      default: () => true,
    },
    display: {
      type: String,
      default: () => "block",
    },
    tooltip: {
      type: String,
      default: () => undefined,
    },
  },
  setup(props) {
    const styles = computed(() => {
      return {
        "--custom-badge-display": props.display,
      };
    });

    return {
      styles,
    };
  },
};
</script>

<style lang="scss" scoped>
.badge-custom {
  .v-badge.card-container-badge {
    display: var(--custom-badge-display);
  }
}
</style>
