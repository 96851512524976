var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2 height-100"},[_c('v-row',[_c('v-col',{staticClass:"text-right mb-2"},[(_vm.$can('update', 'help'))?_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.help.editMode),callback:function ($$v) {_vm.$set(_vm.help, "editMode", $$v)},expression:"help.editMode"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":false}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,false,942338593)},[_vm._v(" View ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":true}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,2704887310)},[_vm._v(" Edit ")])],1):_vm._e(),_c('v-btn-toggle',{staticClass:"ml-2",model:{value:(_vm.closeBtn),callback:function ($$v) {_vm.closeBtn=$$v},expression:"closeBtn"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","value":true},on:{"click":function($event){return _vm.help.close()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_vm._v(" Close ")])],1)],1)],1),(_vm.open && _vm.helpItem && !_vm.help.editMode)?_c('Viewer',{attrs:{"initialValue":_vm.helpItem.content,"options":_vm.options}}):_vm._e(),(_vm.$can('update', 'help') && _vm.help.editMode && _vm.helpItem)?_c('InlineForm',{attrs:{"entity":_vm.helpItem,"entityStore":_vm.help,"canEdit":true,"fields":['content'],"height":"100%","noBadge":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var childProps = ref.childProps;
var childEvents = ref.childEvents;
return [(_vm.open)?_c('Editor',{ref:"editor",attrs:{"initialValue":childProps.content.value,"options":_vm.editorOptions,"height":"calc(100% - 100px)"},on:{"change":_vm.onEditorChange}}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mt-2 text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","disabled":!_vm.hasChanges},on:{"click":_vm.reload}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.hasChanges,"loading":childProps.content.loading},on:{"click":function () { return _vm.save(childEvents.content.input); }}},[_vm._v(" Save ")])],1)],1)]}}],null,false,3439211665)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }