import Vue from "vue";
import { i18n } from "../i18n";
import { defineStore } from "pinia";
import router from "../router";
import { useUserSettings } from "./userSettings.pinia";
import { useFacilities } from "./facilities.pinia";
import { useWorkcenters } from "./workcenters.pinia";
import { useProcessTemplates } from "./processTemplates.pinia";
import { useAuth } from "./auth.pinia";
import { useI18n } from "./i18n.pinia";

export const useStore = defineStore("app", {
  state: () => {
    return {
      isLoaded: false,
      isAuthenticated: false,
      drawer: true,
      currentRoute: null,
      // Place to store current ids
      current: {
        workcenterId: null,
        facilityId: null,
        processId: null,
      },
      redirectPath: null,
      alert: {
        enabled: false,
        message: "",
        timeout: 0,
        color: "success",
      },
      facilityOverride: null,
      breadcrumbPath: [],
      appBarSize: {
        height: 0,
        width: 0,
      },
      flowviewerAccordionExpansionPrefs: {},
    };
  },
  getters: {
    isAuthenticated() {
      const authStore = useAuth();
      return !!authStore.isAuthenticated;
    },
    dataLoaded() {
      const authStore = useAuth();
      return !!authStore.dataLoaded;
    },
    isReady() {
      return this.isAuthenticated && this.dataLoaded;
    },
    facilityIsActive() {
      const facilitiesStore = useFacilities();
      return (id) => {
        return (
          facilitiesStore.itemsById[id] &&
          !facilitiesStore.itemsById[id]?.hide_in_dropdown
        );
      };
    },
    defaultFacilityId() {
      const authStore = useAuth();
      const userSettingsStore = useUserSettings();
      // Find a matching default facility, belonging to this user
      const facilityId =
        userSettingsStore.items.find(
          (item) =>
            item.property === "default_facility_id" &&
            item.user_id === authStore.userId
        )?.value || null;
      // Return the ID, as long as it's active
      return facilityId !== null && this.facilityIsActive(facilityId)
        ? facilityId
        : null;
    },
    // gets the facility the user is currently logged into and selected in the dropdown
    activeFacility() {
      const facilitiesStore = useFacilities();
      return facilitiesStore.getFromStore(this.activeFacilityId);
    },
    activeFacilityId(state) {
      return state.facilityOverride || this.defaultFacilityId || null;
    },
    // can the user edit the facility currently selected in the dropdown
    canEditActiveFacility() {
      if (!this.activeFacilityId) return false;
      return Vue.prototype.$ability.can("manageChildren", this.activeFacility);
    },
    currentWorkcenter(state) {
      if (state.current.workcenterId === null) return null;
      const workcentersStore = useWorkcenters();
      // Fetch the workcenter, if it doesn't exist locally already
      workcentersStore.get(state.current.workcenterId ?? false, {
        skipRequestIfExists: true,
      });
      return workcentersStore.itemsById[state.current.workcenterId] ?? null;
    },
    currentFacility(state) {
      if (state.current.facilityId === null) return null;
      const facilitiesStore = useFacilities();
      return facilitiesStore.getFromStore(state.current.facilityId) ?? null;
    },
    currentProcess(state) {
      if (state.current.processId === null) return null;
      const processTemplatesStore = useProcessTemplates();
      // Fetch the process, if it doesn't exist locally already
      processTemplatesStore.get(state.current.processId ?? false, {
        skipRequestIfExists: true,
      });
      return processTemplatesStore.itemsById[state.current.processId] ?? null;
    },
    breadcrumbs(state) {
      const options = {
        Admin: {
          text: "Admin",
          disabled: true,
        },
        Users: {
          text: "Users",
          href: "/#/admin/users",
        },
        Permissions: {
          text: "Permissions",
          to: { name: "AdminUsersPermissions" },
        },
        FacilityOverview: {
          text: this.currentFacility?.title,
          subtitle: i18n.tc("facility"),
          to: {
            name: "FacilityOverview",
            params: { facilityId: this.currentFacility?.id },
          },
        },
        WorkcenterOverview: {
          text: this.currentWorkcenter?.title,
          subtitle: i18n.tc("workcenter"),
          to: {
            name: "WorkcenterOverview",
            params: {
              // TODO: fix warning when fallback is null
              workcenterId: this.currentWorkcenter?.id ?? "-",
            },
          },
          badge: {
            color: this.currentWorkcenter?.active ? "success" : "error",
          },
        },
        ProcessOverview: {
          text: this.currentProcess?.title,
          subtitle: "Process",
          to: {
            name: "ProcessOverview",
            params: {
              workcenterId: this.currentWorkcenter?.id,
              processId: this.currentProcess?.id,
            },
          },
          badge: {
            color: this.currentProcess?.active ? "success" : "error",
          },
        },
        FlowSearch: {
          text: "Flow Search",
          to: { name: "FlowSearch" },
          icon: "mdi-magnify",
        },
        FlowViewer: {
          text: "Flow Viewer",
        },
      };

      return state.breadcrumbPath.map((item) => {
        return options[item] ?? { text: item, disabled: true };
      });
    },
    canEditCurrentFacility() {
      if (!this.currentFacility) return false;
      return Vue.prototype.$ability.can("manageChildren", this.currentFacility);
    },
    canEditCurrentProcess() {
      return this.canEditCurrentFacility && this.currentProcess?.active == true;
    },
  },
  actions: {
    async initLoader() {
      const i18nStore = useI18n();
      await i18nStore.load();
      this.isLoaded = true;
    },
    async setCurrentRoute(route) {
      this.currentRoute = route;
      // Update "current" items
      const newCurrent = {
        workcenterId: route?.params?.workcenterId ?? null,
        facilityId:
          route?.params?.facilityId ?? route?.query?.facilityId ?? null,
        processId: route?.params?.processId ?? null,
      };
      // If facility is null (but a workcenter is set), set the facility id from the workcenter
      if (!newCurrent.facilityId && newCurrent.workcenterId) {
        const workcenter = await useWorkcenters().get(newCurrent.workcenterId, {
          skipRequestIfExists: true,
        });
        newCurrent.facilityId = workcenter.facility_id;
      }
      // Set the current items
      Object.assign(this.current, newCurrent);
    },
    setRedirectPath(path) {
      this.redirectPath = path;
    },
    navigateToFacilityHome(next = null) {
      // Define destination
      const route = this.activeFacilityId
        ? {
            name: "FacilityOverview",
            params: { facilityId: this.activeFacilityId },
          }
        : {
            name: "Home",
          };
      // Use next when provided, otherwise use the router
      return next ? next(route) : router.push(route).catch(() => {});
    },
    setAlert({ message = null, timeout = 5000, color = "success" } = {}) {
      if (timeout) {
        this.alert.timeout = timeout;
      }
      this.alert.color = color;
      this.alert.message = message;
      this.alert.enabled = !!message?.length ?? false;
    },
    disableAlert() {
      this.alert.enabled = false;
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    afterLogout() {
      this.facilityOverride = null;
    },
    setBreadcrumbPath(breadcrumbPath) {
      this.breadcrumbPath = breadcrumbPath;
    },
    setFlowViewerAccordionExpansionPrefs(expansionPrefs) {
      expansionPrefs.forEach((item) => {
        this.flowviewerAccordionExpansionPrefs.find(
          (i) => i.id === item.id
        ).expanded = item.expanded;
        return item;
      });
    },
  },
});
