import Vue from "vue";
import VueRouter from "vue-router";
import { useStore } from "@/store/app.pinia";
import routes from "./routes.js";
import qs from "qs";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? `?${result}` : "";
  },
});
// Check if a given route/path is the home page
const isHomePath = (path = "/") => router.resolve(path).route.path === "/";
// Check if a given route/path is the login page
const isLoginPath = (path = "/") =>
  router.resolve(path).route.path === "/login";
// Check if two routes are the same
const compareRoutes = (route1, route2) => {
  const resolved1 = router.resolve(route1);
  const resolved2 = router.resolve(route2);
  if (resolved1.route.path === resolved2.route.path) {
    return true;
  }
  return false;
};

router.beforeEach(async (to, from, next) => {
  const appStore = useStore();
  // Set variables for certain paths
  const setPathVars = async (nextPath = null) => {
    if (nextPath) {
      // Don't bother saving variables for certain paths
      if (nextPath === true || isLoginPath(nextPath)) return;
    }
    // Store the current route
    await appStore.setCurrentRoute(to);
    // Set the breadcrumbs for this route
    appStore.setBreadcrumbPath(to?.meta?.breadcrumbPath ?? []);
  };
  // Handle redirects with some smart logic
  const handleRedirect = async (path, setRedirect = false) => {
    // Resolve the destination path
    const toPath = router.resolve(path).route.path;
    // If setRedirect is true, save the current to path
    if (setRedirect) {
      // Don't overwrite the redirect path if it's already set
      if (appStore.redirectPath === null) {
        // If this isn't login or home...
        if (!isLoginPath(to) && !isHomePath(to)) {
          // Save the path we intended to go to
          appStore.setRedirectPath(to.fullPath);
        } else if (to?.params?.saveFrom) {
          // If we have a parameter to save the from path, save it
          appStore.setRedirectPath(from.fullPath);
        }
      }
    } else if (appStore.redirectPath !== null) {
      // We have a stored redirect path, so go to it
      const redirectPath = appStore.redirectPath; // Store path locally
      appStore.setRedirectPath(null); // Clear the stored path
      // If the paths are different, redirect to the saved path
      if (!compareRoutes(redirectPath, to)) {
        await setPathVars(redirectPath);
        return next(redirectPath);
      }
    }
    // Set variables
    await setPathVars(toPath);
    // If the paths are different, redirect to the destination path
    if (!compareRoutes(toPath, to)) {
      return next(toPath);
    } else {
      // Otherwise, continue!
      return next();
    }
  };
  // Logic for unauthenticated users
  if (!appStore.isAuthenticated) {
    // Redirect to login page
    return handleRedirect({ name: "Login" }, true);
  }
  // Logic for authenticated users
  if (appStore.isAuthenticated) {
    // Check if we need to redirect
    if (appStore.redirectPath !== null) {
      // Redirect to the saved redirect path
      return handleRedirect(appStore.redirectPath);
    }
    // Set the path vars
    await setPathVars(to);
    // If we're on the login path, navigate home
    if (isLoginPath(to)) {
      return next({ name: "Home" });
    }
  } else {
    await setPathVars(to);
  }
  // Default next
  next();
});

export default router;
