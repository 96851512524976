<template>
  <v-card outlined min-width="450" min-height="150" class="mx-auto text-center">
    <v-card-title class="text-center">
      <v-btn
        v-if="showBackButton"
        icon
        color="primary"
        class="mx-2"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ text }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    title: {
      type: String,
      default: () => "Not Found",
    },
    showBackButton: {
      type: Boolean,
      default: () => true,
    },
    text: {
      type: String,
      default: () => "Page not found",
    },
  },
};
</script>
