import { defineAuthStore } from "feathers-pinia";
import { set } from "vue-demi";
import { api as feathersClient } from "../utils/feathers-api";
import { useStore } from "./app.pinia";
import { useUserSettings } from "./userSettings.pinia";
import { useFacilities } from "./facilities.pinia";
import { useProcessTemplateFieldTypes } from "./processTemplateFieldTypes.pinia";
import router from "../router";

export const status = {
  loggedOut: 0,
  loading: 1,
  loggedIn: 2,
};

export const useAuth = defineAuthStore({
  feathersClient,
  state: () => ({
    dataLoaded: false,
    authentication: {
      payload: {
        azureUser: {
          id: null,
          displayName: null,
          mail: null,
          memberOf: null,
        },
      },
    },
  }),
  getters: {
    user(state) {
      return state.authentication?.payload?.azureUser?.id?.length
        ? state.authentication.payload.azureUser
        : null;
    },
    userId() {
      return this.user?.id || null;
    },
  },
  actions: {
    setDataLoaded(loading = true) {
      this.dataLoaded = loading;
    },
    async afterLogin() {
      const userSettingsStore = useUserSettings();
      const facilitiesStore = useFacilities();
      const processTemplateFieldTypesStore = useProcessTemplateFieldTypes();
      try {
        await Promise.all([
          // Load facilities
          facilitiesStore.find({ query: {} }),
          // Load user settings
          userSettingsStore.find({
            query: {
              user_id: this.userId,
            },
          }),
          // Load all the process template field types
          processTemplateFieldTypesStore.find({ query: {} }),
        ]);
      } catch (error) {
        console.error("afterLogin loading error: ", error);
      }
      // Set data load state
      this.setDataLoaded();
      // Set up autologout timer
      setTimeout(this.checkLoginTimeout, this.getLoginTimeout() + 50);
      // Navigate home
      router
        .push({ name: "Home" })
        // Ignore errors (route guard can interrupt)
        .catch(() => {});
    },
    getLoginTimeout() {
      // Return the login timeout in seconds
      const now = Math.round(Date.now() / 1000);
      const expires = this.authentication?.payload?.exp ?? now;
      const diff = expires - now;
      return diff * 1000;
    },
    checkLoginTimeout() {
      const timeout = this.getLoginTimeout();
      if (timeout <= 0) {
        // Logout and reload
        this.logout(true);
      }
    },
    async logout(reload = true) {
      const finallyStep = async () => {
        // TODO: verify route state
        await this.afterLogout();
        this.$reset();
        if (window?.location && reload) window.location.reload();
      };
      return this.feathersClient
        .logout()
        .catch(() => {})
        .finally(() => {
          return finallyStep();
        });
    },
    async afterLogout() {
      const appStore = useStore();
      set(this, "isAuthenticated", false);
      appStore.afterLogout();
    },
  },
});
