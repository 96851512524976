<template>
  <div class="pa-2 height-100">
    <v-row>
      <v-col class="text-right mb-2">
        <v-btn-toggle
          v-if="$can('update', 'help')"
          v-model="help.editMode"
          mandatory
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :value="false" v-on="on" v-bind="attrs">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            View
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :value="true" v-on="on" v-bind="attrs">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Edit
          </v-tooltip>
        </v-btn-toggle>

        <v-btn-toggle v-model="closeBtn" class="ml-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                @click="help.close()"
                :value="true"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            Close
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <Viewer
      v-if="open && helpItem && !help.editMode"
      :initialValue="helpItem.content"
      :options="options"
    />
    <InlineForm
      v-if="$can('update', 'help') && help.editMode && helpItem"
      :entity="helpItem"
      :entityStore="help"
      :canEdit="true"
      :fields="['content']"
      height="100%"
      noBadge
    >
      <template v-slot:default="{ childProps, childEvents }">
        <Editor
          v-if="open"
          ref="editor"
          :initialValue="childProps.content.value"
          :options="editorOptions"
          height="calc(100% - 100px)"
          @change="onEditorChange"
        />
        <v-row>
          <v-col class="mt-2 text-right">
            <v-btn
              color="red"
              class="mr-2"
              :disabled="!hasChanges"
              @click="reload"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              :disabled="!hasChanges"
              :loading="childProps.content.loading"
              @click="() => save(childEvents.content.input)"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </InlineForm>
  </div>
</template>

<script>
import { computed, nextTick, ref, watch } from "vue-demi";
import { useGet } from "feathers-pinia";
import { Editor, Viewer } from "@toast-ui/vue-editor";
import InlineForm from "./InlineFieldsForm.vue";
import { useHelp } from "../store/help.pinia";
import { useStore } from "../store/app.pinia";
// Styles
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";

export default {
  name: "HelpContent",
  components: {
    Viewer,
    Editor,
    InlineForm,
  },
  setup(props, { root, refs }) {
    const appStore = useStore();
    const help = useHelp();
    const open = ref(true);
    const closeBtn = ref(false);
    const hasChanges = ref(false);

    const helpItemid = computed(() => help.activeId);

    const { item: helpItem } = useGet({
      model: help.Model,
      id: helpItemid,
    });

    const options = computed(() => {
      return {
        theme: root.$vuetify.theme.dark ? "dark" : "light",
        usageStatistics: false,
      };
    });

    const editorOptions = computed(() => {
      return {
        ...options.value,
        hideModeSwitch: true,
        toolbarItems: [
          ["heading", "bold", "italic", "strike"],
          ["hr", "quote"],
          ["ul", "ol"],
          ["scrollSync"],
        ],
      };
    });

    const getValue = () => {
      return refs.editor?.invoke("getMarkdown") ?? undefined;
    };

    const onEditorChange = () => {
      const oldVal = helpItem?.value?.content ?? undefined;
      const newVal = getValue();
      if (oldVal === undefined) {
        hasChanges.value = false;
        return;
      }
      hasChanges.value = oldVal !== newVal;
    };

    const reload = async () => {
      open.value = false;
      await nextTick();
      open.value = true;
      await nextTick();
      onEditorChange();
    };

    const save = async (saveHandler) => {
      const newVal = getValue();
      await saveHandler(newVal); // Save the new value
      await nextTick(); // Wait for the save to reflect
      reload(); // Reload the editor, to ensure we have the latest content
      appStore.setAlert({ message: "Saved" });
    };

    watch(
      () => options.value,
      () => reload(),
      { deep: true }
    );

    watch(
      () => closeBtn.value,
      async () => {
        await nextTick();
        closeBtn.value = false;
      }
    );

    return {
      open,
      hasChanges,
      closeBtn,
      options,
      editorOptions,
      help,
      helpItem,
      onEditorChange,
      reload,
      save,
    };
  },
};
</script>

<style lang="scss">
.toastui-editor-contents {
  h1,
  h2 {
    border-bottom: none !important;
  }
}

.height-100 {
  height: 100%;
}
</style>
