<template>
  <v-container :fluid="fluid" :class="containerClass" :fill-height="fillHeight">
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
      v-if="loading"
    >
      <v-col class="text-subtitle-1 text-center" cols="12" v-html="label">
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="primary accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <slot v-else></slot>
  </v-container>
</template>

<script>
export default {
  name: "LoadingContainer",
  props: {
    label: {
      type: String,
      default: "Loading...",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: () => "",
    },
  },
};
</script>
