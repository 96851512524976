import { defineStore } from "./store.pinia";
import { api } from "@/utils/feathers-api";

const servicePath = "bom-tree";
const useBomTree = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  state: () => ({}),
  getters: {
    byId: (state) => (bomId) => {
      return state.items.find((item) => item.id === bomId);
    },
  },
  actions: {
    async fetchBOMPartById(bomId) {
      return await this.get(bomId, { skipRequestIfExists: true });
    },
  },
});

api.service(servicePath).hooks({});

export { useBomTree };
