import { _ } from "@feathersjs/commons";
import { set } from "vue-demi";
import stringify from "fast-json-stable-stringify";
import ObjectID from "bson-objectid";
import fastCopy from "fast-copy";
function stringifyIfObject(val) {
  if (typeof val === "object" && val != null) {
    return val.toString();
  }
  return val;
}
/**
 * Get the id from a record in this order:
 *   1. the `idField`
 *   2. id
 *   3. _id
 * @param item
 * @param idField
 */
export function getId(item, idField) {
  if (!item) return;
  if (idField && item[idField] != undefined) {
    return stringifyIfObject(item[idField]);
  }
  if (item.id != undefined) {
    return stringifyIfObject(item.id);
  }
  if (item._id != undefined) {
    return stringifyIfObject(item._id);
  }
}
export function getTempId(item, tempIdField) {
  if (item?.[tempIdField] != undefined) {
    return stringifyIfObject(item[tempIdField]);
  }
}
export function getAnyId(item, tempIdField) {
  return getId(item) != undefined ? getId(item) : getTempId(item, tempIdField);
}
export function getQueryInfo(params = {}, response = {}) {
  const { query = {}, qid = "default" } = params;
  const $limit = response.limit != undefined ? response.limit : query?.$limit;
  const $skip = response.skip != undefined ? response.skip : query?.$skip;
  const pageParams = $limit !== undefined ? { $limit, $skip } : undefined;
  const pageId = pageParams ? stringify(pageParams) : undefined;
  const queryParams = _.omit(query, "$limit", "$skip");
  const queryId = stringify(queryParams);
  return {
    qid,
    query,
    queryId,
    queryParams,
    pageParams,
    pageId,
    response: undefined,
    isOutdated: undefined,
  };
}
export function getItemsFromQueryInfo(pagination, queryInfo, keyedById) {
  const { queryId, pageId } = queryInfo;
  const queryLevel = pagination[queryId];
  const pageLevel = queryLevel && queryLevel[pageId];
  const ids = pageLevel && pageLevel.ids;
  if (ids && ids.length) {
    return ids.map((id) => keyedById[id]);
  } else {
    return [];
  }
}
export function keyBy(items, fn = (i) => getId(i)) {
  return items.reduce((all, current) => {
    const id = fn(current);
    all[id] = current;
    return all;
  }, {});
}
/**
 * Generate a new tempId and mark the record as a temp
 * @param state
 * @param item
 */
export function assignTempId(item, tempIdField) {
  const newId = new ObjectID().toHexString();
  set(item, tempIdField, newId);
  return item;
}
/**
 * Cleans data to prepare it for the server.
 * @param data item or array of items
 * @returns items without private attributes like __isClone and __tempId
 */
export function cleanData(data, tempIdField) {
  const { items, isArray } = getArray(data);
  const cleaned = items.map((item) => _.omit(item, "__isClone", tempIdField));
  return isArray ? cleaned : cleaned[0];
}
/**
 * Restores tempIds to the records returned from the server. The tempIds need to be
 * temporarily put back in place in order to migrate the objects from the tempsById
 * into the itemsById. A shallow copy of the object
 *
 * Note when data is an array, it doesn't matter if the server
 * returns the items in the same order. It's only important that all of the correct
 * records are moved from tempsById to itemsById
 *
 * @param data item(s) before being passed to the server
 * @param responseData items(s) returned from the server
 */
export function restoreTempIds(data, resData, tempIdField) {
  const { items: sourceItems, isArray } = getArray(data);
  const { items: responseItems } = getArray(resData);
  responseItems.forEach((item, index) => {
    const tempId = sourceItems[index][tempIdField];
    if (tempId) {
      set(item, tempIdField, tempId);
    }
  });
  return isArray ? responseItems : responseItems[0];
}
/**
 * Uses fast-copy on any data provided get an independent and reference-free copy.
 * This makes it easy to work with client-side databases like feathers-memory. It makes
 * it impossible to accidentally modify stored data due to js object in-memory references.
 * @param data item or array of items
 */
export function useCleanData(data) {
  const { items, isArray } = getArray(data);
  const cleaned = items.map((item) => fastCopy(item));
  return isArray ? cleaned : cleaned[0];
}
/**
 *
 * @param data item or array of items
 * @returns object with { items[], isArray } where isArray is a boolean of if the data was an array.
 */
export function getArray(data) {
  const isArray = Array.isArray(data);
  return { items: isArray ? data : [data], isArray };
}
export const hasOwn = (obj, prop) =>
  Object.prototype.hasOwnProperty.call(obj, prop);
