<template>
  <v-container>
    <CreateNewListDialog v-model="createListDialog" />
    <EditListNameDialog :list="editingList" v-model="editListDialog" />
    <v-dialog v-model="deleteListDialog" max-width="100%" width="400">
      <v-card>
        <v-card-title> Delete List? </v-card-title>
        <v-card-text class="text-h6">{{ listToBeDeleted.title }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteListDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteList(listToBeDeleted)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card :loading="isPending">
      <v-card-title>
        My Flows Lists
        <v-spacer />
        <v-btn v-if="!isEditing" @click="createNewList()" icon>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on">mdi-plus</v-icon>
            </template>
            Create New List
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="!!mySavedListofFlows.length"
          @click="!isEditing ? enableEditing() : disableEditing()"
          icon
        >
          <v-tooltip bottom v-if="!isEditing">
            <template #activator="{ on }">
              <v-icon v-on="on">mdi-pencil-outline</v-icon>
            </template>
            Edit Lists
          </v-tooltip>
          <v-tooltip bottom v-else-if="isEditing">
            <template #activator="{ on }">
              <v-icon v-on="on" color="red"> mdi-pencil-off-outline </v-icon>
            </template>
            Stop Editing
          </v-tooltip>
        </v-btn>
      </v-card-title>
      <v-expansion-panels v-model="openPanels" multiple accordion>
        <!-- <draggable style="width: 100%" :disabled="!isEditing"> -->
        <v-expansion-panel
          v-for="list in mySavedListofFlows"
          :key="list.id"
          :readonly="!list.flows.length"
        >
          <v-expansion-panel-header
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
            :hide-actions="!list.flows.length"
          >
            {{ list.title }} ({{ list.flows.length }})
            <template v-if="isEditing">
              <v-spacer />
              <v-btn @click.stop="editListName(list)" icon>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on">mdi-pencil</v-icon>
                  </template>
                  Edit List Name
                </v-tooltip>
              </v-btn>
              <v-btn @click.stop="startDeleteList(list)" icon>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on" color="red">mdi-delete</v-icon>
                  </template>
                  Delete List
                </v-tooltip>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="w-100 pa-0">
            <v-list dense subheader>
              <draggable
                style="width: 100%"
                :disabled="!isEditing"
                :list="list.flows"
                handle=".handle"
              >
                <v-list-item
                  v-for="savedFlow in list.flows"
                  :key="savedFlow.id"
                  class="list-item pl-6 pr-0"
                  two-line
                  dense
                >
                  <v-icon v-if="isEditing" class="handle">mdi-arrow-all</v-icon>
                  <span>
                    <v-list-item-title>
                      {{ savedFlow.destination_part_number }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ savedFlow.destination_buffer_description }}
                    </v-list-item-subtitle>
                  </span>
                  <v-spacer />
                  <!-- <v-list-item-icon> -->
                  <v-btn
                    v-if="!isEditing"
                    @click="goToFlowViewer(savedFlow.flow_id)"
                    plain
                    text
                  >
                    <span class="pr-2">{{
                      facilitiesStore.getFacilityCode(savedFlow.facility_id)
                    }}</span>
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <v-icon
                          :color="
                            getColorFromInitiationType(
                              savedFlow.initiation_type
                            )
                          "
                          class="icon-highlighted-white"
                          v-on="on"
                          >mdi-location-enter</v-icon
                        >
                      </template>
                      View Flow
                    </v-tooltip>
                  </v-btn>

                  <v-btn
                    v-if="isEditing"
                    icon
                    @click="deleteFlowFromList(savedFlow)"
                  >
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <v-icon v-on="on" color="red">mdi-delete</v-icon>
                      </template>
                      Delete Flow
                    </v-tooltip>
                  </v-btn>
                </v-list-item>
              </draggable>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- </draggable> -->
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { ref, computed, watch } from "vue-demi";
import { useRouter } from "../utils/useRouter";
import { useAuth } from "../store/auth.pinia";
import { computedRef, getColorFromInitiationType } from "../utils/composables";
import { useUserSavedFlows } from "../store/userSavedFlows.pinia";
import { useUserSavedLists } from "../store/userSavedLists.pinia";
import draggable from "vuedraggable";
import { useFind } from "feathers-pinia";
import { useFacilities } from "../store/facilities.pinia";
import CreateNewListDialog from "./CreateNewListDialog.vue";
import EditListNameDialog from "./EditListNameDialog.vue";

export default {
  name: "SidebarNavigationLists",
  methods: { getColorFromInitiationType },
  components: {
    draggable,
    CreateNewListDialog,
    EditListNameDialog,
  },
  setup() {
    const router = useRouter();
    const authStore = useAuth();
    const userSavedFlowsStore = useUserSavedFlows();
    const userSavedListsStore = useUserSavedLists();
    const facilitiesStore = useFacilities();
    const isEditing = ref(false);
    const initialFlows = ref([]);
    const createListDialog = ref(false);
    const openPanels = ref([]);
    const editListDialog = ref(false);
    const editingList = ref({});
    const deleteListDialog = ref(false);
    const listToBeDeleted = ref({});

    const mySavedListsParams = computedRef(() => {
      return {
        query: {
          user_id: authStore.userId,
          $sort: { createdAt: 1 },
        },
      };
    });

    const { items: mySavedLists, isPending: mySavedListsPending } = useFind({
      model: userSavedListsStore.Model,
      params: mySavedListsParams,
    });

    const mySavedListsIds = computed(() => mySavedLists.value.map((l) => l.id));

    const mySavedFlowsParams = computedRef(() => {
      return {
        query: {
          user_id: authStore.userId,
          list_id: { $in: mySavedListsIds.value },
        },
      };
    });

    const { items: mySavedFlows, isPending: mySavedFlowsPending } = useFind({
      model: userSavedFlowsStore.Model,
      params: mySavedFlowsParams,
      debounce: 100,
    });

    watch(
      () => mySavedFlows.value,
      (newVal) => {
        console.log("mySavedFlows changed", mySavedFlows.value);
        if (newVal.length === 0) {
          // Disable editing if there are no flows
          isEditing.value = false;
        }
      }
    );

    const mySavedListofFlows = computedRef(() => {
      return mySavedLists.value.map((list) => {
        return {
          ...list,
          flows: mySavedFlows.value
            .filter((savedFlow) => savedFlow.list_id === list.id)
            // We're destructuring to avoid reactivity issues with ordering
            .map((savedFlow) => {
              return {
                ...savedFlow,
              };
            })
            .sort((a, b) => {
              // sort by order
              return a?.order - b?.order;
            }),
        };
      });
    });

    const isPending = computed(() => {
      return mySavedListsPending.value || mySavedFlowsPending.value;
    });

    const goToFlowViewer = (id) => {
      router.push(`/flow-viewer/${id}`);
    };

    const deleteFlowFromList = (savedFlow) => {
      userSavedFlowsStore.deleteSavedFlowById(savedFlow.id);
    };

    const disableEditing = async () => {
      // check if any lists have been reordered
      const listOfFlowsToUpdate = mySavedListofFlows.value.map((savedFlow) => {
        return savedFlow.flows
          .map((flow) => {
            if (flow.order === savedFlow.flows.indexOf(flow)) {
              return null;
            } else {
              return {
                ...flow,
                order: savedFlow.flows.indexOf(flow),
              };
            }
          })
          .filter((flow) => {
            return flow !== null;
          });
      });

      // save any flows that have been reordered
      await Promise.all(
        listOfFlowsToUpdate.map((list) => {
          return Promise.all(
            list.map((savedFlow) => {
              const flowToPatch = new userSavedFlowsStore.Model({
                ...savedFlow,
              });
              return flowToPatch.patch();
            })
          );
        })
      );

      isEditing.value = false;
    };

    const enableEditing = () => {
      initialFlows.value = mySavedFlows.value.flows;
      isEditing.value = true;
    };

    const createNewList = () => (createListDialog.value = true);

    const editListName = (list) => {
      editListDialog.value = true;
      editingList.value = list;
    };

    const startDeleteList = (list) => {
      deleteListDialog.value = true;
      listToBeDeleted.value = list;
    };

    const deleteList = (list) => {
      // delete list
      userSavedListsStore.deleteSavedListById(list.id);

      const savedFlowIds = list?.flows.map((savedFlow) => savedFlow.id);
      // then delete all saved flows related to that list
      Promise.all(
        userSavedFlowsStore.deleteSavedFlowsByIds(savedFlowIds)
      ).finally(() => {
        deleteListDialog.value = false;
      });
    };

    return {
      goToFlowViewer,
      mySavedFlows,
      isEditing,
      deleteFlowFromList,
      disableEditing,
      enableEditing,
      mySavedLists,
      mySavedListofFlows,
      isPending,
      createNewList,
      createListDialog,
      openPanels,
      editingList,
      editListDialog,
      editListName,
      deleteList,
      deleteListDialog,
      startDeleteList,
      listToBeDeleted,
      facilitiesStore,
    };
  },
};
</script>
<style lang="scss" scoped>
h1 {
  padding: 10px;
}

.v-expansion-panel-header {
  padding: 10px;
  font-size: 16px;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.list-item:not(:last-child) {
  border-bottom: 1px solid rgba(127.5, 127.5, 127.5, 0.12);
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: 0 0 auto;
}

.handle {
  cursor: grab;
  float: left;
  margin-left: -8px;
  margin-right: 8px;
}

.v-card__title {
  word-break: break-word;
}
</style>
