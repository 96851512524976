<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="goToAuditLog"
        icon
        v-on="on"
        v-bind="attrs"
        @click="goToAuditLog"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>
    <span>Audit Log</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "HistoryLink",
  setup(props, { root }) {
    // List of named routes and their respective audit log
    const auditLogRoutes = {
      WorkcenterOverview: "WorkcenterAuditLog",
      ProcessOverview: "ProcessAuditLog",
    };

    const goToAuditLog = () => {
      // Only navigate if there was a match
      if (auditLogRoutes[root.$route.name] !== undefined) {
        root.$router.push({
          name: auditLogRoutes[root.$route.name],
          params: root.$route.params,
        });
      }
    };

    return {
      goToAuditLog,
    };
  },
};
</script>
