import React, { memo } from "react";
import { mdiMonitorDashboard, mdiLayers } from "@mdi/js";
import BaseNode from "./base/BaseNode";

React;

const WaypointNode = memo((props) => {
  // Define left icons
  const iconsLeft = {
    color: "#79512a",
    props: [
      {
        path: mdiMonitorDashboard,
        title: "Waypoint",
      },
    ],
  };
  const inventory = {
    // green if inventory is present, gray if not present
    color: props.data.detail.inventory?.length ? "green" : "lightgray",
    path: mdiLayers,
    title: "Inventory",
  };

  return (
    <BaseNode
      {...props}
      handles={["top", "bottom", "right"]}
      iconsLeft={iconsLeft}
      inventory={inventory}
    />
  );
});

const WaypointOutputLines = () => {
  return ["data.data.detail.text"];
};

export { WaypointNode, WaypointOutputLines };
