import Vue from "vue";
import { env } from "./utils/variables";
if (env?.toLowerCase() === "dev") Vue.config.devtools = true;
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { pinia } from "./store/store.pinia";
import http from "./plugins/http";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueCompositionApi from "@vue/composition-api";
import App from "./App.vue";
import MaintenanceMode from "./MaintenanceMode.vue";
import "flag-icons";
import { i18n } from "./i18n";
import { maintenanceMode } from "./utils/variables";

Vue.config.productionTip = false;

Vue.use(VueCompositionApi);
Vue.use(Toast, {
  timeout: 5000,
});

new Vue({
  pinia,
  http,
  router,
  vuetify,
  i18n,
  render: (h) => {
    return h(maintenanceMode ? MaintenanceMode : App);
  },
}).$mount("#app");
