var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Workcenter Search","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Only Active"},model:{value:(_vm.onlyActive),callback:function ($$v) {_vm.onlyActive=$$v},expression:"onlyActive"}})],1),_c('v-col',{staticClass:"mb-0 pb-0"},[_c('v-btn-toggle',{staticClass:"mb-2 float-right",attrs:{"color":"primary"},model:{value:(_vm.toggle),callback:function ($$v) {_vm.toggle=$$v},expression:"toggle"}},[_c('v-btn',{attrs:{"value":true,"small":""}},[_c('v-icon',[_vm._v(" mdi-arrow-expand-vertical ")])],1),_c('v-btn',{attrs:{"value":false,"small":""}},[_c('v-icon',[_vm._v(" mdi-arrow-collapse-vertical ")])],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-0 mt-0"},[_c('v-list',{attrs:{"dense":""}},[(
            _vm.appStore.currentFacility
              ? _vm.appStore.canEditCurrentFacility
              : _vm.appStore.canEditActiveFacility
          )?_c('v-list-item',{attrs:{"to":{
            name: "WorkcentersEdit",
            query: { facilityId: _vm.appStore.activeFacilityId },
          },"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Add Workcenter")])],1)],1):_vm._e()],1)],1)],1),_c('v-expand-transition',[(_vm.isPending)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""},model:{value:(_vm.openPanels),callback:function ($$v) {_vm.openPanels=$$v},expression:"openPanels"}},_vm._l((_vm.workcenters),function(workcenter){return _c('v-expansion-panel',{key:workcenter.id,attrs:{"color":"error","dark":true}},[_c('v-expansion-panel-header',{attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.processes[workcenter.id].length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"color":"success","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.processes[workcenter.id].filter(function (i) { return i.active; }).length)+" ")])]}}],null,true)},[_c('span',[_vm._v("Active Processes")])]):_vm._e(),_c('v-icon',[_vm._v("mdi-chevron-down")])]},proxy:true}],null,true)},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-badge',{attrs:{"dot":"","inline":"","left":"","color":workcenter.active ? 'success' : 'error'}},[_vm._v(" "+_vm._s(workcenter.title)+" ")])],1)],1)],1),_c('v-expansion-panel-content',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
              name: "WorkcenterOverview",
              params: { workcenterId: workcenter.id },
            },"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-tools")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Workcenter Overview ")]),_c('div',{staticClass:"text-center"})],1)],1),(_vm.appStore.canEditCurrentFacility && workcenter.active)?_c('v-list-item',{attrs:{"to":{
              name: "WorkcenterOverview",
              params: { workcenterId: workcenter.id },
              query: { addProcess: 1 },
            },"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Add Process ")]),_c('div',{staticClass:"text-center"})],1)],1):_vm._e(),_vm._l((_vm.processes[workcenter.id]),function(process){return _c('v-list-item',{key:process.id,attrs:{"to":{
              name: "ProcessOverview",
              params: { workcenterId: workcenter.id, processId: process.id },
            }}},[_c('v-badge',{attrs:{"inline":"","dot":"","left":"","color":process.active ? 'success' : 'error'}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(process.title)+" ")])],1)],1),_c('v-spacer'),(_vm.getProcessOperationCount(process.id) != false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"align-self-center mr-0",attrs:{"color":"success","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getProcessOperationCount(process.id))+" ")])]}}],null,true)},[_c('span',[_vm._v("Operation Count")])]):_vm._e()],1)})],2)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }