import { BaseModel } from "./service-store/index";
import { registerClient } from "./clients";
import { defineStore } from "./define-store";
export function setupFeathersPinia(globalOptions) {
  const { clients } = globalOptions;
  Object.keys(clients).forEach((name) => {
    registerClient(name, clients[name]);
  });
  function defineStoreWrapper(...args) {
    const id = args.length === 2 ? args[0] : args[0].id;
    const options = args.length === 2 ? args[1] : args[0];
    options.id = id || `service.${options.servicePath}`;
    return defineStore(Object.assign({}, globalOptions, options));
  }
  return {
    defineStore: defineStoreWrapper,
    BaseModel,
  };
}
