<template>
  <v-dialog v-model="dialog" max-width="100%" width="400">
    <v-card>
      <v-card-title class="text-h5"> Create New List </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field
          v-model="newListTitle"
          label="List Name"
          :rules="titleRules"
          @keyup.enter="create"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="close"> Cancel </v-btn>
        <v-btn color="green darken-1" text @click="create"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed } from "vue-demi";
import { useAuth } from "@/store/auth.pinia.js";
import { useUserSavedLists } from "../store/userSavedLists.pinia";
import rules from "../utils/rules";

export default {
  name: "CreateNewListDialog",
  props: {
    value: {},
  },
  setup(props, context) {
    const authStore = useAuth();
    const activeUserId = ref(authStore.userId);
    const userSavedListsStore = useUserSavedLists();
    const newListTitle = ref("");

    const dialog = computed({
      get() {
        return this.value;
      },
      set(val) {
        context.emit("input", val);
      },
    });

    const create = () => {
      if (newListTitle.value !== "") {
        userSavedListsStore
          .addSavedList(newListTitle.value, activeUserId.value)
          .catch((err) => {
            console.error("Error creating list", err);
          })
          .then((res) => {
            context.emit("list-created", res);
          })
          .finally(() => close());
      }
    };

    const close = () => {
      newListTitle.value = "";
      dialog.value = false;
    };

    return {
      create,
      dialog,
      newListTitle,
      close,
      titleRules: [rules.required],
    };
  },
};
</script>
