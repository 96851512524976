<template>
  <div class="d-flex flex-column align-end justify-space-around fill-height">
    <v-btn
      v-if="toFlowId"
      @click.prevent.stop="onFlowClick(toFlowId)"
      text
      plain
    >
      {{ facilityCode }}
      <v-icon
        :color="toFlowColor"
        class="icon-highlighted-white"
        size="24"
        right
      >
        mdi-location-enter
      </v-icon>
    </v-btn>
    <v-btn
      v-if="fromFlowId"
      @click.prevent.stop="onFlowClick(fromFlowId)"
      text
      plain
    >
      {{ fromFacilityCode }}
      <v-icon
        :color="fromFlowColor"
        class="icon-highlighted-white"
        size="24"
        right
      >
        mdi-location-enter
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { computed } from "vue-demi";
import { useFacilities } from "../../store/facilities.pinia";
import { getColorFromInitiationType } from "../../utils/composables";

export default {
  name: "BTTopFlowButtons",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const facilitiesStore = useFacilities();

    const fromFlowId = computed(() => {
      return props.item.from_flow_id ?? null;
    });

    const toFlowId = computed(() => {
      if (props.item.flow_id) return props.item.flow_id;
      return props.item.to_flow_id ?? props.item.flow_id ?? null;
    });

    const toFlowColor = computed(() => {
      if (!props.item.flow_initiation_detail?.text) return "grey";
      return getColorFromInitiationType(props.item.flow_initiation_detail.text);
    });

    const fromFlowColor = computed(() => {
      if (!props.item.from_flow_initiation_detail?.text) return "grey";
      return getColorFromInitiationType(
        props.item.from_flow_initiation_detail.text
      );
    });

    const facilityCode = computed(() => {
      const facilityId = props.item?.buffer?.facility_id ?? null;
      if (!facilityId) return "";
      return facilitiesStore.getFacilityCode(facilityId);
    });

    const fromFacilityCode = computed(() => {
      const facilityId = props.item?.from_buffer?.facility_id ?? null;
      if (!facilityId) return "";
      return facilitiesStore.getFacilityCode(facilityId);
    });

    const onFlowClick = (id) => {
      console.log("top-flow-click", props.item);
      emit("flow-click", id);
    };

    return {
      fromFlowId,
      toFlowId,
      facilityCode,
      fromFacilityCode,
      toFlowColor,
      fromFlowColor,
      onFlowClick,
    };
  },
};
</script>
