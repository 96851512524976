import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { appGroups } from "../utils/variables";

const servicePath = "members";
export const useMembers = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  actions: {
    async getGroupMembers() {
      const query = {
        groupIds: appGroups,
      };
      // Execute the search
      return this.find({ query });
    },
  },
});

api.service(servicePath).hooks({});
