import { set, del } from "vue-demi";
import { getId } from "../utils";
import { models } from "../models";
import { EventEmitter } from "events";
export class BaseModel {
  constructor(data, options = {}) {
    const { store, instanceDefaults, setupInstance } = this.constructor;
    Object.assign(this, instanceDefaults(data, { models, store }));
    Object.assign(this, setupInstance(data, { models, store }));
    set(this, "__isClone", !!options.clone);
    return this;
  }
  static instanceDefaults(data) {
    return data;
  }
  static setupInstance(data) {
    return data;
  }
  static find(params) {
    return this.store.find(params);
  }
  static findInStore(params) {
    return this.store.findInStore(params);
  }
  static get(id, params) {
    return this.store.get(id, params);
  }
  static getFromStore(id, params) {
    return this.store.getFromStore(id, params);
  }
  static count(params) {
    return this.store.count(params);
  }
  static countInStore(params) {
    return this.store.countInStore(params);
  }
  static addToStore(data) {
    return this.store.addToStore(data);
  }
  static update(id, data, params) {
    return this.store.update(id, data, params);
  }
  static patch(id, data, params) {
    return this.store.patch(id, data, params);
  }
  static remove(id, params) {
    return this.store.remove(id, params);
  }
  static removeFromStore(data) {
    return this.store.removeFromStore(data);
  }
  get __isTemp() {
    const { idField } = this.constructor;
    return getId(this, idField) != null;
  }
  get isSavePending() {
    const { store } = this.constructor;
    const pending = store.pendingById[getId(this)];
    return pending?.create || pending?.update || pending?.patch || false;
  }
  get isCreatePending() {
    const { store } = this.constructor;
    return store.pendingById[getId(this)]?.create || false;
  }
  get isPatchPending() {
    const { store } = this.constructor;
    return store.pendingById[getId(this)]?.patch || false;
  }
  get isUpdatePending() {
    const { store } = this.constructor;
    return store.pendingById[getId(this)]?.update || false;
  }
  get isRemovePending() {
    const { store } = this.constructor;
    return store.pendingById[getId(this)]?.remove || false;
  }
  get isPending() {
    const { store } = this.constructor;
    const pending = store.pendingById[getId(this)];
    return (
      pending?.create ||
      pending?.update ||
      pending?.patch ||
      pending?.remove ||
      false
    );
  }
  /**
   * Add the current record to the store
   */
  addToStore() {
    const { store } = this.constructor;
    return store.addToStore(this);
  }
  /**
   * clone the current record using the `createCopy` mutation
   */
  clone(data = {}) {
    const { store } = this.constructor;
    return store.clone(this, data);
  }
  /**
   * Update a store instance to match a clone.
   */
  commit() {
    const { store } = this.constructor;
    if (this.__isClone) {
      return store.commit(this);
    } else {
      throw new Error("You cannot call commit on a non-copy");
    }
  }
  /**
   * Update a clone to match a store instance.
   */
  reset(data = {}) {
    const { store } = this.constructor;
    return store.clone(this, data);
  }
  /**
   * A shortcut to either call create or patch/update
   * @param params
   */
  save(params) {
    const { idField } = this.constructor;
    const id = getId(this, idField);
    if (id != null) {
      return this.patch(params);
    } else {
      return this.create(params);
    }
  }
  /**
   * Calls service create with the current instance data
   * @param params
   */
  create(params) {
    const { idField, store } = this.constructor;
    const data = Object.assign({}, this);
    if (data[idField] === null) {
      del(data, idField);
    }
    return store.create(data, params);
  }
  /**
   * Calls service patch with the current instance data
   * @param params
   */
  patch(params) {
    const { idField, store } = this.constructor;
    const id = getId(this, idField);
    if (id == null) {
      const error = new Error(
        `Missing ${idField} property. You must create the data before you can patch with this data`
      );
      return Promise.reject(error);
    }
    return store.patch(id, this, params);
  }
  /**
   * Calls service update with the current instance data
   * @param params
   */
  update(params) {
    const { idField, store } = this.constructor;
    const id = getId(this, idField);
    if (id == null) {
      const error = new Error(
        `Missing ${idField} property. You must create the data before you can patch with this data`
      );
      return Promise.reject(error);
    }
    return store.update(id, this, params);
  }
  /**
   * Calls service remove with the current instance id
   * @param params
   */
  remove(params) {
    checkThis(this);
    const { idField, store } = this.constructor;
    const id = getId(this, idField);
    return store.remove(id, params);
  }
  /**
   * Removes the instance from the store
   */
  removeFromStore() {
    const { store } = this.constructor;
    return store.removeFromStore(this);
  }
}
BaseModel.store = null;
BaseModel.pinia = null;
BaseModel.servicePath = null;
BaseModel.idField = "";
BaseModel.tempIdField = "";
function checkThis(context) {
  if (!context) {
    throw new Error(
      `Instance methods must be called with the dot operator. If you are referencing one in an event, use '@click="() => instance.remove()"' so that the correct 'this' context is applied. Using '@click="instance.remove"' will call the remove function with "this" set to 'undefined' because the function is called directly instead of as a method.`
    );
  }
}
for (const n in EventEmitter.prototype) {
  set(BaseModel, n, EventEmitter.prototype[n]);
}
