import { getCurrentInstance, toRef, reactive, ref, watch } from "vue-demi";

const createMessage = (msg) => {
  return `useRouter() ${msg}`;
};

const warnNoInstance = () =>
  console.warn(createMessage("No current instance found"));

export function useRouter() {
  const inst = getCurrentInstance();
  if (inst) {
    return inst.proxy.$root.$router;
  }
  warnNoInstance();
  return undefined;
}

export const useRoute = () => {
  const router = useRouter();
  if (!router) {
    warnNoInstance();
    return undefined;
  }
  return toRef(reactive(router), "currentRoute");
};

export function onBeforeRouteLeave(leaveGuard) {
  const inst = getCurrentInstance();
  if (!inst) {
    warnNoInstance();
    return;
  }
  const { options } = inst.proxy.constructor;
  const hooks = options.beforeRouteLeave || [];
  hooks.push(leaveGuard);
  options.beforeRouteLeave = hooks;
}

export function onBeforeRouteUpdate(updateGuard) {
  const inst = getCurrentInstance();
  if (!inst) {
    warnNoInstance();
    return;
  }
  const { options } = inst.proxy.constructor;
  const hooks = options.beforeRouteUpdate || [];
  hooks.push(updateGuard);
  options.beforeRouteUpdate = hooks;
}

export function useRouteQuery(param = null, defaultValue = null) {
  const inst = getCurrentInstance();
  if (inst) {
    const initialVal = param
      ? inst.proxy.$route?.query?.[param] ?? defaultValue
      : inst.proxy.$route?.query ?? defaultValue;
    const val = ref(initialVal);
    const watcher = (newValue) => (val.value = newValue ?? defaultValue);
    if (param) {
      watch(() => inst.proxy.$route.query[param], watcher);
    } else {
      watch(() => inst.proxy.$route.query, watcher);
    }
    return val;
  }
  warnNoInstance();
  return undefined;
}
