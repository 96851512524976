import React, { memo } from "react";
import { mdiFormatListBulleted, mdiTrayArrowUp } from "@mdi/js";
import BaseNode from "./base/BaseNode";

React;

const PartGroupNode = memo((props) => {
  let linkedFlowIds = props?.data?.detail?.related_part_groups;

  const outString =
    linkedFlowIds.length > 0
      ? "Out"
      : "No flow exists pulling out of this part group";
  // Define left icons
  const iconsLeft = {
    color: "#75b0ad",
    props: [
      {
        path: mdiFormatListBulleted,
        title: "Part Group",
      },
      {
        path: mdiTrayArrowUp,
        title: outString,
        type: "out",
      },
    ],
  };

  return (
    <BaseNode
      {...props}
      handles={["top", "bottom", "left"]}
      iconsLeft={iconsLeft}
      linkedFlowIds={linkedFlowIds}
      slot={
        <div>
          <div style={{ marginRight: "24px" }}>
            Part: {props?.data?.detail?.part?.part_number}
          </div>
          <div
            style={{
              textAlign: "right",
              marginRight: "8px",
              marginTop: "-18px",
            }}
          >
            QTY:{" "}
            {props?.data?.detail?.bom?.quantity
              ? props?.data?.detail?.bom?.quantity
              : 0}{" "}
            {props?.data?.detail?.part?.uom?.description}
          </div>
          <div>Desc: {props?.data?.detail?.part?.description}</div>
        </div>
      }
    />
  );
});

export { PartGroupNode };
