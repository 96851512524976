<template>
  <v-navigation-drawer
    ref="leftnav"
    v-model="appStore.drawer"
    app
    floating
    width="400"
  >
    <template #prepend>
      <v-row>
        <v-col>
          <v-card tile color="transparent" class="logo-area pb-2 pt-1">
            <div class="d-flex align-center justify-space-around">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="150"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon x-large>
                    <v-icon size="50">mdi-apps</v-icon>
                  </v-btn>
                </template>
                <AppMenu @close="menu = false" />
              </v-menu>
              <div
                class="d-flex align-center logo"
                @click="() => (menu = true)"
              >
                <v-img
                  src="../assets/logo.png"
                  alt="MEM Logo"
                  max-width="52"
                  contain
                />
                <h1 class="font-heebo logo-text px-0 pb-0 ma-0">MEM</h1>
              </div>
              <v-btn
                class="align-self-baseline"
                @click="appStore.drawer = false"
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-list class="pb-0 mb-0" dense>
      <v-list-item v-if="!disableSearch" :to="{ name: `FlowSearch` }" link>
        <v-list-item-icon>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Flow Search</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item
        v-if="appStore.canEditCurrentFacility"
        :to="{
          name: `WorkcentersEdit`,
          query: { facilityId: appStore.activeFacilityId },
        }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Add Workcenter</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
    <div>
      <v-tabs
        grow
        v-model="activeTab"
        density="compact"
        hide-slider
        show-arrows
      >
        <v-tab>BOM</v-tab>
        <v-tab>Workcenters</v-tab>
        <v-tab>My Lists</v-tab>
        <v-tab-item>
          <SidebarBomTree />
        </v-tab-item>
        <v-tab-item>
          <SidebarWorkcenters />
        </v-tab-item>
        <v-tab-item>
          <SidebarNavigationLists />
          <SidebarNavigationSearches />
        </v-tab-item>
      </v-tabs>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { ref } from "vue-demi";
import { disableSearch } from "../utils/variables";
import { useStore } from "@/store/app.pinia.js";
import AppMenu from "./AppMenu.vue";
import SidebarBomTree from "./SidebarBomTree.vue";
import SidebarWorkcenters from "./SidebarWorkcenters.vue";
import SidebarNavigationLists from "./SidebarNavigationLists.vue";
import SidebarNavigationSearches from "./SidebarNavigationSearches.vue";

export default {
  name: "AppNavigationDrawer",
  components: {
    AppMenu,
    SidebarBomTree,
    SidebarWorkcenters,
    SidebarNavigationLists,
    SidebarNavigationSearches,
  },
  setup() {
    const appStore = useStore();
    const menu = ref(false);
    const activeTab = ref("workcenters");

    return {
      appStore,
      menu,
      disableSearch,
      activeTab,
    };
  },
};
</script>

<style lang="scss">
.logo-area {
  .logo {
    cursor: pointer;

    .logo-text {
      padding-top: 3px;
    }
  }
}

.font-heebo {
  font-family: "Heebo", sans-serif;
}

.logo-text {
  font-size: 4rem;
  line-height: 3rem;
  user-select: none;
}
</style>
