<template>
  <BadgeContainer v-bind="attrs">
    <slot></slot>
  </BadgeContainer>
</template>

<script>
import { computed } from "vue-demi";
import BadgeContainer from "./BadgeContainer.vue";

export default {
  name: "BadgeCanEdit",
  components: {
    BadgeContainer,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    noBadge: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const attrs = computed(() => {
      return {
        color: props.value ? "primary" : "red",
        icon: props.value ? "mdi-pencil" : "mdi-lock",
        left: false,
        tooltip: `Edit Access ${props.value ? "Enabled" : "Disabled"}`,
        noBadge: props.noBadge,
      };
    });

    return {
      attrs,
    };
  },
};
</script>
