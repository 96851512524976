import { set, del } from "vue-demi";
import { getId, hasOwn } from "../utils";
import { debounce as _debounce } from "lodash-es";
import { models } from "../models";
export function enableServiceEvents({ service, Model, store, options }) {
  const debouncedQueue = {
    addOrUpdateById: {},
    removeItemById: {},
    enqueueAddOrUpdate(item) {
      const id = getId(item, options.idField);
      set(this.addOrUpdateById, id, item);
      if (hasOwn(this.removeItemById, id)) {
        del(this.removeItemById, id);
      }
      this.flushAddOrUpdateQueue();
    },
    enqueueRemoval(item) {
      const id = getId(item, options.idField);
      set(this.removeItemById, id, item);
      if (hasOwn(this.addOrUpdateById, id)) {
        del(this.addOrUpdateById, id);
      }
      this.flushRemoveItemQueue();
    },
    flushAddOrUpdateQueue: _debounce(
      async function () {
        const values = Object.values(this.addOrUpdateById);
        if (values.length === 0) return;
        await store.addOrUpdate(values);
        set(this, "addOrUpdateById", {});
      },
      options.debounceEventsTime || 20,
      { maxWait: options.debounceEventsMaxWait }
    ),
    flushRemoveItemQueue: _debounce(
      function () {
        const values = Object.values(this.removeItemById);
        if (values.length === 0) return;
        store.removeFromStore(values);
        set(this, "removeItemById", {});
      },
      options.debounceEventsTime || 20,
      { maxWait: options.debounceEventsMaxWait }
    ),
  };
  const handleEvent = (eventName, item, mutationName) => {
    const handler = options.handleEvents[eventName];
    const confirmOrArray = handler(item, { model: Model, models });
    const [affectsStore, modified = item] = Array.isArray(confirmOrArray)
      ? confirmOrArray
      : [confirmOrArray];
    if (affectsStore) {
      if (!options.debounceEventsTime) {
        eventName === "removed"
          ? store.removeFromStore(modified)
          : store[mutationName](modified);
      } else {
        eventName === "removed"
          ? debouncedQueue.enqueueRemoval(item)
          : debouncedQueue.enqueueAddOrUpdate(item);
      }
    }
  };
  // Listen to socket events when available.
  service.on("created", (item) => {
    handleEvent("created", item, "addOrUpdate");
    Model.emit && Model.emit("created", item);
  });
  service.on("updated", (item) => {
    handleEvent("updated", item, "addOrUpdate");
    Model.emit && Model.emit("updated", item);
  });
  service.on("patched", (item) => {
    handleEvent("patched", item, "addOrUpdate");
    Model.emit && Model.emit("patched", item);
  });
  service.on("removed", (item) => {
    handleEvent("removed", item, "removeFromStore");
    Model.emit && Model.emit("removed", item);
  });
  return debouncedQueue;
}
