// store/store.pinia.ts
import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { setupFeathersPinia } from "../utils/feathers-pinia/lib";
import { abilitiesPlugin } from "@casl/vue";
import { caslPlugin, ability } from "./casl.pinia";
import { api } from "../utils/feathers-api";
export { PiniaVuePlugin };

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();

// Setup CASL
pinia.use(caslPlugin);
Vue.use(abilitiesPlugin, ability);

const store = setupFeathersPinia({
  clients: { api },
  idField: "id",
});

export const { defineStore, BaseModel } = store;
