import { reactive, computed, toRefs, unref, watch, set } from "vue-demi";
export function useGet({
  model,
  id,
  params = computed(() => ({})),
  queryWhen = computed(() => true),
  local = false,
  immediate = true,
}) {
  if (!model) {
    throw new Error(
      `No model provided for useGet(). Did you define and register it with FeathersPinia?`
    );
  }
  function getId() {
    return unref(id);
  }
  function getParams() {
    return unref(params);
  }
  const state = reactive({
    isPending: false,
    hasBeenRequested: false,
    hasLoaded: false,
    error: null,
    isLocal: local,
    request: null,
  });
  const computes = {
    item: computed(() => model.getFromStore(getId(), getParams()) || null),
    servicePath: computed(() => model.servicePath),
    isSsr: computed(() => model.store.isSsr),
  };
  function get(id, params) {
    const idToUse = unref(id);
    const paramsToUse = unref(params);
    if (idToUse != null && queryWhen.value && !state.isLocal) {
      set(state, "isPending", true);
      set(state, "hasBeenRequested", true);
      const request =
        paramsToUse != null
          ? model.get(idToUse, paramsToUse)
          : model.get(idToUse);
      set(state, "request", request);
      return request
        .then((response) => {
          set(state, "isPending", false);
          set(state, "hasLoaded", true);
          return response;
        })
        .catch((error) => {
          set(state, "isPending", false);
          set(state, "error", true);
          return error;
        });
    } else {
      return Promise.resolve(undefined);
    }
  }
  watch(
    () => [getId(), getParams(), queryWhen.value],
    ([id, params]) => {
      get(id, params);
    },
    { immediate }
  );
  return {
    ...toRefs(state),
    ...computes,
    get,
  };
}
