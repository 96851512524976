import { defineStore } from "pinia";
import {
  Ability,
  createAliasResolver,
  detectSubjectType as defaultDetector,
} from "@casl/ability";
import { BaseModel } from "./store.pinia.js";

const detectSubjectType = (subject) => {
  if (typeof subject === "string") return subject;
  if (!(subject instanceof BaseModel)) return defaultDetector(subject);
  return subject.constructor.servicePath;
};

const resolveAction = createAliasResolver({
  manageChildren: [], // Created for flagging only
  createOrUpdate: ["create", "update"],
  update: "patch",
  read: ["get", "find"],
  delete: "remove",
});

const ability = new Ability([], { detectSubjectType, resolveAction });

const useCasl = defineStore("casl", {
  state: () => ({
    ability: ability,
    rules: [],
  }),
  actions: {
    setRules(rules) {
      this.rules = rules;
      this.ability.update(rules);
    },
  },
});

const caslPlugin = (context) => {
  const caslStore = useCasl();

  context.store.$onAction(
    ({
      name, // name of the action
      // store, // store instance, same as `someStore`
      // args, // array of parameters passed to the action
      after, // hook after the action returns or resolves
      //   onError, // hook if the action throws or rejects
    }) => {
      after((result) => {
        if (name === "authenticate") {
          if (
            !result?.rules ||
            (result?.authentication &&
              !result.authentication?.accessToken?.length) ||
            (result?.user && !result?.user?.active)
          ) {
            caslStore.setRules([]);
            return;
          }
          // Set the rules!
          caslStore.setRules(result.rules);
        }
        // TODO: Remove rules on logout
      });
    }
  );
};

export { useCasl, ability, caslPlugin };
