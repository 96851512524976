import React, { memo } from "react";
import { Handle } from "reactflow";
import { Grid, Box, Paper, styled } from "@mui/material";
import IconButton from "@mdi/react";

React;

function adjustColor(color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).slice(-2)
      )
  );
}

const TextCenter = styled(Paper)(() => ({
  textAlign: "left",
  height: "100%",
  paddingLeft: "6px",
}));

const iconsLeftDefaultColor = "#dfd5e7";

const BaseNode = memo(
  ({
    data,
    iconsLeft,
    isConnectable,
    handles,
    linkedFlowIds = [],
    slot = [],
    inventory = null,
  }) => {
    // console.log("data", data);
    const IconsLeftBox = styled(Paper)(({ theme }) => ({
      backgroundColor: `${adjustColor(
        iconsLeft?.color ?? iconsLeftDefaultColor,
        150
      )}`,
      // ...theme.typography.body2,
      padding: theme.spacing(0),
      textAlign: "center",
      height: "100%",
      borderRight: "solid",
      borderRightWidth: "1px",
      // paddingRight: "20px",
      // color: theme.palette.text.secondary,
    }));

    // If handles is true, enable all handles
    if (handles === true) handles = ["top", "left", "right", "buttom"];

    const isBuffer = data?.label?.toLowerCase().includes("buffer");

    // a function that takes a string input and returns a string that adds a new line after every 44 characters
    const addNewLine = (str) => {
      // 44 is also used in getNodeHeight() (line 100) in elk-graph.js, if you wanna update one update both
      const targetLength = 44;

      // find the last space in the str string within the target length
      const lastSpace = str?.lastIndexOf(" ", targetLength);

      if (str?.length > targetLength) {
        let result =
          str.substring(0, lastSpace).trim() +
          "\n" +
          addNewLine(str.substring(lastSpace)?.trim());
        return result;
      } else return str;
    };

    return (
      <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} minWidth={300} minHeight={50}>
            <Grid item xs={1} sm={1}>
              <IconsLeftBox>
                {/* For each item in iconsLeftProps array, display an icon */}
                {iconsLeft &&
                  iconsLeft.props.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <IconButton
                          className="icon-button"
                          onClick={() => {
                            data.onButtonPress(data, item.type, linkedFlowIds);
                          }}
                          {...{
                            color: iconsLeft.color ?? iconsLeftDefaultColor,
                            size: iconsLeft.size ?? 0.7,
                            ...item,
                          }}
                        />
                        <br />
                      </React.Fragment>
                    );
                  })}
              </IconsLeftBox>
            </Grid>
            <Grid item xs={11} sm={11}>
              {/* inventory button, will need this later */}
              {inventory && (
                <span className="inventory-icon-wrapper">
                  <IconButton
                    onClick={() => {
                      data.onButtonPress(data);
                    }}
                    {...{ ...inventory, size: 0.7 }}
                  />
                </span>
              )}
              <TextCenter>
                <b className="facility-type">
                  {data.facility?.code ?? data.facility_id} {data.type}
                </b>
                {!isBuffer && (
                  <div style={{ whiteSpace: "pre" }}>
                    {addNewLine(data?.label) ?? ""}
                  </div>
                )}
                {slot}
                {/* <br />
                {data.label} */}
              </TextCenter>
            </Grid>
          </Grid>
        </Box>
        {/* Only render handles that are in the handles array */}
        {handles.includes("top") && (
          <Handle
            type="target"
            position="top"
            id="topHandle"
            isConnectable={isConnectable}
          />
        )}
        {handles.includes("left") && (
          <Handle
            type="target"
            position="left"
            id="leftHandle"
            isConnectable={isConnectable}
          />
        )}
        {handles.includes("bottom") && (
          <Handle
            type="source"
            position="bottom"
            id="bottomHandle"
            isConnectable={isConnectable}
          />
        )}
        {handles.includes("right") && (
          <Handle
            type="source"
            position="right"
            id="rightHandle"
            isConnectable={isConnectable}
          />
        )}
      </>
    );
  }
);

export default BaseNode;
