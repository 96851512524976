<template>
  <v-list-item @click="onClick" class="pr-1">
    <v-list-item-content>
      <v-list-item-title>
        <v-tooltip
          right
          v-if="showStatusColors && partInfo.status === 'Obsolete'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small icon v-bind="attrs" v-on="on">
              <v-badge color="error" inline dot></v-badge>
            </v-btn>
          </template>
          <span>Part {{ partInfo.status }}</span>
        </v-tooltip>
        <strong>
          {{ partInfo.part_number }}
        </strong>
        {{ partInfo.quantity }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ partInfo.description }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="ma-0">
      <v-btn
        v-if="toFlowId"
        @click.prevent.stop="onFlowClick(toFlowId)"
        plain
        text
      >
        {{ facilityCode }}
        <v-icon
          :color="toFlowColor"
          class="icon-highlighted-white"
          size="24"
          right
        >
          mdi-location-enter
        </v-icon>
      </v-btn>
      <v-btn
        v-if="fromFlowId"
        @click.prevent.stop="onFlowClick(fromFlowId)"
        plain
        text
      >
        {{ fromFacilityCode }}
        <v-icon
          :color="fromFlowColor"
          class="icon-highlighted-white"
          size="24"
          right
        >
          mdi-location-enter
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { computed } from "vue-demi";
import { get, omit } from "lodash-es";
import { useFacilities } from "@/store/facilities.pinia";
import { getColorFromInitiationType } from "@/utils/composables";

export default {
  name: "BTListItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: "grey",
    },
    itemOverridesPath: {
      type: String,
      default: null,
    },
    showStatusColors: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const facilitiesStore = useFacilities();
    const isTop = computed(() => get(props.item, "is_top", false));

    const item = computed(() => {
      // If overrides isn't set, user the item directly
      if (!props.itemOverridesPath) return props.item;
      // Otherwise, merge the overrides with the item
      const overrides = get(props.item, props.itemOverridesPath, {});
      return {
        // Omit the overrides path from the item
        ...omit(props.item, [props.itemOverridesPath]),
        // Merge the overrides
        ...overrides,
      };
    });

    const partId = computed(() => {
      // if (isTop.value) return props.item?.parent_part_id ?? null;
      if (item.value.part_id) return item.value.part_id;
      return item.value.parent_part_id ?? null;
    });

    const partStatus = computed(() => {
      if (partId.value === item.value.parent_part?.id)
        return item.value.parent_part?.status;
      if (partId.value === item.value.child_part?.id)
        return item.value.child_part?.status;

      return item.value.status ?? null;
    });

    const partInfo = computed(() => {
      return {
        part_id: partId.value,
        // Direct child properties should contain the correct part information to use (parent vs child)
        part_number: item.value.part_number,
        description: item.value.description,
        // Do not include quantity for top level items
        quantity: isTop.value ? "" : `(${item.value?.quantity ?? "0"})`,
        status: partStatus.value,
      };
    });

    const fromFlowId = computed(() => {
      return item.value.from_flow_id ?? null;
    });

    const toFlowId = computed(() => {
      if (item.value.flow_id) return item.value.flow_id;
      return item.value.to_flow_id ?? item.value.flow_id ?? null;
    });

    const toFlowColor = computed(() => {
      if (!item.value.flow_initiation_detail?.text) return "grey";
      return getColorFromInitiationType(item.value.flow_initiation_detail.text);
    });

    const fromFlowColor = computed(() => {
      if (!item.value.from_flow_initiation_detail?.text) return "grey";
      return getColorFromInitiationType(
        item.value.from_flow_initiation_detail.text
      );
    });

    const facilityCode = computed(() => {
      const facilityId = item.value?.buffer?.facility_id ?? null;
      if (!facilityId) return "";
      return facilitiesStore.getFacilityCode(facilityId);
    });

    const fromFacilityCode = computed(() => {
      const facilityId = item.value?.from_buffer?.facility_id ?? null;
      if (!facilityId) return "";
      return facilitiesStore.getFacilityCode(facilityId);
    });

    const onClick = () => emit("click", partId.value);
    const onFlowClick = (id) => {
      console.log("flow-click", item.value);
      emit("flow-click", id);
    };

    return {
      partInfo,
      fromFlowId,
      toFlowId,
      selectedPartId: partId,
      facilityCode,
      fromFacilityCode,
      toFlowColor,
      fromFlowColor,
      onClick,
      onFlowClick,
    };
  },
};
</script>
