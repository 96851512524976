import { BufferNode } from "./BufferNode";
import { InitiationNode } from "./InitiationNode";
import { MaterialSpecificationNode } from "./MaterialSpecificationNode";
import { OperationNode, OperationOutputLines } from "./OperationNode";
import { PartGroupNode } from "./PartGroupNode";
import { TransferNode } from "./TransferNode";
import { WaypointNode, WaypointOutputLines } from "./WaypointNode";

const nodeTypes = {
  Buffer: BufferNode,
  Initiation: InitiationNode,
  MaterialSpecification: MaterialSpecificationNode,
  Operation: OperationNode,
  PartGroup: PartGroupNode,
  Transfer: TransferNode,
  Waypoint: WaypointNode,
};

const outputLines = {
  // Buffer: BufferOutputLines,
  Operation: OperationOutputLines,
  Waypoint: WaypointOutputLines,
};

export {
  OperationNode,
  InitiationNode,
  BufferNode,
  MaterialSpecificationNode,
  PartGroupNode,
  TransferNode,
  WaypointNode,
  nodeTypes,
  outputLines,
};
