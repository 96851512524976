export function makeState(options) {
  return () => ({
    clientAlias: options?.clientAlias || "api",
    servicePath: options?.servicePath || "",
    idField: options?.idField || "id",
    tempIdField: options?.tempIdField || "__tempId",
    itemsById: {},
    tempsById: {},
    clonesById: {},
    pendingById: {
      Model: {
        find: false,
        count: false,
        get: false,
      },
    },
    eventLocksById: {
      created: {},
      patched: {},
      updated: {},
      removed: {},
    },
    pagination: {},
    whitelist: options?.whitelist || [],
    ...(typeof options.state === "function" ? options.state() : options?.state),
  });
}
