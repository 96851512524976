import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VExpansionPanels } from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify, {
  components: {
    // Components that should always be registered
    VExpansionPanels, // Used in process detail draggable
  },
});

export default new Vuetify({
  theme: {
    dark: ["true", true].includes(localStorage.getItem("darkMode")),
    themes: {
      light: {
        accent: colors.grey.lighten1,
      },
      dark: {
        accent: colors.grey.darken2,
      },
    },
  },
});
