import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "process-count";
const useProcessCount = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    processOperationCountById: (state) => (id) => {
      return state.itemsById[`process-operation-${id}`]?.Count ?? null;
    },
    operationDetailCountById: (state) => (id) => {
      return state.itemsById[`operation-detail-${id}`]?.Count ?? null;
    },
  },
});

api.service(servicePath).hooks({});

export { useProcessCount };
