<template>
  <v-navigation-drawer
    right
    absolute
    temporary
    touchless
    v-show="help.drawer"
    v-model="help.drawer"
    :width="width"
  >
    <HelpContent />
  </v-navigation-drawer>
</template>

<script>
import { useHelp } from "@/store/help.pinia";
import HelpContent from "./HelpContent.vue";
import { computed } from "vue-demi";

export default {
  name: "SidebarRight",
  components: {
    HelpContent,
  },
  setup(props, { root }) {
    const help = useHelp();

    const isMobile = computed(() => root.$vuetify.breakpoint.smAndDown);

    const width = computed(() => {
      if (help.editMode) return "100%";
      return isMobile.value ? "90%" : "40%";
    });

    return {
      help,
      width,
    };
  },
};
</script>
