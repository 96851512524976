import { defineStore, BaseModel } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { useWorkcenters } from "./workcenters.pinia";

export class ProcessTemplate extends BaseModel {
  static instanceDefaults(data = null) {
    return {
      id: data?.id ?? null,
      workcenter_id: data?.workcenter_id ?? null,
      title: data?.title ?? "",
      active: data?.active ?? true,
      hide_on_flow_ticket: data?.hide_on_flow_ticket ?? 0,
      hide_detail: data?.hide_detail ?? false,
      pre_operation: data?.pre_operation ?? false,
      created: data?.created ?? null,
      modified: data?.modified ?? null,
      created_by_id: data?.created_by_id ?? null,
      modified_by_id: data?.modified_by_id ?? null,
    };
  }
}

const servicePath = "process-templates";
export const useProcessTemplates = defineStore({
  idField: "id",
  Model: ProcessTemplate,
  clients: { api },
  servicePath,
  getters: {
    byWorkcenterId: (state) => (workcenterId) => {
      return state.items.filter((item) => item.workcenter_id === workcenterId);
    },
  },
  actions: {
    addProcessTemplate(workcenterId) {
      const workcentersStore = useWorkcenters();
      const workcenter = workcentersStore.itemsById[workcenterId];
      const processTemplate = new this.Model({
        workcenter_id: workcenterId,
        active: workcenter?.active ?? true, // Default to workcenter active value
      });
      return this.addToStore(processTemplate);
    },
  },
});

api.service(servicePath).hooks({});
