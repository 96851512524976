import { useFacilities } from "../../store/facilities.pinia";
import { useWorkcenters } from "../../store/workcenters.pinia";
import { useProcessTemplates } from "../../store/processTemplates.pinia";
import { useMembers } from "../../store/members.pinia";

export const facilityName = ({ params }) => {
  const facilitiesStore = useFacilities();
  return facilitiesStore.getFacilityName(params.facilityId);
};

export const workcenterName = (route) => {
  const workcentersStore = useWorkcenters();
  return workcentersStore.getWorkcenterName(route.params.workcenterId);
};

export const username = ({ params }) => {
  const membersStore = useMembers();
  return membersStore.itemsById[params.userId]?.mail ?? "?";
};

export const processTitle = ({ params }) => {
  const processTemplatesStore = useProcessTemplates();
  return processTemplatesStore.itemsById[params.processId]?.title ?? "?";
};
