import { defineStore, BaseModel } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { createPaginatedAction } from "../utils/feathers-paginated";

export class UserSetting extends BaseModel {
  static instanceDefaults(data = null) {
    if (data?.property) {
      // Only allow a certain set of strings
      data.property = data.property.toLowerCase();
      if (!["default_facility_id"].includes(data.property)) {
        throw new Error(`Invalid property: ${data.property}`);
      }
    }
    return {
      id: data?.id ?? null,
      user_id: data?.user_id ?? null,
      property: data?.property ?? null,
      value: data?.value ?? null,
      created: data?.created ?? null,
      modified: data?.modified ?? null,
      created_by_id: data?.created_by_id ?? null,
      modified_by_id: data?.modified_by_id ?? null,
    };
  }
}

const servicePath = "user-settings";
const useUserSettings = defineStore({
  idField: "id",
  clients: { api },
  Model: UserSetting,
  servicePath,
  actions: {
    getUsersSettings: createPaginatedAction(),
    addUserSetting(userId, facilityId, propertyName) {
      const userSetting = new this.Model({
        property: propertyName,
        value: facilityId,
        user_id: userId,
      });
      // Save it to the database
      return userSetting.save();
    },
  },
});

api.service(servicePath).hooks({});

export { useUserSettings };
