<template>
  <v-card min-height="200px">
    <v-card-title>
      {{ pageTitle }}
      <v-spacer />
      <HistoryLink v-if="auditLog === true" />
      <template v-if="helpId !== false">
        <HelpLink :value="helpId" />
      </template>
    </v-card-title>

    <v-divider></v-divider>

    <v-container>
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
import HelpLink from "../components/HelpLink.vue";
import HistoryLink from "../components/HistoryLink.vue";

export default {
  name: "BaseInternalParentPage",
  components: {
    HelpLink,
    HistoryLink,
  },
  computed: {
    pageTitle() {
      if (
        this.$route.meta.title &&
        typeof this.$route.meta.title === "function"
      ) {
        return this.$route.meta.title(this.$route);
      }
      return this.$route.meta.title || this.$route.name;
    },
    // If a route-level helpId is defined, return it.
    helpId() {
      return this.$route.meta.helpId || false;
    },

    auditLog() {
      const auditLogRoutes = ["WorkcenterOverview", "ProcessOverview"];
      return !!auditLogRoutes.includes(this.$route.name);
    },
  },
};
</script>
