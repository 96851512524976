import { ref, watch, unref } from "vue-demi";
import { useGet, useFind } from "feathers-pinia";
import { usePart } from "../../store/part.pinia";
import { computed } from "vue-demi";

/**
 * feathers-pinia useGet helper to reduce reactivity loops and reduce boilerplate
 * @param {*} id
 * @param {*} store
 * @returns
 */
export const useGetHelper = (id, store) => {
  const idValue = ref(null);
  const queryWhen = ref(false);

  // Watch for reactive changes to id and update idValue
  watch(
    () => unref(id),
    (newId) => {
      // Update idValue if it has changed
      if (newId !== idValue.value) idValue.value = newId;
      // Update queryWhen based on whether idValue has a value
      const shouldQuery = !!idValue.value;
      if (shouldQuery !== queryWhen.value) queryWhen.value = shouldQuery;
    },
    { immediate: true, deep: true }
  );

  // Build useGet and return
  return useGet({
    model: store.Model,
    id: idValue,
    queryWhen: queryWhen,
  });
};

export const useGetPart = (id) => {
  return useGetHelper(id, usePart());
};

// finding from store by partid
export const useFindByPartId = (id, store) => {
  const idValue = ref(null);
  const queryWhen = ref(false);

  // Watch for reactive changes to id and update idValue
  watch(
    () => unref(id),
    (newId) => {
      // Update idValue if it has changed
      if (newId !== idValue.value) idValue.value = newId;
      // Update queryWhen based on whether idValue has a value
      const shouldQuery = !!idValue.value;
      if (shouldQuery !== queryWhen.value) queryWhen.value = shouldQuery;
    },
    { immediate: true, deep: true }
  );

  const params = computed(() => ({
    query: {
      part_id: idValue.value,
    },
  }));

  return useFind({
    model: store.Model,
    params: params,
  });
};
