import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "user-saved-lists";
const useUserSavedLists = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  actions: {
    addSavedList(title, userId) {
      const userSavedList = new this.Model({
        title: title,
        user_id: userId,
      });
      // Save it to the database
      return userSavedList.save();
    },
    deleteSavedListById(id) {
      return this.remove(id).catch((err) => console.error(err));
    },
  },
});

api.service(servicePath).hooks({});

export { useUserSavedLists };
