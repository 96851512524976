export function createPaginatedAction(options = {}) {
  const defaults = {
    // Fields that are queried as LIKE %search%
    searchFields: [],
    // Query parameters to merge with
    query: {},
  };
  // Create the contextual options
  const ctxOptions = Object.assign(defaults, options);
  // Return the action
  return function ({
    page = 1,
    itemsPerPage = 15,
    sortBy = [],
    sortDesc = [],
    params = {},
  } = {}) {
    // @TODO: improve sorting to allow multiple fields
    const sortByItem = sortBy.length ? sortBy[0] : null;
    // -1 == desc, 1 == asc
    let sortByField = sortDesc.length && sortDesc[0] === true ? -1 : 1;
    // Execute the search
    const query = {
      ...ctxOptions.query,
      $limit: itemsPerPage,
      $skip: (page - 1) * itemsPerPage,
      // Build params object
      ...Object.keys(params).reduce((acc, key) => {
        // Only keep params that are not null
        if (params[key] !== null) {
          // Change search fields to the correct format
          if (ctxOptions.searchFields.includes(key)) {
            acc[key] = { $like: `%${params[key]}%` };
          } else {
            acc[key] = params[key];
          }
        }
        return acc;
      }, {}),
    };
    // Add sorting, if any
    if (sortByItem) query.$sort = { [sortByItem]: sortByField };
    // Execute the search
    return this.find({ query });
  };
}

export default createPaginatedAction;
