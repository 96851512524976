import { defineStore, BaseModel } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { createPaginatedAction } from "../utils/feathers-paginated";

export class Workcenter extends BaseModel {
  static instanceDefaults(data = null) {
    return {
      id: data?.id ?? null,
      title: data?.title ?? "",
      facility_id: data?.facility_id ?? null,
      active: data?.active ?? true,
      created: data?.created ?? null,
      modified: data?.modified ?? null,
      created_by_id: data?.created_by_id ?? null,
      modified_by_id: data?.modified_by_id ?? null,
    };
  }
}

const servicePath = "workcenters";
export const useWorkcenters = defineStore({
  idField: "id",
  Model: Workcenter,
  clients: { api },
  servicePath,
  getters: {
    getWorkcenterName(state) {
      return (id) => {
        return state.itemsById[id]?.title || "";
      };
    },
    byFacility(state) {
      return (facilityId) => {
        return state.items.filter((item) => item.facility_id === facilityId);
      };
    },
    isActive(state) {
      return (id) => {
        return state.itemsById[id]?.active ?? false;
      };
    },
  },
  actions: {
    getPaginated: createPaginatedAction({
      searchFields: ["title"],
    }),
    hasUniqueTitle(title, facilityId, workcenterId = -1) {
      if (typeof title !== "string") return true;
      const trimmedTitle = title.trim().toLowerCase();
      return this.byFacility(facilityId).every((item) => {
        if (item.id === workcenterId) return true; // Don't compare with self
        if (typeof item.title !== "string") return true; // Don't compare with non-strings
        return item.title.trim().toLowerCase() !== trimmedTitle; // Compare trimmed titles
      });
    },
  },
});

api.service(servicePath).hooks({});
