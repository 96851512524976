<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="onHelpClick"
        icon
        v-on="on"
        v-bind="attrs"
        @click="onHelpClick"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { useHelp } from "@/store/help.pinia";

export default {
  name: "HelpLink",
  props: {
    value: {
      type: String,
      default: () => "",
    },
    icon: {
      type: String,
      default: "mdi-help-circle-outline",
    },
    tooltip: {
      type: String,
      default: () => "Help",
    },
  },
  setup(props) {
    const help = useHelp();

    const onHelpClick = () => {
      help.openContent(props.value);
    };

    return {
      onHelpClick,
    };
  },
};
</script>
