<template>
  <v-list-item class="pr-1">
    <v-list-item-content>
      <v-list-item-title class="d-flex">
        {{ facilityCode }}
        <v-spacer />
        {{ item.description }}
        <v-spacer />
        <span>
          {{ item.signal_method }}
        </span>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action class="ma-0">
      <v-btn v-if="flowId" @click.prevent.stop="onFlowClick(flowId)" plain text>
        <v-icon
          :color="flowColor"
          class="icon-highlighted-white"
          size="24"
          right
        >
          mdi-location-enter
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { computed } from "vue-demi";
import { useFacilities } from "../../store/facilities.pinia";
import { getColorFromInitiationType } from "../../utils/composables";

export default {
  name: "BTBufferListItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const facilitiesStore = useFacilities();

    const partId = computed(() => {
      if (props.item.part_id) return props.item.part_id;
      return props.item.parent_part_id ?? null;
    });

    const flowId = computed(() => {
      if (props.item.flow_id) return props.item.flow_id;
      return props.item.to_flow_id || props.item.flow_id || null;
    });

    const flowColor = computed(() => {
      if (!props.item.flow_initiation_detail?.text) return "grey";
      return getColorFromInitiationType(props.item.flow_initiation_detail.text);
    });

    const facilityCode = computed(() => {
      const facilityId = props.item?.facility_id ?? null;
      if (!facilityId) return "";
      return facilitiesStore.getFacilityCode(facilityId);
    });

    const onFlowClick = (id) => {
      emit("flow-click", id);
    };

    return {
      flowId,
      selectedPartId: partId,
      facilityCode,
      flowColor,
      onFlowClick,
    };
  },
};
</script>
