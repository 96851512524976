<template>
  <v-form
    v-if="appStore.isAuthenticated"
    ref="facilitySelection"
    v-model="valid"
  >
    <v-tooltip :value="showTooltip" bottom color="error">
      <template v-slot:activator="{ on }">
        <v-select
          ref="facilitySelector"
          label="Active Facility"
          :value="appStore.activeFacilityId"
          @input="setFacilityOverride"
          :items="facilitiesStore.activeFacilities"
          :rules="[(v) => !!v || 'Item is required']"
          item-text="code"
          item-value="id"
          :append-icon="
            isFacilityClearable ? 'mdi-close' : 'mdi-triangle-small-down'
          "
          @click:append="setFacilityOverride(null, on)"
          outlined
          hide-details
          dense
          filled
          required
          :background-color="backgroundColor"
          :color="color"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.code }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.title }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </template>
      <span>Select a facility to continue...</span>
    </v-tooltip>
  </v-form>
</template>

<script>
import { computed, ref, watch } from "vue-demi";
import { useStore } from "@/store/app.pinia.js";
import { useFacilities } from "@/store/facilities.pinia";

export default {
  name: "AppBarFacilitySelector",
  setup(props, { refs }) {
    const appStore = useStore();
    const facilitiesStore = useFacilities();

    const valid = ref(true);
    const showTooltip = computed(() => !appStore.activeFacilityId?.length);

    const isFacilityClearable = computed(() => {
      return appStore.facilityOverride !== null;
    });

    const setFacilityOverride = (facilityId) => {
      if (facilityId === null && !isFacilityClearable.value) {
        // If it isn't clearable, the user probably wants to open the menu
        refs.facilitySelector.focus();
        return refs.facilitySelector.activateMenu();
      }
      appStore.facilityOverride = facilityId;
    };

    const color = computed(() => {
      return showTooltip.value ? "error" : "";
    });

    const backgroundColor = computed(() => {
      // const def = "primary darken-3";
      const def = "primary";
      // If there is an error, then lighten the default color for readability
      if (showTooltip.value) {
        return `${def} lighten-4`;
      }
      // If the current facility is not the user's default facility, show a warning indicator color
      if (appStore.activeFacilityId !== appStore.defaultFacilityId) {
        return "warning darken-1";
      }
      // Otherwise, show the default color
      return `${def} darken-1`;
    });

    const validate = () => {
      // If the ref isn't available yet, recursively call until it is
      if (refs.facilitySelection === undefined) {
        setTimeout(validate, 100);
        return;
      }
      refs.facilitySelection.validate();
    };

    watch(() => appStore.activeFacilityId, validate, { immediate: true });

    return {
      showTooltip,
      appStore,
      facilitiesStore,
      setFacilityOverride,
      isFacilityClearable,
      valid,
      color,
      backgroundColor,
    };
  },
};
</script>

<style scoped>
::v-deep .v-select__selections input {
  display: none;
}
::v-deep .v-select {
  min-width: 7em;
}
</style>
