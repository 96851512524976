<template>
  <v-container>
    <KeepAlive>
      <router-view></router-view>
    </KeepAlive>
  </v-container>
</template>

<script>
export default {
  name: "AliveInternalBasicPage",
  setup() {
    return {};
  },
};
</script>
