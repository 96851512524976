<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          label="Workcenter Search"
          class="mt-2"
          outlined
          clearable
          hide-details
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-0 pb-0">
        <v-checkbox v-model="onlyActive" label="Only Active" class="mt-0" />
      </v-col>
      <v-col class="mb-0 pb-0">
        <v-btn-toggle v-model="toggle" color="primary" class="mb-2 float-right">
          <v-btn :value="true" small>
            <v-icon> mdi-arrow-expand-vertical </v-icon>
          </v-btn>
          <v-btn :value="false" small>
            <v-icon> mdi-arrow-collapse-vertical </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pt-0 mt-0">
        <v-list class="" dense>
          <v-list-item
            v-if="
              appStore.currentFacility
                ? appStore.canEditCurrentFacility
                : appStore.canEditActiveFacility
            "
            :to="{
              name: `WorkcentersEdit`,
              query: { facilityId: appStore.activeFacilityId },
            }"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Workcenter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-progress-linear
        v-if="isPending"
        indeterminate
        color="primary"
      ></v-progress-linear>
    </v-expand-transition>
    <v-expansion-panels v-model="openPanels" multiple accordion>
      <v-expansion-panel
        v-for="workcenter in workcenters"
        :key="workcenter.id"
        color="error"
        :dark="true"
      >
        <v-expansion-panel-header
          :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
        >
          <template v-slot:actions>
            <v-tooltip v-if="processes[workcenter.id].length" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="mr-0"
                  color="success"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ processes[workcenter.id].filter((i) => i.active).length }}
                </v-chip>
              </template>
              <span>Active Processes</span>
            </v-tooltip>
            <v-icon>mdi-chevron-down</v-icon>
          </template>
          <v-row no-gutters>
            <v-col cols="12">
              <v-badge
                dot
                inline
                left
                :color="workcenter.active ? 'success' : 'error'"
              >
                {{ workcenter.title }}
              </v-badge>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense>
            <v-list-item
              :to="{
                name: `WorkcenterOverview`,
                params: { workcenterId: workcenter.id },
              }"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-tools</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Workcenter Overview </v-list-item-title>
                <div class="text-center"></div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="appStore.canEditCurrentFacility && workcenter.active"
              :to="{
                name: `WorkcenterOverview`,
                params: { workcenterId: workcenter.id },
                query: { addProcess: 1 },
              }"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Add Process </v-list-item-title>
                <div class="text-center"></div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="process in processes[workcenter.id]"
              :key="process.id"
              :to="{
                name: `ProcessOverview`,
                params: { workcenterId: workcenter.id, processId: process.id },
              }"
            >
              <v-badge
                inline
                dot
                left
                :color="process.active ? 'success' : 'error'"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ process.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-badge>
              <v-spacer />
              <v-tooltip
                v-if="getProcessOperationCount(process.id) != false"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="align-self-center mr-0"
                    color="success"
                    small
                  >
                    {{ getProcessOperationCount(process.id) }}
                  </v-chip>
                </template>
                <span>Operation Count</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { computed, ref, watch } from "vue-demi";
import { useFind } from "feathers-pinia";
import { sortByStringProperty } from "@/utils";
import { useStore } from "@/store/app.pinia.js";
import { useWorkcenters } from "@/store/workcenters.pinia.js";
import { useProcessTemplates } from "../store/processTemplates.pinia";
import { useProcessCount } from "../store/processCount.pinia";

export default {
  name: "SidebarWorkcenters",
  setup() {
    const appStore = useStore();
    const workcentersStore = useWorkcenters();
    const processTemplatesStore = useProcessTemplates();
    const ProcessCountStore = useProcessCount();

    const openPanels = ref([]);
    const toggle = ref(false);
    const search = ref("");
    const onlyActive = ref(true);

    const workcentersParams = computed(() => {
      const query = {
        $limit: 1000,
        $sort: { title: 1 },
        facility_id: appStore.activeFacilityId,
      };

      if (onlyActive.value) query.active = true;

      return {
        query,
        debounce: 500,
      };
    });
    // 3. Provide the Model class and params in the options
    const workcentersData = useFind({
      model: workcentersStore.Model,
      params: workcentersParams,
    });

    const isPending = workcentersData.isPending;

    // Create sortBy method
    const sortByTitle = sortByStringProperty("title");

    const workcenters = computed(() => {
      const searchString = search.value?.toLowerCase() || "";
      // If search is empty, return all
      if (!searchString.length)
        return workcentersData.items.value.sort(sortByTitle);
      // Otherwise, filter by search string
      return workcentersData.items.value
        .filter((workcenter) => {
          return workcenter.title.toLowerCase().indexOf(searchString) !== -1;
        })
        .sort(sortByTitle);
    });

    const workcenterIds = computed(
      () => workcenters?.value.map((workcenter) => workcenter.id) ?? []
    );

    const processParams = computed(() => {
      const query = {
        workcenter_id: {
          $in: workcenterIds.value,
        },
        $sort: { title: 1 },
        $limit: 1000, // TODO: If we need more than 1000, more logic required
      };

      if (onlyActive.value) query.active = true;

      return {
        query,
      };
    });

    const { items: processesTemplates } = useFind({
      model: processTemplatesStore.Model,
      params: processParams,
      queryWhen: computed(() => !!workcenterIds.value.length),
    });

    // Provide an array of processes for each workcenter
    const processes = computed(() => {
      // Create an object that contains an array for each workcenter
      const processesContainer = workcenters?.value?.reduce(
        (acc, workcenter) => {
          acc[workcenter.id] = [];
          return acc;
        },
        {}
      );
      return (
        processesTemplates?.value
          // Sor alphabetically
          ?.sort(sortByTitle)
          // Group by workcenter
          .reduce((acc, val) => {
            if (!acc[val.workcenter_id]) acc[val.workcenter_id] = [];
            acc[val.workcenter_id].push(val);
            return acc;
          }, processesContainer) ?? []
      );
    });

    const processesByWorkcenterId = (workcenterId) => {
      return processes.value[workcenterId] ?? [];
    };

    // Process Operation Counts
    const poQuery = computed(() => {
      return {
        query: {
          type: "process",
          FacilityId: appStore.activeFacilityId,
        },
      };
    });
    // Operation Detail Counts
    const odQuery = computed(() => {
      return {
        query: {
          type: "processdetail",
          FacilityId: appStore.activeFacilityId,
        },
      };
    });
    // No need to assign
    useFind({
      model: ProcessCountStore.Model,
      params: poQuery,
    });
    useFind({
      model: ProcessCountStore.Model,
      params: odQuery,
    });

    const getProcessOperationCount = (processId) => {
      const count = parseInt(
        ProcessCountStore.processOperationCountById(processId.toLowerCase())
      );
      if (isNaN(count)) return false;
      return count > 0 ? count : false;
    };

    const activatePanels = (on = true) => {
      openPanels.value = !on ? [] : workcenters.value.map((v, i) => i);
    };

    watch(
      () => [workcenters.value, appStore.activeFacilityId],
      (vals, oldVals) => {
        const oldCount = oldVals[0]?.length;
        const newCount = vals[0]?.length;
        if (oldCount !== newCount) activatePanels(toggle.value);
      },
      { immediate: true }
    );

    watch(
      () => toggle.value,
      (v, p) => {
        // If the new value is undefined, then the user clicked the same button twice
        const n = v !== undefined ? v : p;
        activatePanels(n);
      }
    );

    return {
      isPending,
      workcenters,
      processes,
      processesByWorkcenterId,
      activatePanels,
      toggle,
      search,
      onlyActive,
      openPanels,
      workcentersData,
      appStore,
      getProcessOperationCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header {
  padding: 10px;
}
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-list-item::v-deep {
  .v-list-item__icon:first-child {
    margin-right: 10px !important; // Reduce the icon margin
  }
}
</style>
