import { makeServiceStore, BaseModel } from "./service-store/index";
import { defineStore as definePiniaStore } from "pinia";
import { registerModel } from "./models";
import { registerClient, clients } from "./clients";
import { enableServiceEvents } from "./service-store/events";
export function defineStore(_options) {
  const defaults = {
    ssr: false,
    clients: {},
    clientAlias: "api",
    servicePath: "",
    idField: "id",
    tempIdField: "__tempId",
    enableEvents: true,
    debounceEventsTime: 20,
    debounceEventsMaxWait: 1000,
    whitelist: [],
    state: () => ({}),
    getters: {},
    actions: {},
  };
  const options = Object.assign({}, defaults, _options);
  const actions = options.actions;
  const clientAlias = options.clientAlias || "api";
  const {
    ssr,
    servicePath,
    idField,
    tempIdField,
    enableEvents,
    debounceEventsTime,
    debounceEventsMaxWait,
    whitelist,
    state,
    getters,
  } = options;
  let { handleEvents = {} } = options;
  let isInitialized = false;
  Object.keys(options.clients || {}).forEach((name) => {
    registerClient(name, clients[name]);
  });
  // Setup the event handlers. By default they just return the value of `options.enableEvents`
  const defaultHandleEvents = {
    created: () => enableEvents,
    patched: () => enableEvents,
    updated: () => enableEvents,
    removed: () => enableEvents,
  };
  handleEvents = Object.assign({}, defaultHandleEvents, handleEvents);
  // If no Model class is provided, create a dynamic one.
  if (!options.Model) {
    class DynamicBaseModel extends BaseModel {}
    DynamicBaseModel.modelName = servicePath;
    options.Model = DynamicBaseModel;
  }
  if (!options.Model.modelName) {
    options.Model.modelName = options.Model.name;
  }
  // Create and initialize the Pinia store.
  const storeOptions = makeServiceStore({
    ssr,
    id: options.id || `service.${options.servicePath}`,
    idField,
    tempIdField,
    clientAlias,
    servicePath,
    clients,
    Model: options.Model,
    state,
    getters,
    actions,
    whitelist,
  });
  function useStore(pinia) {
    const useStoreDefinition = definePiniaStore(storeOptions);
    const initializedStore = useStoreDefinition(pinia);
    if (!isInitialized) {
      isInitialized = true;
      // Monkey patch the model with the store and other options
      Object.assign(options.Model, {
        store: initializedStore,
        pinia,
        servicePath: options.servicePath,
        idField,
        tempIdField,
        clients,
        // Bind `this` in custom actions to the store.
        ...Object.keys(actions).reduce((boundActions, key) => {
          const fn = actions[key];
          boundActions[key] = fn.bind(initializedStore);
          return boundActions;
        }, {}),
      });
      const client = clients[clientAlias];
      if (!client) {
        throw new Error(
          `There is no registered FeathersClient named '${clientAlias}'. You need to provide one in the 'defineStore' options.`
        );
      }
      const service = client.service(servicePath);
      const opts = {
        idField,
        debounceEventsTime,
        debounceEventsMaxWait,
        handleEvents,
      };
      registerModel(options.Model, initializedStore);
      enableServiceEvents({
        service,
        Model: options.Model,
        store: initializedStore,
        options: opts,
      });
    }
    return initializedStore;
  }
  return useStore;
}
