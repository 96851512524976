import React, { memo } from "react";
import BaseNode from "./base/BaseNode";
import { mdiFileDocument } from "@mdi/js";

React;

const MaterialSpecificationNode = memo((props) => {
  // Define left icons
  const iconsLeft = {
    color: "#000000",
    props: [
      {
        path: mdiFileDocument,
        title: "Material Specification",
      },
    ],
  };

  return (
    <BaseNode
      {...props}
      handles={["top", "bottom", "right"]}
      iconsLeft={iconsLeft}
    />
  );
});

export { MaterialSpecificationNode };
