import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { createPaginatedAction } from "../utils/feathers-paginated";

const servicePath = "facilities";
const useFacilities = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    activeFacilities() {
      return this.allFacilities.filter((item) => !item.hide_in_dropdown);
    },
    allFacilities(state) {
      return (
        Object.values(state.itemsById || {})
          // sort by title
          .sort((a, b) => {
            let titleA = a.title.toUpperCase();
            let titleB = b.title.toUpperCase();
            return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
          })
      );
    },
    getFacilityName(state) {
      return (id) => {
        return state.itemsById[id]?.title || "";
      };
    },
    getFacilityCode(state) {
      return (id) => {
        return state.itemsById[id]?.code || "";
      };
    },
  },
  actions: {
    getPaginated: createPaginatedAction(),
  },
});

api.service(servicePath).hooks({});

// Preload the data
// const facilitiesStore = useFacilities();
// facilitiesStore.find({ query: {} }).then(console.log);

export { useFacilities };
