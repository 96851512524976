import { set } from "lodash-es";
import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { i18n } from "../i18n";

const servicePath = "i18n";
export const useI18n = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    translations() {
      return this.items.reduce((acc, item) => {
        if (typeof item.key === "string" && item.key.length) {
          // Initialize the translation object if it doesn't exist
          if (acc[item.lang] === undefined) acc[item.lang] = {};
          // Set this item's value
          set(acc[item.lang], item.key, item.value);
        }
        return acc;
      }, {});
    },
  },
  actions: {
    async load() {
      await this.find({ query: {} });
      // Set all the translation messages
      for (const lang in this.translations) {
        i18n.setLocaleMessage(lang, this.translations[lang]);
      }
    },
  },
});

api.service(servicePath).hooks({});
