import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth, { AuthenticationClient } from "@feathersjs/authentication-client";
import io from "socket.io-client";
import { getBaseUrl } from "./";
// import { iff, discard } from 'feathers-hooks-common'

const socket = io(getBaseUrl(), { transports: ["websocket"] });

class SSOAuthenticationClient extends AuthenticationClient {
  authenticate(authentication, params) {
    if (!authentication) {
      return this.reAuthenticate();
    }

    const promise = this.service
      .create(authentication, params)
      .then((authResult) => {
        const { accessToken } = authResult;

        this.authenticated = true;
        this.app.emit("login", authResult);
        this.app.emit("authenticated", authResult);

        return this.setAccessToken(accessToken).then(() => authResult);
      })
      .catch((error) => this.handleError(error, "authenticate"));

    this.app.set("authentication", promise);

    return promise;
  }
}

// This variable name becomes the alias for this server.
export const api = feathers()
  .configure(socketio(socket))
  .configure(
    auth({
      storage: window.localStorage,
      Authentication: SSOAuthenticationClient,
      strategy: "jwt",
    })
  );

// Set the api as a global variable for debugging/testing
window.$fapi = api;
