import { ref, unref, computed, watch } from "vue-demi";
import { isEqual } from "lodash-es";
import { isRegionUS } from "./variables";
import { useFacilities } from "../store/facilities.pinia";

export const computedRef = (watcherFunc) => {
  const itemValue = ref(null);
  const computedValue = computed(watcherFunc);
  watch(
    () => unref(computedValue),
    (newValue) => {
      // Only update the value if it has changed
      if (!isEqual(newValue, itemValue.value)) itemValue.value = newValue;
    },
    { immediate: true, deep: true }
  );
  return itemValue;
};

export const formatPrice = (value) => {
  let val = (value / 1).toFixed(2).replace(",", ".");
  // @TODO: Use vue-i18n when we deal with conversions
  const prependSymbol = isRegionUS ? "$" : "";
  // this regex is from https://stackoverflow.com/a/2901298/11236
  return prependSymbol + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// get source facility code by part id
export const getSourceFacilityCode = (propNodePart) => {
  const facilitiesStore = useFacilities();
  return computed(() => ({
    code: facilitiesStore.itemsById[propNodePart.value?.facility_id]?.code,
  }));
};

// filter schedule documents by node->part->part_preschedule->schedule_station_id
export const filterScheduleDocumentsBySpecificId = (
  scheduleDocumentResults,
  partScheduleStationId
) => {
  return computed(() => {
    const scheduleStationId = unref(partScheduleStationId);
    return {
      result:
        scheduleDocumentResults.value?.filter((item) => {
          if (
            item.specific_schedule_station_id &&
            item.specific_schedule_station_id != null
          ) {
            return item.specific_schedule_station_id === scheduleStationId;
          } else return true;
        }) ?? [],
    };
  });
};

export const getColorFromInitiationType = (value) => {
  const colorMap = {
    emove: "#ec3636", // red
    purchased: "#41b3ff", // light blue
    "manufactured mrp": "#40f15d", // light green
    visual: "#181818", // dark grey
    "manufactured not mrp": "#edc918", // gold
    "manufactured non mrp": "#edc918", // gold
    "finished goods": "#309249", // dark green
    chained: "#f946fc", // light pink
  };

  const cleanedValue = value.replace("Type: ", "").toLowerCase();

  return colorMap[cleanedValue] ?? "black";
};

export const getColorFromPartStatus = (value) => {
  const colorMap = {
    current: "success",
    obsolete: "error",
  };

  return colorMap[(value || "").toLowerCase()] ?? "black";
};
