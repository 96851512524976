<template>
  <div>
    <h1>Site Maintenance</h1>
    <p>
      We're currently performing maintenance on the site. We'll be back up and
      running soon!
    </p>
  </div>
</template>

<script>
export default {
  name: "MaintenanceMode",
};
</script>

<style scoped>
/* Add some basic styling to make the page look presentable */
h1 {
  font-size: 3rem;
  text-align: center;
}

p {
  font-size: 1.5rem;
  text-align: center;
}
</style>
