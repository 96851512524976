import { set } from "vue-demi";
import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "help";
export const useHelp = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  state: () => {
    return {
      drawer: false,
      editMode: false,
      activeId: null,
    };
  },
  actions: {
    openContent(key = false) {
      set(this, "activeId", key);
      set(this, "drawer", true);
    },
    close() {
      set(this, "drawer", false); // close the drawer
      set(this, "activeId", false); // close content
      set(this, "editMode", false); // Reset edit mode
    },
  },
});

api.service(servicePath).hooks({});
