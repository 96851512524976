<template>
  <v-dialog v-model="dialog" max-width="100%" width="400">
    <v-card>
      <v-card-title class="text-h5"> Logout </v-card-title>

      <v-card-text> Are you sure you want to logout? </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="error" text @click="dialog = false"> Cancel </v-btn>

        <v-btn color="green darken-1" text @click="logout"> Logout </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from "vue-demi";
import { useAuth } from "@/store/auth.pinia.js";

export default {
  name: "AppLogoutDialog",
  props: {
    value: {
      default: null,
    },
  },
  setup(props, context) {
    const authStore = useAuth();

    const dialog = computed({
      get() {
        return this.value;
      },
      set(val) {
        context.emit("input", val);
      },
    });

    const logout = () => {
      authStore.logout();
      dialog.value = false;
    };

    return {
      logout,
      dialog,
    };
  },
};
</script>
