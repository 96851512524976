<template>
  <BaseInternalParentPage>
    <router-view></router-view>
  </BaseInternalParentPage>
</template>

<script>
import BaseInternalParentPage from "./BaseInternalParentPage.vue";

export default {
  name: "InternalParentPage",
  components: {
    BaseInternalParentPage,
  },
};
</script>
