import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "operation";
const useOperation = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
});

api.service(servicePath).hooks({});

export { useOperation };
