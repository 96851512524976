import {
  dagHierarchy,
  sugiyama,
  layeringSimplex,
  decrossTwoLayer,
  // coordCenter,
  coordQuad,
} from "d3-dag/dist";
import { outputLines } from "./nodes/index.js";
import { get } from "lodash-es";

const DEFAULT_WIDTH = 250;
const DEFAULT_HEIGHT = 50;
const DEFAULT_HEIGHT_PADDING = 40;

const getPreviousFlowDetails = (node, flowDetails = []) => {
  const flowDetail = node?.data?.detail;
  // Find the previous node
  const previousFlowDetail = flowDetails.find(
    (detail) => detail.id === flowDetail.linked_from
  );
  // If there is no previous flow detail, return the an empty array
  if (!previousFlowDetail) return [];
  // If there is a previous flow detail, return the previous flow details
  return [
    previousFlowDetail,
    // Recursively call this function to get the previous flow details
    ...getPreviousFlowDetails(previousFlowDetail, [
      ...flowDetails.filter(
        (detail) => ![flowDetail.id, previousFlowDetail.id].includes(detail.id)
      ),
    ]),
  ];
};

export const createGraphLayout = async (elements, flow = null) => {
  // Get separate arrays of nodes and edges
  const nodes = elements.filter((el) => el.elementType === "node");
  const edges = elements.filter((el) => el.elementType === "edge");

  // Find the ending node
  const endingNode = elements.find(
    (node) => node?.data?.detail?.regarding_object_id === flow.buffer_id
  );

  // Create the dag hierarchy map
  const dagHierarchyMap = {
    id: "root",
    // Each flow detail is a root child, and linked_to items be children of them.
    children: [endingNode, ...getPreviousFlowDetails(endingNode, nodes)].map(
      (node) => {
        return {
          ...node,
          children: nodes
            .filter((n) => n?.data?.detail.linked_to === node.id)
            .map((n) => ({
              id: n.id,
              isChild: true,
            })),
        };
      }
    ),
  };

  const createDag = dagHierarchy();
  const dag = createDag(dagHierarchyMap);

  // Render horizontally, then flip X and Y coordinates later
  const layout = sugiyama()
    .layering(layeringSimplex())
    // .layering(layeringTopological())
    // .coord(coordSimplex())
    // .coord(coordGreedy())
    .coord(coordQuad())
    // .coord(coordTopological())
    // .coord(coordCenter())
    .decross(decrossTwoLayer())
    // Size nodes as if they were flipped 90 degrees
    .nodeSize((node) => {
      if (!node?.data?.id) return [0, 0];
      let heightAdjustment = DEFAULT_HEIGHT_PADDING;
      let widthAdjustment = 0;
      // Child nodes should have more horizontal space
      if (node?.data?.isChild) {
        widthAdjustment = DEFAULT_WIDTH * 0.75;
      }
      // Add height spacing for each child node
      const childrenCount = node?.data?.children?.length ?? 0;
      heightAdjustment +=
        (childrenCount > 1 ? childrenCount - 1 : 0) *
        (DEFAULT_HEIGHT + heightAdjustment);
      const nodeSize = [
        DEFAULT_HEIGHT + heightAdjustment + getNodeHeight(node) * 4,
        DEFAULT_WIDTH + widthAdjustment,
      ];
      node.data.nodeSize = nodeSize;
      return nodeSize;
    });

  // getNodeHeight based on length of strings, if the string is longer than 44 chars
  // the string will be on 2 lines and the nodeheight should reflect that
  const getNodeHeight = (node) => {
    const nodeTypeOutputs = outputLines[node?.data?.data?.type]?.() ?? ["", ""];
    const charsPerLine = 44;
    const lines = nodeTypeOutputs.length;
    const characters = nodeTypeOutputs
      .map((line) => get(node, line, "").length)
      .reduce((a, b) => a + b, 0);

    return Math.floor(characters / charsPerLine + lines) > lines
      ? characters / charsPerLine + lines
      : 0.5;
  };

  // Execute the layout
  layout(dag);

  // Build the new nodes array
  const newNodes = [];

  for (const node of dag) {
    // Find matching node
    const n = nodes.find((n) => n.id === node.data.id);
    // Don't process empty nodes
    if (!n || !n.id) continue;
    // Create new node
    const newNode = {
      ...n,
      draggable: false,
      connectable: false,
      processedNode: node,
      // Flip X and Y coordinates, so it renders vertically
      position: { x: node.y, y: node.x },
    };
    newNodes.push(newNode);
  }

  // Move noGap nodes so they tough their parent node
  const noGapNodes = newNodes.filter((node) => node?.noGap);

  noGapNodes.forEach((node) => {
    node.position.y -= DEFAULT_HEIGHT_PADDING;
    // And also move up any child nodes
    const childNodes = newNodes.filter(
      (n) => n?.data?.detail?.linked_to === node.id
    );
    childNodes.forEach(
      (childNode) => (childNode.position.y -= DEFAULT_HEIGHT_PADDING)
    );
  });

  // Move all source buffers up a bit
  const sourceBuffers = newNodes.filter(
    (node) => node?.data?.detail?.type?.toLowerCase() === "sourcebuffer"
  );
  sourceBuffers.forEach((node) => (node.position.y -= 10.5));

  // Move all alternate legs down, to the same level as the following node
  const alternateLegs = newNodes.filter(
    (node) => node?.data?.detail?.type?.toLowerCase() === "alternateleg"
  );
  if (alternateLegs.length > 0) {
    // Get the position of the destination buffer
    const destinationBuffer = newNodes.find(
      (node) => node?.data?.detail?.type?.toLowerCase() === "destinationbuffer"
    );
    const destinationBufferY = destinationBuffer?.position?.y ?? null;
    if (destinationBufferY) {
      // Position the last alternate leg at the same level as the destination buffer
      // and move the rest, the same relative amount
      const lastAlternateLegY =
        alternateLegs[alternateLegs.length - 1]?.position?.y ?? null;
      if (lastAlternateLegY) {
        const alternateLegsYDiff = lastAlternateLegY - destinationBufferY;
        alternateLegs.forEach(
          (node) => (node.position.y -= alternateLegsYDiff)
        );
      }
    }
  }

  /**
   * Update the initiation node to be the highest node
   */
  const initiationNode = newNodes.find(
    (node) => node?.data?.detail?.type?.toLowerCase() === "initiation"
  );
  // Get the highest node
  const highestNode = newNodes.reduce((prev, current) =>
    prev.position.y < current.position.y ? prev : current
  );
  // If the highest node is higher than the initiation node, move the initiation node up
  if (initiationNode && highestNode.position.y < initiationNode.position.y) {
    initiationNode.position.y = highestNode.position.y - 50;
  }

  return { nodes: newNodes, edges };
};
