import { i18n } from "../i18n";
import Home from "../views/Home.vue";
import InternalParentPage from "../views/InternalParentPage.vue";
import AliveInternalBasicPage from "../views/AliveInternalBasicPage.vue";
import Workflow from "../components/Workflow.vue";
import {
  facilityName,
  workcenterName,
  username,
  processTitle,
} from "./utils/routeTitles";
import { disableSearch } from "../utils/variables";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/LoginPage.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: disableSearch ? "/facilities/index" : "/flow-search/search",
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: InternalParentPage,
    children: [
      {
        path: "typesense",
        name: "AdminTypesense",
        component: () =>
          import(
            /* webpackChunkName: "typesense-admin" */ "../components/AdminTypesense.vue"
          ),
      },
      {
        path: "users",
        name: "Users",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "../components/AdminUsers.vue"
          ),
        meta: {
          title: "Users",
          breadcrumbPath: ["Admin", "Users"],
        },
      },
      {
        path: "users/:userId/settings",
        name: "AdminUsersSettings",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-users-settings" */ "../components/AdminUsersSettings.vue"
          ),
        meta: {
          title: (route) => {
            return `User Settings | ${username(route)}`;
          },
          breadcrumbPath: ["Admin", "Users", "Settings"],
        },
      },
      {
        path: "users/:userId/permissions",
        name: "AdminUsersPermissions",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-users-permissions" */ "../components/AdminUsersPermissions.vue"
          ),
        meta: {
          title: "User Permissions",
          breadcrumbPath: ["Admin", "Users", "Permissions"],
        },
      },
    ],
  },
  {
    path: "/workcenters",
    name: "Workcenters",
    component: InternalParentPage,
    redirect: "/workcenters/index",
    children: [
      {
        path: "index",
        name: "WorkcentersBrowse",
        component: () => import("../components/WorkcentersBrowse.vue"),
        meta: {
          title: "Workcenters | Browse",
        },
        default: true,
      },
      {
        path: "edit/:id?",
        name: "WorkcentersEdit",
        props: true,
        component: () => import("../components/WorkcentersEdit.vue"),
        meta: {
          title: (route) =>
            `Workcenters | ${
              route.params.id ? "Edit " + route.params.id : "Create"
            }`,
        },
      },
      {
        path: ":workcenterId/overview",
        name: "WorkcenterOverview",
        props: true,
        component: () => import("../components/WorkcenterOverview.vue"),
        meta: {
          title: workcenterName,
          breadcrumbPath: [
            "FacilityOverview",
            "WorkcenterOverview",
            i18n.t("overview"),
          ],
          helpId: "workcenters.overview",
        },
      },
      {
        path: ":workcenterId/audit-log",
        name: "WorkcenterAuditLog",
        props: true,
        component: () => import("../components/WorkcenterAuditLog.vue"),
        meta: {
          title: (route) => `${workcenterName(route)} | Audit Log`,
          breadcrumbPath: [
            "FacilityOverview",
            "WorkcenterOverview",
            "Audit Log",
          ],
        },
      },
      {
        path: ":workcenterId/process/:processId/overview",
        name: "ProcessOverview",
        props: true,
        component: () => import("../components/ProcessOverview.vue"),
        meta: {
          title: (route) => {
            return `${workcenterName(route)} | ${processTitle(route)}`;
          },
          breadcrumbPath: [
            "FacilityOverview",
            "WorkcenterOverview",
            "ProcessOverview",
            i18n.t("overview"),
          ],
          helpId: "processes.overview",
        },
      },
      {
        path: ":workcenterId/process/:processId/audit-log",
        name: "ProcessAuditLog",
        props: true,
        component: () => import("../components/ProcessAuditLog.vue"),
        meta: {
          title: (route) => {
            return [
              workcenterName(route),
              processTitle(route),
              "Audit Log",
            ].join(" | ");
          },
          breadcrumbPath: [
            "FacilityOverview",
            "WorkcenterOverview",
            "ProcessOverview",
            "Audit Log",
          ],
        },
      },
    ],
  },
  {
    path: "/facilities",
    name: "Facilities",
    component: InternalParentPage,
    redirect: "/facilities/index",
    children: [
      {
        path: "index",
        name: "FacilitiesBrowse",
        component: () => import("../components/FacilitiesBrowse.vue"),
        meta: {
          title: "Facilities | Browse",
        },
        default: true,
      },
      {
        path: ":facilityId/overview",
        name: "FacilityOverview",
        props: true,
        component: () => import("../components/FacilityOverview.vue"),
        meta: {
          title: facilityName,
          breadcrumbPath: ["FacilityOverview", "Overview"],
        },
      },
    ],
  },
  {
    path: "/kitchen",
    name: "Kitchen",
    component: InternalParentPage,
    redirect: "/kitchen/index",
    children: [
      {
        path: "index",
        name: "KitchenSink",
        component: () =>
          import(
            /* webpackChunkName: "kitchen-sink" */ "../components/KitchenSink.vue"
          ),
        meta: {
          title: "Kitchen | Sink",
        },
        default: true,
      },
    ],
  },
  {
    path: "/flow-search",
    name: "FlowSearchRoot",
    component: AliveInternalBasicPage,
    redirect: "/flow-search/search",
    children: [
      {
        path: "search",
        name: "FlowSearch",
        component: () =>
          import(
            /* webpackChunkName: "flow-search" */ "../components/FlowSearch.vue"
          ),
        meta: {
          title: "Flows | Search",
          breadcrumbPath: ["FlowSearch"],
        },
        default: true,
        props: true,
      },
    ],
  },
  {
    path: "/flow-viewer/:flowId",
    name: "FlowViewer",
    component: () =>
      import(
        /* webpackChunkName: "flow-viewer" */ "../components/FlowViewer.vue"
      ),
    meta: {
      title: "Flows | Flow Viewer",
      breadcrumbPath: ["FlowSearch", "FlowViewer"],
    },
    props: true,
  },
  {
    path: "/workflow",
    name: "Workflow",
    component: Workflow,
  },
];

export default routes;
