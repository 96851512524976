import { computed, reactive, unref, toRefs, watch, set } from "vue-demi";
import debounce from "just-debounce";
import { getQueryInfo, getItemsFromQueryInfo } from "./utils";
export function useFind({
  model = null,
  params = computed(() => null),
  fetchParams = computed(() => undefined),
  qid = "default",
  queryWhen = computed(() => true),
  local = false,
  immediate = true,
}) {
  if (!model) {
    throw new Error(
      `No model provided for useFind(). Did you define and register it with FeathersPinia?`
    );
  }
  const getParamsForFetch = (providedParams) => {
    const provided = unref(providedParams);
    const forFetch = unref(fetchParams);
    const paramsToUse =
      provided || provided === null
        ? provided
        : forFetch || forFetch === null
        ? forFetch
        : unref(params);
    return paramsToUse;
  };
  const state = reactive({
    qid,
    isPending: false,
    haveBeenRequested: false,
    haveLoaded: local,
    error: null,
    debounceTime: null,
    latestQuery: null,
    isLocal: local,
    request: null,
  });
  const computes = {
    // The find getter
    items: computed(() => {
      const getterParams = unref(params);
      if (getterParams) {
        if (getterParams.paginate) {
          const serviceState = model.store;
          const { defaultSkip, defaultLimit } = serviceState.pagination;
          const skip = getterParams.query.$skip || defaultSkip;
          const limit = getterParams.query.$limit || defaultLimit;
          const pagination =
            computes.paginationData.value[getterParams.qid || state.qid] || {};
          const response = skip != null && limit != null ? { limit, skip } : {};
          const queryInfo = getQueryInfo(getterParams, response);
          const items = getItemsFromQueryInfo(
            pagination,
            queryInfo,
            serviceState.itemsById
          );
          return items;
        } else {
          return model.findInStore(getterParams).data;
        }
      } else {
        return [];
      }
    }),
    paginationData: computed(() => {
      return model.store.pagination;
    }),
    servicePath: computed(() => model.servicePath),
    isSsr: computed(() => model.store.isSsr),
  };
  /**
   * If queryWhen is a function, call queryWhen with a context, otherwise return it's value.
   * @param queryWhen
   * @param params
   * @returns boolean
   */
  function handleQueryWhen(queryWhen, params) {
    const val = unref(queryWhen);
    // If queryWhen returns a function, call it with a context
    if (typeof val === "function") {
      const info = getQueryInfo(params, {});
      const qidData = model.store.pagination[info.qid];
      const queryData = qidData?.[info.queryId];
      const pageData = queryData?.[info.pageId];
      const context = {
        items: computes.items,
        queryInfo: info,
        qidData,
        queryData,
        pageData,
        isPending: computed(() => state.isPending),
        haveBeenRequested: computed(() => state.haveBeenRequested),
        haveLoaded: computed(() => state.haveLoaded),
        error: computed(() => state.error),
      };
      return val(context);
    }
    return val;
  }
  /**
   * Fetch records from the API server.
   * @param params
   * @returns query results
   */
  function find(params) {
    if (state.isLocal) return;
    params = unref(params);
    set(state, "isPending", true);
    set(state, "haveBeenRequested", true);
    const request = model.find(params).then((response) => {
      // To prevent thrashing, only clear error on response, not on initial request.
      set(state, "error", null);
      set(state, "haveLoaded", true);
      if (!Array.isArray(response)) {
        const queryInfo = getQueryInfo(params, response);
        queryInfo.response = response;
        queryInfo.isOutdated = false;
        set(state, "latestQuery", queryInfo);
      }
      set(state, "isPending", false);
      return response;
    });
    set(state, "request", request);
    return request;
  }
  const methods = {
    findDebounced(params) {
      return find(params);
    },
  };
  function findProxy(params) {
    const paramsToUse = getParamsForFetch(params);
    if (paramsToUse && paramsToUse.debounce) {
      if (paramsToUse.debounce !== state.debounceTime) {
        methods.findDebounced = debounce(find, paramsToUse.debounce);
        set(state, "debounceTime", paramsToUse.debounce);
      }
      return methods.findDebounced(paramsToUse);
    } else if (paramsToUse) {
      return find(paramsToUse);
    } else {
      // Set error
    }
  }
  const wrappedQueryWhen = computed(() => {
    const params = getParamsForFetch();
    if (typeof queryWhen.value === "function") {
      return handleQueryWhen(queryWhen.value, params);
    } else {
      return queryWhen.value;
    }
  });
  watch(
    () => [getParamsForFetch(), wrappedQueryWhen.value],
    // eslint-disable-next-line no-unused-vars
    ([params, queryWhen]) => {
      if (queryWhen) {
        findProxy();
      }
    },
    { immediate }
  );
  return { ...computes, ...toRefs(state), find: findProxy };
}
