<template>
  <v-app-bar
    ref="appBar"
    app
    color="primary"
    class="v-bar--underline"
    dark
    v-model="isDisplayed"
    transition="slide-x-transition"
  >
    <!-- <div class="d-flex align-center"></div> -->
    <transition name="slide-x">
      <v-app-bar-nav-icon
        v-if="appStore.activeFacilityId && !appStore.drawer"
        @click="appStore.toggleDrawer()"
      />
    </transition>

    <AppBarEnv />
    <v-spacer></v-spacer>
    <AppBarFacilitySelector />
    <v-spacer></v-spacer>
    <template v-slot:extension transition="fade">
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        v-if="appStore.activeFacilityId && !isDisplayed"
        @click="appStore.toggleDrawer()"
        transition="slide-y-transition"
      />
      <Breadcrumbs />
    </template>
  </v-app-bar>
</template>

<script>
import { ref, watch, set } from "vue-demi";
import { useElementSize } from "@vueuse/core";
import { useStore } from "@/store/app.pinia.js";
import AppBarEnv from "./AppBarEnv.vue";
import AppBarFacilitySelector from "./AppBarFacilitySelector.vue";
import Breadcrumbs from "./AppBreadcrumbs.vue";

export default {
  name: "AppBar",
  components: {
    AppBarEnv,
    AppBarFacilitySelector,
    Breadcrumbs,
  },
  setup() {
    const appStore = useStore();
    const isDisplayed = ref(true);
    const appBar = ref(null);

    // Keep appStore.appBarSize up to date.
    const { width, height } = useElementSize(appBar);
    watch([width, height], ([width, height]) => {
      set(appStore, "appBarSize", { width, height });
    });

    return {
      appStore,
      isDisplayed,
      appBar,
    };
  },
};
</script>

<style lang="scss">
.v-toolbar__extension {
  background-color: rgba(0, 0, 0, 0.15);
}

.breadcrumb-title {
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    text-decoration: underline;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}
</style>
