import React, { memo } from "react";
import { mdiCog } from "@mdi/js";
import BaseNode from "./base/BaseNode";

React;

const OperationNode = memo((props) => {
  // Define left icons
  const iconsLeft = {
    color: "#3452c7",
    props: [
      {
        path: mdiCog,
        title: "Operation",
      },
    ],
  };

  return (
    <BaseNode
      {...props}
      handles={["top", "bottom", "right"]}
      iconsLeft={iconsLeft}
      slot={
        <div>
          <div>{props?.data?.detail?.process?.title}</div>
          <div>{props?.data?.detail?.workcenter?.title}</div>
        </div>
      }
    />
  );
});

const OperationOutputLines = () => {
  return [
    "data.data.detail.process.title",
    "data.data.detail.workcenter.title",
  ];
};

export { OperationNode, OperationOutputLines };
